import React from 'react'
import { IconHandStop, IconMinus, IconPlus } from '@tabler/icons-react'

interface MapControlBarProps {
  zoomLevel: number
  onZoomIn?: () => void
  onZoomOut?: () => void
  onPanMode?: () => void
  isPanMode?: boolean
}

export const MapControlBar: React.FC<MapControlBarProps> = ({
  zoomLevel = 15,
  onZoomIn,
  onZoomOut,
  onPanMode,
  isPanMode = false,
}) => {
  return (
    <div className="flex w-full max-w-lg items-center justify-between rounded-full bg-gray-800 px-5 py-3">
      <button
        className={`flex items-center justify-center rounded-full p-2 transition-colors ${
          isPanMode ? 'border border-orange-500 bg-gray-600' : 'hover:bg-gray-700'
        }`}
        onClick={onPanMode}
        aria-label="Toggle pan mode"
      >
        <IconHandStop size={16} stroke={1.5} color="white" />
      </button>

      <div className="mx-1 h-full w-px bg-gray-600"></div>

      <button
        className="flex items-center justify-center rounded-full p-2 transition-colors hover:bg-gray-700"
        onClick={onZoomOut}
        aria-label="Zoom out"
      >
        <IconMinus size={16} stroke={1.5} color="white" />
      </button>

      <div className="font-regular mx-4 font-inter text-[14px] text-gray-200">{zoomLevel}%</div>

      <button
        className="flex items-center justify-center rounded-full p-2 transition-colors hover:bg-gray-700"
        onClick={onZoomIn}
        aria-label="Zoom in"
      >
        <IconPlus size={16} stroke={1.5} color="white" />
      </button>
    </div>
  )
}
