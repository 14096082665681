import { FC } from 'react'
import Typography from 'src/components/atoms/Typography'
import DataTable from 'src/templates/members/data-table'
import AddMaterialDialog from 'src/components/organisms/dialogs/AddMaterialDialog'
import { useLearningMaterialColumns } from 'src/templates/dashboard/super-admin/colums/learningMaterialsColumns'
import { useAdminLearningMaterialsQuery, useDeleteLearningMaterial } from 'src/enviroment/api/services/superAdminApi'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LearningTabProps {}

export const LearningTab: FC<LearningTabProps> = () => {
  const deleteMaterialMutation = useDeleteLearningMaterial()

  const {
    data: materials,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useAdminLearningMaterialsQuery()

  return (
    <div className={`my-6 flex flex-col gap-8 rounded-[8px] bg-gray-800 p-8`}>
      <div className="flex items-center justify-between gap-8">
        <Typography variant="xl" className="shrink-0 text-light-green-50">
          Learning Materials
        </Typography>
        <div className="ml-auto flex items-center gap-6">
          <AddMaterialDialog />
        </div>
      </div>
      <DataTable
        columns={useLearningMaterialColumns((id) => deleteMaterialMutation.mutateAsync(id))}
        data={materials?.pages.flatMap((page) => page.materials) || []}
        onLoadMore={fetchNextPage}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
    </div>
  )
}
