import React, { useRef, useState } from 'react'
import { Upload, X } from 'lucide-react'
import Typography from '../../atoms/Typography'
import DataTable from 'src/templates/members/data-table'
import { useColumns } from './colums'
import {
  useChunkedUpload,
  useDeleteLayer,
  useLayersQuery,
  useUpdateLayerName,
} from 'src/enviroment/api/services/layersApi'
import { SecondaryButton } from 'src/components/atoms/buttons/SecondaryButton'
import { CHUNK_SIZE } from 'src/enviroment/constants/constants'
import { ErrorMessage } from 'src/components/atoms/ErrorMessage'

interface UploadProgress {
  chunks: number
  totalChunks: number
  percentage: number
}

const FileChunksTable = () => {
  const [isUploading, setIsUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState<UploadProgress | null>(null)
  const [fileError, setFileError] = useState<string | null>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const abortControllerRef = useRef<AbortController | null>(null)

  // API Hooks
  const { data: layersData, hasNextPage, fetchNextPage, isFetchingNextPage, isLoading } = useLayersQuery()
  const { upload } = useChunkedUpload(CHUNK_SIZE)
  const { mutate: deleteLayer } = useDeleteLayer()
  const { mutate: updateLayer } = useUpdateLayerName()
  const files = layersData?.pages.flatMap((page) => page) ?? []

  // Allowed MIME types based on the error message
  const ALLOWED_MIME_TYPES = [
    'image/tiff',
    'application/geopackage+sqlite3',
    'application/x-sqlite3',
    'application/gpkg', // Common GPKG MIME type
    'application/geopackage', // Alternative GPKG MIME type
    'application/octet-stream', // Fallback for GPKG files not properly recognized
  ]

  const handleDelete = async (fileId: string) => {
    deleteLayer(fileId)
  }

  const handleUpdateName = async (id: string, newName: string) => {
    try {
      await updateLayer({ fileId: id, fileName: newName })
    } catch (error) {
      console.error('Failed to update name:', error)
      throw error // Propagate error to EditableNameCell for handling
    }
  }
  const columns = useColumns(handleDelete, handleUpdateName)

  const handleFileSelection = async (file: File) => {
    setFileError(null)

    // Get file extension
    const fileName = file.name.toLowerCase()
    const fileExtension = fileName.split('.').pop() || ''

    // Define allowed extensions
    const ALLOWED_EXTENSIONS = ['tiff', 'tif', 'gpkg']

    // Validate file type using MIME type OR file extension
    const isValidMimeType = file.type && ALLOWED_MIME_TYPES.includes(file.type)
    const isValidExtension = ALLOWED_EXTENSIONS.includes(fileExtension)

    if (!isValidMimeType && !isValidExtension) {
      console.log('File type:', file.type)
      console.log('File extension:', fileExtension)
      setFileError(`Invalid file type. Only TIFF images (.tiff, .tif) and GeoPackage files (.gpkg) are allowed.`)
      if (fileInputRef.current) fileInputRef.current.value = ''
      return
    }

    try {
      setIsUploading(true)
      abortControllerRef.current = new AbortController()

      await upload(file, {
        onProgress: (progress: React.SetStateAction<UploadProgress | null>) => {
          setUploadProgress(progress)
        },
        signal: abortControllerRef.current.signal,
      })
    } catch (error) {
      console.error('Upload failed:', error)
      setFileError('Upload failed. Please try again.')
    } finally {
      setIsUploading(false)
      setUploadProgress(null)
      abortControllerRef.current = null
      if (fileInputRef.current) fileInputRef.current.value = ''
    }
  }

  const cancelUpload = async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
    }
  }

  return (
    <div className="w-full space-y-6">
      <div className="flex items-center justify-between">
        <div>
          <input
            type="file"
            ref={fileInputRef}
            className="hidden"
            accept=".tiff,.tif,.gpkg"
            onChange={(e) => e.target.files?.[0] && handleFileSelection(e.target.files[0])}
          />
          <SecondaryButton
            leftIcon={<Upload className="h-5 w-5 " />}
            onClick={() => fileInputRef.current?.click()}
            disabled={isUploading}
          >
            {isUploading ? 'Uploading...' : 'Upload File'}
          </SecondaryButton>
          <Typography variant="sm" className="mt-2 text-gray-400">
            Accepted file types: TIFF images (.tiff, .tif) and GeoPackage files (.gpkg)
          </Typography>
        </div>
      </div>

      {fileError && (
        <div className="flex ">
          <ErrorMessage message={fileError} />
        </div>
      )}

      {uploadProgress && (
        <div className="rounded-lg bg-gray-800 p-4">
          <div className="mb-2 flex justify-between text-white">
            <Typography variant="base">
              Uploading: {uploadProgress.chunks} of {uploadProgress.totalChunks} chunks
            </Typography>
            <div className="flex items-center gap-4">
              <Typography variant="base">{uploadProgress.percentage}%</Typography>
              <button onClick={cancelUpload} className="text-gray-400 transition-colors hover:text-red-500">
                <X className="h-5 w-5" />
              </button>
            </div>
          </div>
          <div className="h-2 rounded-full bg-gray-700">
            <div
              className="h-full rounded-full bg-light-green-600 transition-all duration-300"
              style={{ width: `${uploadProgress.percentage}%` }}
            />
          </div>
        </div>
      )}

      <DataTable
        columns={columns}
        data={files}
        onLoadMore={fetchNextPage}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
    </div>
  )
}

export default FileChunksTable
