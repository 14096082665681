import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { PrimaryButton } from '../atoms/buttons/PrimaryButton'
import { DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../atoms/dialog'
import { Input } from '../atoms/inputs/Input'
import { TextArea } from 'src/components/atoms/inputs/InputTextArea'
import { zodResolver } from '@hookform/resolvers/zod'
import { ErrorMessage } from 'src/components/atoms/ErrorMessage'
import { IconMessage } from '@tabler/icons-react'
import Typography from '../atoms/Typography'
import SingleFileDropzone from '../organisms/SingleFileDropzone'
import { useCreateSupportTicket } from 'src/enviroment/api/services/supportApi'

// Form schema for validation - Project Request
const requestProjectFormSchema = z.object({
  subject: z
    .string()
    .trim()
    .min(3, { message: 'Subject must be at least 3 characters' })
    .max(35, { message: 'Subject cannot exceed 100 characters' })
    .regex(/^[A-Za-zА-Яа-яЁё\s]+$/, { message: 'Subject must contain only letters and spaces' }),
  description: z
    .string()
    .trim()
    .min(10, { message: 'Description must be at least 10 characters' })
    .max(500, { message: 'Description cannot exceed 500 characters' }),
  shape: z.instanceof(File).nullable(),
})

export type RequestProjectFormData = z.infer<typeof requestProjectFormSchema>

type RequestProjectData = {
  subject: string
  description: string
  shape: File
}

interface RequestProjectFormProps {
  initialFile: File | null
  onOpenChange: (open: boolean) => void
  onFileChange: (file: File | null) => void
  isCheckingZone: boolean
}

const RequestProjectForm = ({ initialFile, onOpenChange, onFileChange, isCheckingZone }: RequestProjectFormProps) => {
  const { control, handleSubmit, reset } = useForm<RequestProjectFormData>({
    mode: 'onChange',
    resolver: zodResolver(requestProjectFormSchema),
    defaultValues: {
      subject: '',
      description: '',
      shape: initialFile,
    },
  })

  const { mutateAsync, isLoading } = useCreateSupportTicket()

  const onSubmit = async (data: RequestProjectFormData) => {
    if (!data.shape) {
      console.error('File is required')
      return
    }

    const requestData: RequestProjectData = {
      subject: data.subject,
      description: data.description,
      shape: data.shape,
    }

    try {
      mutateAsync(requestData, {
        onSuccess: () => {
          onOpenChange(false)
          reset()
        },
      })
    } catch (error) {
      console.error('Failed to send project request:', error)
    }
  }

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>
          <Typography variant="2xl-nexa-bold">Request Project</Typography>
          <Typography variant="base" className="mt-2">
            Name your project, add a Geo JSON file if you have a spatial file for your project boundary.
          </Typography>
        </DialogTitle>
      </DialogHeader>

      <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
        <div className="space-y-4">
          <Controller
            name="shape"
            control={control}
            render={({ field: { value }, formState }) => (
              <>
                <SingleFileDropzone file={value} onFileChange={onFileChange} />
                <ErrorMessage message={formState.errors.shape?.message} />

                {isCheckingZone && (
                  <div className="mt-2 text-center">
                    <div className="animate-pulse text-gray-600">
                      <Typography variant="sm">Checking if this zone is available for project creation...</Typography>
                    </div>
                  </div>
                )}
              </>
            )}
          />

          <Controller
            name="subject"
            control={control}
            render={({ field: { onChange, ...field }, formState }) => (
              <Input
                {...field}
                onChange={onChange}
                label="Subject"
                autoFocus
                placeholder="Project subject"
                type="text"
                errorMessage={formState.errors.subject?.message}
              />
            )}
          />

          <Controller
            name="description"
            control={control}
            render={({ field: { onChange, ...field }, formState }) => (
              <TextArea
                {...field}
                onChange={onChange}
                label="Description"
                placeholder="Please provide details about your project request..."
                rows={4}
                errorMessage={formState.errors.description?.message}
              />
            )}
          />
        </div>

        <DialogFooter className="mt-4 sm:justify-end sm:space-x-6">
          {isLoading && (
            <div className="mb-4 w-full text-center">
              <div className="animate-pulse text-gray-600">
                <Typography variant="sm">Submitting your project request...</Typography>
              </div>
            </div>
          )}
          <PrimaryButton type="submit" disabled={isLoading} isLoading={isLoading} rightIcon={<IconMessage size={20} />}>
            Submit Request
          </PrimaryButton>
        </DialogFooter>
      </form>
    </DialogContent>
  )
}

export default RequestProjectForm
