import React from 'react'
import Typography from './Typography'
import { IconWorld } from '@tabler/icons-react'

const MapLoadingHolder = () => {
  return (
    <div className="absolute inset-0 flex flex-col items-center justify-center bg-gradient-to-tr from-light-green-900/70 to-light-green-700">
      <IconWorld className="h-16 w-16 scale-150 animate-[pulse_1.5s_ease-in-out_infinite] text-light-green-50/70" />
      <Typography variant="base-semibold" className="mt-5  text-light-green-50 drop-shadow-lg">
        Initializing the map
      </Typography>
    </div>
  )
}

export default MapLoadingHolder
