import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { ChatMessage } from '../api/chat/models'

// Extended chat message with additional UI properties
interface ExtendedChatMessage extends ChatMessage {
  id: string
  date: string
  isError?: boolean
}

// Chat store state interface
interface ChatState {
  messages: ExtendedChatMessage[]
  selectedCountry: string
}

// Chat store actions interface
interface ChatActions {
  addMessage: (message: ExtendedChatMessage) => void
  addErrorMessage: (error: any) => void
  clearMessages: () => void
  initializeMessages: () => void
  setSelectedCountry: (country: string) => void
}

// Combined store interface
type ChatStore = ChatState & ChatActions

// Initial welcome message
const initialMessage: ExtendedChatMessage = {
  id: 'welcome',
  role: 'assistant',
  content: "Hi! I'm Copa, your carbon copilot. How can I help you today?",
  date: new Date().toISOString(),
}

// Create store with selective persistence
export const useChatStore = create<ChatStore>()(
  persist(
    (set) => ({
      messages: [],
      selectedCountry: '', // Default is empty (global)

      addMessage: (message) =>
        set((state) => ({
          messages: [...state.messages, message],
        })),

      addErrorMessage: (error) => {
        let errorMessage = 'An error occurred while processing your request.'

        // Try to extract error message
        try {
          if (error?.message) {
            errorMessage = error.message
          } else if (typeof error === 'string') {
            errorMessage = error
          }
        } catch (e) {
          // Default error message is used
        }

        set((state) => ({
          messages: [
            ...state.messages,
            {
              id: Date.now().toString(),
              role: 'assistant',
              content: errorMessage,
              date: new Date().toISOString(),
              isError: true,
            },
          ],
        }))
      },

      clearMessages: () => set({ messages: [initialMessage] }),

      initializeMessages: () =>
        set((state) => ({
          messages: state.messages.length === 0 ? [initialMessage] : state.messages,
        })),

      setSelectedCountry: (country) => set({ selectedCountry: country }),
    }),
    {
      name: 'chat-storage',
      // Only persist the messages property
      partialize: (state) => ({ messages: state.messages }),
      storage: createJSONStorage(() => localStorage),
    },
  ),
)
