import { IconEdit } from '@tabler/icons-react'
import React, { useRef } from 'react'
import { useUpdateAvatar } from 'src/enviroment/api/services/userApi'
import Avatar from './Avatar'
import { IUser } from 'src/types/user'

interface Props {
  user: IUser
}
const AvatarUpload = ({ user }: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const updateAvatar = useUpdateAvatar()

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      updateAvatar.mutateAsync(file)
    }
  }

  const handleClick = () => {
    fileInputRef.current?.click()
  }

  return (
    <div className="relative my-4 h-20 w-20 overflow-hidden rounded-full">
      {!updateAvatar.isLoading && <Avatar avatarUrl={user?.avatarUrl} size="lg" />}˝
      <div
        className="absolute inset-0 flex cursor-pointer items-center justify-center bg-black bg-opacity-50 opacity-0 transition-opacity duration-200 hover:opacity-100"
        onClick={handleClick}
      >
        <div className="rounded-full p-2">
          <IconEdit size={24} className="text-gray-50" />
        </div>
      </div>
      {/* Hidden file input */}
      <input type="file" ref={fileInputRef} onChange={handleFileChange} accept="image/*" className="hidden" />
      {/* Loading indicator */}
      {updateAvatar.isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30">
          <div className="h-8 w-8 animate-spin rounded-full border-4 border-white border-t-transparent"></div>
        </div>
      )}
    </div>
  )
}

export default AvatarUpload
