import { ChevronLeftIcon, ChevronRightIcon, Maximize2Icon, Minimize2Icon } from 'lucide-react'
import React, { useState, useEffect } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../../atoms/dialog'
import Typography from '../../atoms/Typography'
import { SecondaryButton } from '../../atoms/buttons/SecondaryButton'
import { LearningMaterial } from 'src/enviroment/api/services/superAdminApi'

interface LearningMaterialViewProps {
  material: LearningMaterial | null
  onClose: () => void
}

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

const LearningMaterialView: React.FC<LearningMaterialViewProps> = ({ material, onClose }) => {
  const [numPages, setNumPages] = useState<number>(0)
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false)
  const pdfContainerRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    setNumPages(0)
    setPageNumber(1)
    setLoading(true)
    setError(null)
  }, [material?.blobUrl])

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement)
    }

    document.addEventListener('fullscreenchange', handleFullscreenChange)
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange)
    }
  }, [])

  if (!material) return null

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages)
    setLoading(false)
  }

  const onDocumentLoadError = (error: Error) => {
    setError(error.message)
    setLoading(false)
  }

  const goToPreviousPage = () => {
    setPageNumber((prevPage) => Math.max(prevPage - 1, 1))
  }

  const goToNextPage = () => {
    setPageNumber((prevPage) => Math.min(prevPage + 1, numPages))
  }

  const toggleFullscreen = async () => {
    if (!pdfContainerRef.current) return

    try {
      if (!isFullscreen) {
        await pdfContainerRef.current.requestFullscreen()
      } else {
        await document.exitFullscreen()
      }
    } catch (err) {
      console.error('Error toggling fullscreen:', err)
    }
  }

  const renderPdfNavigation = () => (
    <div className="relative mt-4 flex items-center justify-center px-4">
      <div className="flex items-center gap-4">
        <SecondaryButton onClick={goToPreviousPage} disabled={pageNumber <= 1} leftIcon={<ChevronLeftIcon />}>
          Previous
        </SecondaryButton>
        <Typography variant="base" className="text-sm text-light-green-50">
          Page {pageNumber} of {numPages}
        </Typography>
        <SecondaryButton onClick={goToNextPage} disabled={pageNumber >= numPages} rightIcon={<ChevronRightIcon />}>
          Next
        </SecondaryButton>
      </div>
      <SecondaryButton onClick={toggleFullscreen} className="absolute right-4 h-8 w-8 p-0">
        {isFullscreen ? <Minimize2Icon size={16} /> : <Maximize2Icon size={16} />}
      </SecondaryButton>
    </div>
  )

  const renderContent = () => {
    if (material.mimeType.includes('pdf')) {
      return (
        <div ref={pdfContainerRef} className="pdf-container">
          <div className="relative">
            <Document
              file={material.blobUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={onDocumentLoadError}
              className="flex justify-center"
              loading={
                <div className="flex items-center justify-center">
                  <div className="h-8 w-8 animate-spin rounded-full border-2 border-light-green-50 border-t-transparent" />
                </div>
              }
            >
              <Page
                pageNumber={pageNumber}
                className="shadow-lg"
                renderTextLayer={false}
                renderAnnotationLayer={false}
                scale={isFullscreen ? 1.8 : 1}
              />
            </Document>
          </div>
          {renderPdfNavigation()}
        </div>
      )
    }
    if (material.mimeType.includes('video')) {
      return (
        <div className="relative w-full pt-[56.25%]">
          <video
            key={material.blobUrl}
            className="absolute left-0 top-0 h-full w-full rounded-lg"
            controls
            autoPlay={false}
            src={material.blobUrl || ''}
          >
            Your browser does not support the video tag.
          </video>
        </div>
      )
    }

    return (
      <Typography variant="lg" className="text-center text-gray-400">
        Unsupported file type: {material.mimeType}
      </Typography>
    )
  }

  return (
    <Dialog open={!!material} onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="sm:max-w-6xl">
        <DialogHeader className="flex flex-row items-center justify-between">
          <DialogTitle>
            <Typography variant="xl">{material.name}</Typography>
          </DialogTitle>
        </DialogHeader>

        {error ? (
          <Typography variant="lg" className="text-center text-red-500">
            Error loading document: {error}
          </Typography>
        ) : (
          renderContent()
        )}
      </DialogContent>
    </Dialog>
  )
}

export default LearningMaterialView
