import React from 'react'
import Typography from '../atoms/Typography'
import { Link } from 'react-router'

export interface TermsPrivacyProps {
  className?: string
}

const TermsPrivacy: React.FC<TermsPrivacyProps> = ({ className }) => {
  return (
    <div className="space-y-1 text-left text-light-green-50">
      <Typography variant="base" className="inline">
        By continuing you agree to the{' '}
        <Link to="/legal?section=terms">
          <Typography
            variant="base-semibold"
            className="inline cursor-pointer text-light-green-500 decoration-1 hover:text-light-green-400"
          >
            terms of service
          </Typography>
        </Link>
      </Typography>

      <Typography variant="base" className="inline">
        {' '}
        and{' '}
        <Link to="/legal?section=privacy">
          <Typography
            variant="base-semibold"
            className=" inline cursor-pointer text-light-green-500  decoration-1 hover:text-light-green-400"
          >
            privacy policy
          </Typography>
        </Link>
        .
      </Typography>
    </div>
  )
}

export default TermsPrivacy
