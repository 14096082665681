import React, { useEffect } from 'react'
import * as Select from '@radix-ui/react-select'
import { ChevronDown, Check } from 'lucide-react'
import { useGetTenants } from 'src/enviroment/api/services/tenantsApi'
import { ITenant } from 'src/types/user'
import { useTenantStore } from 'src/enviroment/store/tenentStore'
import { useQueryClient } from '@tanstack/react-query'
import { MembersTags } from 'src/enviroment/api/services/membersApi'
import { ProjectTags } from 'src/enviroment/api/services/projectsApi'
import { IconDirection, IconLeaf } from '@tabler/icons-react'
import Typography from '../atoms/Typography'

const getInitials = (name: string) => name.slice(0, 3).toUpperCase()

interface SectionHeaderProps {
  isCollapsed?: boolean
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ isCollapsed }) => {
  const { data: tenants, isLoading } = useGetTenants()
  const { currentTenant, setCurrentTenant } = useTenantStore()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (tenants?.length > 0 && !currentTenant) {
      setCurrentTenant(tenants[0])
    }
  }, [tenants, currentTenant, setCurrentTenant])

  const handleTenantChange = (selectedId: string) => {
    const selectedTenant = tenants?.find((t: ITenant) => t.id === selectedId)
    if (selectedTenant) {
      setCurrentTenant(selectedTenant)

      queryClient.invalidateQueries({
        queryKey: [MembersTags.List, ProjectTags.List],
      })
    }
  }

  if (isLoading || !tenants?.length || !currentTenant) {
    return null
  }

  const displayName = isCollapsed ? getInitials(currentTenant.name) : currentTenant.name

  return (
    <div className="mb-4 flex flex-col justify-between px-2">
      {!isCollapsed && (
        <Typography variant="xs" className="mb-2">
          SELECT WORKSPACE
        </Typography>
      )}
      <Select.Root value={currentTenant.id} onValueChange={handleTenantChange}>
        <Select.Trigger
          className={`inline-flex h-10 items-center justify-between rounded border border-gray-700 bg-gray-800 px-3 py-2 text-sm text-light-green-50 hover:bg-gray-700 focus:outline-none ${
            isCollapsed ? 'w-12' : 'w-full'
          }`}
          aria-label="Select workspace"
        >
          {isCollapsed ? (
            <Select.Value className="flex w-full justify-center">
              <IconLeaf className="mx-auto" />
            </Select.Value>
          ) : (
            <Select.Value>{displayName || 'Select workspace'}</Select.Value>
          )}
          {!isCollapsed && (
            <Select.Icon>
              <IconDirection className="h-6 w-6 text-light-green-500" />
            </Select.Icon>
          )}
        </Select.Trigger>

        <Select.Portal>
          <Select.Content className="z-50 min-w-[200px] overflow-hidden rounded-md border border-gray-700 bg-gray-800 text-light-green-50 shadow-md animate-in fade-in-80">
            <Select.Viewport className="p-1">
              {tenants.map((tenant: ITenant) => (
                <Select.Item
                  key={tenant.id}
                  value={tenant.id}
                  className="relative flex h-9 cursor-pointer select-none items-center rounded px-2 py-1.5 text-sm outline-none hover:bg-gray-700 hover:text-light-green-50 focus:bg-gray-700"
                >
                  <div className="flex w-full items-center justify-between">
                    <Select.ItemText>{tenant.name}</Select.ItemText>
                    {currentTenant.id === tenant.id && <Check className="h-4 w-4 text-light-green-500" />}
                  </div>
                </Select.Item>
              ))}
            </Select.Viewport>
          </Select.Content>
        </Select.Portal>
      </Select.Root>
    </div>
  )
}

export default SectionHeader
