import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { enqueueSnackbar } from 'notistack'
import { client } from '../axios'
import { handleApiError } from '../errorHandler'
import { TenantsQueryKeys } from './tenantsApi'

// Types
import { SUBSCRIPTION_PLAN } from 'src/types/user'
import { UpdateProjectData } from 'src/types/projects'
import { GlobalTenantsResponse, Tenant, UserTenantRoles } from 'src/types/tenant'
import { EditUserResponse, ProjectsResponse, QueryParams, TenantsResponse, UsersResponse } from 'src/types/superadmin'
import { AddProjectFormData } from 'src/components/organisms/dialogs/AddProjectDialog'
import { UserQueryKeys } from './userApi'

const DEFAULT_TAKE = 10

export interface LearningMaterial {
  id: string
  name: string
  type: string
  fileName: string
  blobUrl: string | null
  mimeType: string
  sizeBytes: number
  createdAt: string
  updatedAt: string | null
  allowedTenants: {
    id: string
    name: string
  }[]
}

export interface LearningMaterialsResponse {
  materials: LearningMaterial[]
  total?: number
  nextCursor: string
}

export enum SuperAdminQueryKeys {
  UsersList = 'UsersList',
  GlobalProjectList = 'GlobalProjectList',
  GlobalTenantsList = 'GlobalTenantsList',
  TenantsProject = 'TenantsProject',
  CurrentTenantsOfProject = 'CurrentTenantsOfProject',
  EditUser = 'EditUser',
  LearningMaterials = 'LearningMaterials',
}

export interface UpdateTenantPayload {
  id: string
  name?: string
  plan?: SUBSCRIPTION_PLAN
  logo?: File | null
}

// Utilities
export const queryTool = (query: string | undefined) => (query !== '' ? query : undefined)

// User-related hooks
export function useUsersQuery(params: QueryParams = {}) {
  const queryClient = useQueryClient()
  const { take = DEFAULT_TAKE, query, isApproved } = params

  return useInfiniteQuery({
    queryKey: [SuperAdminQueryKeys.UsersList, query, isApproved],
    queryFn: async ({ pageParam }) => {
      const response = await client.get<UsersResponse>('/users', {
        params: {
          cursor: pageParam,
          take,
          query: queryTool(query),
          isApproved,
        },
      })
      return response.data
    },
    enabled: Boolean(queryClient.getQueryData([UserQueryKeys.User])),
    getNextPageParam: (lastPage) => lastPage.nextCursor,
  })
}

export function useDeleteUser() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (id: string) => {
      await client.delete(`/users/${id}`)
    },
    onSuccess: () => {
      queryClient.invalidateQueries([SuperAdminQueryKeys.UsersList])
    },
  })
}

export function useApproveUser() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (id: string) => {
      await client.patch(`/users/${id}/approve`)
    },
    onSuccess: () => {
      enqueueSnackbar('User approved successfully', { variant: 'success' })
      queryClient.invalidateQueries({
        predicate: (query) => Array.isArray(query.queryKey) && query.queryKey[0] === SuperAdminQueryKeys.UsersList,
      })
    },
  })
}

export function useGetDetailsForEditUser(userId: string, enabled: boolean) {
  return useQuery({
    queryKey: [SuperAdminQueryKeys.EditUser, userId],
    queryFn: async () => {
      const response = await client.get<EditUserResponse>(`/users/${userId}:get-data-for-edit-user-by-superadmin`)
      return response.data
    },
    enabled,
    onError: handleApiError,
  })
}

export function useEditUser(userId: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: EditUserResponse) => {
      await client.patch(`/users/${userId}`, data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => Array.isArray(query.queryKey) && query.queryKey[0] === SuperAdminQueryKeys.UsersList,
      })
      queryClient.invalidateQueries({
        queryKey: [SuperAdminQueryKeys.EditUser, userId],
      })
      queryClient.invalidateQueries({
        queryKey: [SuperAdminQueryKeys.CurrentTenantsOfProject],
      })
    },
    onError: handleApiError,
  })
}

// Project-related hooks
export function useGlobalProjectQuery(params: QueryParams = {}) {
  const queryClient = useQueryClient()
  const { take = DEFAULT_TAKE, query } = params

  return useInfiniteQuery({
    queryKey: [SuperAdminQueryKeys.GlobalProjectList, query],
    queryFn: async ({ pageParam }) => {
      const response = await client.get<ProjectsResponse>('/projects', {
        params: {
          cursor: pageParam,
          take,
          query: queryTool(query),
        },
      })
      return response.data
    },
    enabled: Boolean(queryClient.getQueryData([UserQueryKeys.User])),
    getNextPageParam: (lastPage) => lastPage.nextCursor,
  })
}

export function useAddNewProject() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: AddProjectFormData) => {
      await client.post('/projects', data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries([SuperAdminQueryKeys.GlobalProjectList])
    },
    onError: handleApiError,
  })
}

export function useUpdateGlobalProjectMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: UpdateProjectData) => {
      console.log('data', data)
      return client.patch(`/projects/${data.id}`, data)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [SuperAdminQueryKeys.GlobalProjectList] })
    },
    onError: handleApiError,
  })
}

export function useRefreshThumbnailMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (projectId: string) => {
      return client.post(`/projects/${projectId}/thumbnail-refresh`)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [SuperAdminQueryKeys.GlobalProjectList] })
    },
    onError: handleApiError,
    onSuccess: () => {
      enqueueSnackbar('Thumbnail refreshed successfully', { variant: 'success' })
    },
  })
}

export function useDeleteGlobalProjectMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (projectId: string) => {
      return client.delete(`/projects/${projectId}`)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [SuperAdminQueryKeys.GlobalProjectList] })
    },
    onError: handleApiError,
  })
}

// Tenant-related hooks
export function useGlobalTenantsQuery(params: QueryParams = {}) {
  const queryClient = useQueryClient()
  const { take = DEFAULT_TAKE, query } = params

  return useInfiniteQuery({
    queryKey: [SuperAdminQueryKeys.GlobalTenantsList, query],
    queryFn: async ({ pageParam }) => {
      const response = await client.get<TenantsResponse>('/tenants', {
        params: {
          cursor: pageParam,
          take,
          query: queryTool(query),
        },
      })
      return response.data
    },
    enabled: Boolean(queryClient.getQueryData([UserQueryKeys.User])),
    getNextPageParam: (lastPage) => lastPage.nextCursor,
  })
}

export function useGetTenantsByUserId(userId: string) {
  return useQuery({
    queryKey: ['tenants', userId],
    queryFn: async () => {
      const response = await client.get<UserTenantRoles>(`/users/${userId}:tenant-access`)
      return response.data
    },
  })
}

export function useUpdateTenantAdmin() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: UpdateTenantPayload) => {
      const formData = new FormData()

      if (data.name) {
        formData.append('name', data.name)
      }

      if (data.plan) {
        formData.append('plan', data.plan)
      }

      // Handle logo scenarios:
      // 1. New logo (File object) - append to FormData
      // 2. Delete existing logo (null) - append empty string to indicate deletion
      // 3. No change (undefined) - don't include in payload
      if (data.logo instanceof File) {
        formData.append('logo', data.logo)
      } else if (data.logo === null) {
        // Empty string indicates deletion in the backend
        formData.append('logo', '')
      }

      return await client.patch(`/tenants/${data.id}/as-superadmin`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    },
    onSuccess: () => {
      // Invalidate relevant queries to refresh data
      queryClient.invalidateQueries([SuperAdminQueryKeys.GlobalTenantsList])
      queryClient.invalidateQueries([TenantsQueryKeys.Tenants])
    },
    onError: handleApiError,
  })
}
export function useGetTenantsByProject(params: QueryParams = {}, enabled: boolean) {
  const { take = DEFAULT_TAKE, query } = params

  return useInfiniteQuery({
    queryKey: [SuperAdminQueryKeys.TenantsProject, query],
    queryFn: async ({ pageParam }) => {
      const response = await client.get<GlobalTenantsResponse>('/tenants', {
        params: {
          cursor: pageParam,
          take,
          query: queryTool(query),
        },
      })
      return response.data
    },
    enabled,
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    onError: handleApiError,
  })
}

export function useGetCurrentTenantsOfProject(projectId: string) {
  return useQuery({
    queryKey: [SuperAdminQueryKeys.CurrentTenantsOfProject, projectId],
    queryFn: async () => {
      const response = await client.get<Tenant[]>(`/projects/${projectId}:tenants`)
      return response.data
    },
    onError: handleApiError,
  })
}

export function useUpdateProjectTenants(projectId: string) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (tenantIds: string[]) => {
      await client.put(`/projects/${projectId}:tenants`, { tenantIds })
    },
    onSuccess: () => {
      queryClient.invalidateQueries([SuperAdminQueryKeys.CurrentTenantsOfProject, projectId])
    },
    onError: handleApiError,
  })
}

export function useCreateNewWorkSpace() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: { name: string; userId: string }) => {
      await client.post('/tenants', data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries([SuperAdminQueryKeys.GlobalTenantsList])
    },
    onError: handleApiError,
  })
}

export function useAdminLearningMaterialsQuery(params: QueryParams = {}) {
  const queryClient = useQueryClient()
  const { take = 10, query } = params

  return useInfiniteQuery({
    queryKey: [SuperAdminQueryKeys.LearningMaterials, query],
    queryFn: async ({ pageParam }) => {
      const response = await client.get<LearningMaterialsResponse>('/learning-materials', {
        params: {
          cursor: pageParam,
          take,
        },
      })
      return response.data
    },
    enabled: Boolean(queryClient.getQueryData([UserQueryKeys.User])),
    getNextPageParam: (lastPage) => lastPage.nextCursor,
  })
}

export function useCreateLearningMaterial() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: { name: string; type: string; file: File }) => {
      const formData = new FormData()
      formData.append('name', data.name)
      formData.append('type', data.type)
      formData.append('file', data.file)

      await client.post('/learning-materials:create', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries([SuperAdminQueryKeys.LearningMaterials])
      enqueueSnackbar('Learning material created successfully', { variant: 'success' })
    },
    onError: handleApiError,
  })
}

export function useDeleteLearningMaterial() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (id: string) => {
      await client.delete(`/learning-materials/${id}`)
    },
    onSuccess: () => {
      queryClient.invalidateQueries([SuperAdminQueryKeys.LearningMaterials])
      enqueueSnackbar('Learning material deleted successfully', { variant: 'success' })
    },
    onError: handleApiError,
  })
}

export const useUpdateLearningMaterialTenants = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ id, tenantIds }: { id: string; tenantIds: string[] }) => {
      await client.patch(`/learning-materials/${id}/tenant-access`, { tenantIds })
    },
    onSuccess: () => {
      queryClient.invalidateQueries([SuperAdminQueryKeys.LearningMaterials])
      enqueueSnackbar('Material access updated successfully', { variant: 'success' })
    },
    onError: handleApiError,
  })
}
