import React, { useState, useEffect, FC } from 'react'
import { IconInfoCircle } from '@tabler/icons-react'
import Typography from '../atoms/Typography'
import { SecondaryButton } from '../atoms/buttons/SecondaryButton'
import { PrimaryButton } from '../atoms/buttons/PrimaryButton'

interface DrawingMapTipProps {
  isDrawing: boolean
}

const DrawingMapTip: FC<DrawingMapTipProps> = ({ isDrawing }) => {
  const [showModal, setShowModal] = useState<boolean>(false)

  // Check if the user has already dismissed the modal and if drawing is active
  useEffect(() => {
    const hasSeenTip = localStorage.getItem('hasSeenDrawingMapTipModal')
    if (isDrawing && hasSeenTip !== 'true') {
      setShowModal(true)
    } else {
      setShowModal(false)
    }
  }, [isDrawing])

  const handleDismiss = (): void => {
    setShowModal(false)
  }

  const handleGotIt = (): void => {
    // Save to localStorage that user has seen the modal
    localStorage.setItem('hasSeenDrawingMapTipModal', 'true')
    setShowModal(false)
  }

  if (!showModal) {
    return null
  }

  return (
    <div className="relative w-full max-w-[380px]">
      <div className="w-full rounded-lg border border-gray-500 bg-gray-900 p-3 text-gray-50">
        <div className="flex flex-col">
          {/* Header with icon and title aligned horizontally */}
          <div className="mb-4 flex items-center">
            <div className="p-2">
              <IconInfoCircle className="h-5 w-5 text-light-green-500" />
            </div>

            <Typography variant="sm">Drawing in the Map</Typography>
          </div>

          {/* Content area */}
          <div className="ml-8">
            <Typography variant="sm" className="mb-3 text-gray-200">
              Click anywhere to start your drawing. Point by point create your area.
            </Typography>

            <div className="flex justify-end space-x-4">
              <SecondaryButton size="sm" onClick={handleDismiss}>
                <Typography variant="sm">Dismiss</Typography>
              </SecondaryButton>
              <PrimaryButton size="sm" onClick={handleGotIt}>
                <Typography variant="sm">Got It</Typography>
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DrawingMapTip
