import React, { useEffect, useState } from 'react'
import { Plus, Trash2 } from 'lucide-react'
import { cn } from 'src/enviroment/lib/utils'
import { Types, useCreateType, useDeleteTypeTag, useSearchTypes } from 'src/enviroment/api/services/typesApi'
import Typography from '../atoms/Typography'
import { ErrorMessage } from '../atoms/ErrorMessage'

interface TypeSelectorProps {
  onTypesChange: (typeIds: string[]) => void
  label?: string
  errorMessage?: string
  defaultValue?: Types[]
  placeholder?: string
  className?: string
  isAdmin?: boolean
  minTags?: number
  maxTags?: number
}

const TypeSelector = ({
  onTypesChange,
  label,
  errorMessage,
  className,
  defaultValue = [],
  placeholder = 'Search type',
  isAdmin = false,
  minTags = 3,
  maxTags = 10,
}: TypeSelectorProps) => {
  const [inputValue, setInputValue] = useState('')
  const [selectedTypes, setSelectedTypes] = useState<Types[]>(defaultValue)
  const [isCreating, setIsCreating] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [validationError, setValidationError] = useState('')

  const { data: typesData, isLoading } = useSearchTypes({
    query: inputValue,
  })

  const deleteTypeTagMutation = useDeleteTypeTag()

  const createTypeMutation = useCreateType()

  useEffect(() => {
    if (defaultValue.length > 0) {
      // Validate default value against min/max constraints
      if (defaultValue.length < minTags) {
        setValidationError(`You must select at least ${minTags} tags`)
      } else if (defaultValue.length > maxTags) {
        setValidationError(`You can select a maximum of ${maxTags} tags`)
      }

      onTypesChange(defaultValue.map((type) => type.id))
    } else if (minTags > 0) {
      // If no default values but minimum required
      setValidationError(`You must select at least ${minTags} tags`)
    }
  }, [minTags, maxTags])

  const handleAdd = (type: Types) => {
    // Check if maximum tags limit reached
    if (selectedTypes.length >= maxTags) {
      setValidationError(`You can select a maximum of ${maxTags} tags`)
      return
    }

    if (!selectedTypes.find((t) => t.id === type.id)) {
      const newTypes = [...selectedTypes, type]
      setSelectedTypes(newTypes)
      onTypesChange(newTypes.map((t) => t.id))

      // Clear max validation error if it was shown
      if (validationError.includes('maximum')) {
        setValidationError('')
      }
    }
  }

  // Function to remove a type from selection (available to all users)
  const handleRemoveSelection = (typeId: string) => {
    const newTypes = selectedTypes.filter((t) => t.id !== typeId)

    // Check if removing would violate minimum tags requirement and there are already some tags
    if (selectedTypes.length > 0 && newTypes.length < minTags) {
      setValidationError(`You must select at least ${minTags} tags`)
      return
    }

    setSelectedTypes(newTypes)
    onTypesChange(newTypes.map((t) => t.id))

    // Clear min validation error if it was shown
    if (validationError.includes('at least')) {
      setValidationError('')
    }
  }

  // Function to delete a type from the system (admin only)
  const handleDeleteType = async (typeId: string) => {
    if (!isAdmin) return

    try {
      await deleteTypeTagMutation.mutateAsync(typeId)
      handleRemoveSelection(typeId) // Also remove from selection if deleted
    } catch (error) {
      console.error('Error deleting type:', error)
    }
  }

  const validateInput = (value: string) => {
    if (value.length < 5) {
      setValidationError('Tag must be at least 5 characters long')
      return false
    }
    setValidationError('')
    return true
  }

  const handleCreate = async (name: string) => {
    if (!isAdmin) return

    // Strict validation check
    if (name.length < 5) {
      setValidationError('Tag must be at least 5 characters long')
      return
    }

    // Clear validation error if passed
    setValidationError('')

    try {
      const newType = await createTypeMutation.mutateAsync(name)
      handleAdd(newType)
      setInputValue('')
      setIsCreating(false)
    } catch (error) {
      console.error('Error creating type:', error)
    }
  }

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()

      // For creating new tags (admin only)
      if (isAdmin && (isCreating || !typesData?.length)) {
        // Silently prevent creation if validation fails
        if (inputValue.length < 5) {
          return
        }
        await handleCreate(inputValue)
      }
      // For selecting existing tags
      else if (typesData?.[0]) {
        handleAdd(typesData[0])
        setInputValue('')
      }
    }

    if (e.key === 'Backspace' && !inputValue) {
      e.preventDefault()
      const lastType = selectedTypes[selectedTypes.length - 1]
      if (lastType) {
        // Check minimum tags constraint when removing via backspace
        if (selectedTypes.length <= minTags) {
          setValidationError(`You must select at least ${minTags} tags`)
          return
        }
        handleRemoveSelection(lastType.id)
      }
    }
  }

  return (
    <div className="space-y-2">
      {label && (
        <Typography variant="sm-medium" element="label" className="block text-gray-50">
          {label}
        </Typography>
      )}
      <div
        className={cn(
          'flex min-h-[48px] w-full rounded-lg bg-gray-800 p-2',
          'border border-gray-300',
          'transition-all duration-200',
          'focus-within:border-light-green-500 focus-within:shadow-[0_0_0_1px_rgba(50,145,117,0.3)] hover:border-gray-400',
          (errorMessage || validationError) &&
            'border-red-ramo focus-within:border-red-ramo focus-within:shadow-[0_0_0_1px_rgba(215,27,61,0.3)]',
          className,
        )}
      >
        <div className="flex flex-wrap items-center gap-1.5">
          {selectedTypes.map((type) => (
            <span
              key={type.id}
              className="flex items-center gap-1.5 rounded-md bg-gray-700/50 px-2.5 py-1 text-sm-medium text-gray-50 
                transition-colors hover:bg-gray-700"
            >
              {type.name}
              <button
                onClick={() => handleRemoveSelection(type.id)}
                className={cn(
                  'grid h-4 w-4 place-items-center rounded-full text-gray-300 transition-colors',
                  selectedTypes.length <= minTags
                    ? 'cursor-not-allowed opacity-50'
                    : 'hover:bg-gray-600 hover:text-white',
                )}
                disabled={selectedTypes.length <= minTags}
              >
                ×
              </button>
            </span>
          ))}

          <div className="relative min-w-[120px] flex-1">
            <input
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value)
                setIsCreating(false)
                // No need to display validation errors here, just check silently
                if (validationError && validationError.includes('characters')) {
                  setValidationError('')
                }
              }}
              onKeyDown={handleKeyDown}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setTimeout(() => setIsFocused(false), 200)}
              className={cn(
                'w-full bg-transparent text-base text-gray-50 outline-none placeholder:text-gray-300',
                selectedTypes.length >= maxTags && 'cursor-not-allowed opacity-75',
              )}
              placeholder={selectedTypes.length >= maxTags ? `Maximum ${maxTags} tags reached` : placeholder}
              disabled={selectedTypes.length >= maxTags}
            />
            {inputValue && !isLoading && isFocused && (
              <div className="absolute z-50 mt-2 w-full overflow-hidden rounded-lg border border-gray-300 bg-gray-800 shadow-lg">
                {typesData?.map((type: Types) => (
                  <div
                    key={type.id}
                    className="flex items-center justify-between p-3 text-sm text-gray-50 transition-colors hover:bg-gray-700"
                  >
                    <button
                      className="flex-1 text-left"
                      onClick={() => {
                        handleAdd(type)
                        setInputValue('')
                      }}
                    >
                      {type.name}
                    </button>
                    {isAdmin && (
                      <button
                        onClick={() => handleDeleteType(type.id)}
                        className="ml-2 p-1 text-gray-300 hover:text-red-500"
                      >
                        <Trash2 className="h-4 w-4" />
                      </button>
                    )}
                  </div>
                ))}
                {isAdmin && (
                  <button
                    type="button"
                    className={cn(
                      'flex w-full items-center gap-2 border-t border-gray-700 p-3 text-sm text-gray-50 transition-colors',
                      inputValue.length >= 5 ? 'hover:bg-gray-700' : 'cursor-not-allowed opacity-60',
                    )}
                    onClick={() => {
                      // Strict validation check
                      if (inputValue.length < 5) {
                        setValidationError('Tag must be at least 5 characters long')
                        return
                      }
                      handleCreate(inputValue)
                    }}
                  >
                    <Plus className="h-4 w-4 text-light-green-500" />
                    Create &quot;{inputValue}&quot;
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {errorMessage && <ErrorMessage message={errorMessage} />}
      {validationError && <ErrorMessage message={validationError} />}
    </div>
  )
}

export default TypeSelector
