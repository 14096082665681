import { IconUser } from '@tabler/icons-react'
import React, { useMemo, useState, useEffect } from 'react'

interface AvatarProps {
  avatarUrl?: string | null
  size?: 'sm' | 'md' | 'lg' | 'xl'
  className?: string
}

const SIZE_CLASSES = {
  sm: 'h-8 w-8',
  md: 'h-12 w-12',
  lg: 'h-20 w-20',
  xl: 'h-32 w-32',
} as const

const ICON_SIZES = {
  sm: 16,
  md: 24,
  lg: 40,
  xl: 64,
} as const

const Avatar = ({ avatarUrl, size = 'md', className = '' }: AvatarProps) => {
  const containerClasses = useMemo(
    () => `overflow-hidden rounded-full ${SIZE_CLASSES[size]} ${className}`.trim(),
    [size, className],
  )

  const iconSize = ICON_SIZES[size]

  return (
    <div className={containerClasses}>
      {avatarUrl ? (
        <img src={avatarUrl} alt="User avatar" className="h-full w-full object-cover" key={avatarUrl} />
      ) : (
        <div className="flex h-full w-full items-center justify-center bg-gray-200">
          <IconUser size={iconSize} className="text-gray-500" />
        </div>
      )}
    </div>
  )
}

export default Avatar
