import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { Input } from 'src/components/atoms/inputs/Input'
import { PrimaryButton } from 'src/components/atoms/buttons/PrimaryButton'
import { SecondaryButton } from 'src/components/atoms/buttons/SecondaryButton'
import { zodResolver } from '@hookform/resolvers/zod'

const editProfileSchema = z.object({
  firstName: z
    .string()
    .trim()
    .min(1, 'First name is required')
    .max(50, 'First name cannot exceed 50 characters')
    .regex(/^[\p{L}]+$/u, 'First name must contain only letters')
    .refine((val) => val.length > 0, 'First name is required'),
  lastName: z
    .string()
    .trim()
    .min(1, 'Last name is required')
    .max(50, 'Last name cannot exceed 50 characters')
    .regex(/^[\p{L}]+$/u, 'Last name must contain only letters')
    .refine((val) => val.length > 0, 'Last name is required'),
  company: z
    .string()
    .trim()
    .min(1, 'Company name is required')
    .max(45, 'Company name cannot exceed 100 characters')
    .refine((val) => val.length > 0, 'Company name is required'),
})
type EditProfileFormData = z.infer<typeof editProfileSchema>

interface EditProfileFormProps {
  initialData: {
    firstName: string
    lastName: string
    company: string
  }
  isLoading: boolean
  onSubmit: (data: EditProfileFormData) => void
  onCancel: () => void
}

export default function EditProfileForm({ initialData, isLoading, onSubmit, onCancel }: EditProfileFormProps) {
  const methods = useForm<EditProfileFormData>({
    defaultValues: initialData,
    resolver: zodResolver(editProfileSchema),
    mode: 'onChange',
  })

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = methods

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="max-w-[80%]">
      {/* 2x2 Grid Layout */}
      <div className="mb-6 grid grid-cols-2 gap-4">
        <Controller
          name="firstName"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              label="First Name"
              {...field}
              errorMessage={fieldState.error?.message}
              className="border-gray-700 bg-transparent text-gray-100"
            />
          )}
        />

        <Controller
          name="lastName"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              label="Last Name"
              {...field}
              errorMessage={fieldState.error?.message}
              className="border-gray-700 bg-transparent text-gray-100"
            />
          )}
        />

        <Controller
          name="company"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              label="Company"
              {...field}
              errorMessage={fieldState.error?.message}
              className="border-gray-700 bg-transparent text-gray-100"
            />
          )}
        />

        {/* This is an empty div to maintain the 2x2 grid layout */}
        <div></div>
      </div>

      <div className="flex justify-end space-x-4 pt-4">
        <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton type="submit" disabled={!isValid} isLoading={isLoading}>
          Save Changes
        </PrimaryButton>
      </div>
    </form>
  )
}
