import React, { useState, useEffect } from 'react'
import { Plus, Trash2 } from 'lucide-react'
import { cn } from 'src/enviroment/lib/utils'
import Typography from '../atoms/Typography'
import { ErrorMessage } from '../atoms/ErrorMessage'
import { useCreateStage, useDeleteStageTag, useSearchStages } from 'src/enviroment/api/services/stageApi'

export interface Stage {
  id: string
  name: string
}

interface StageSelectorProps {
  onStageChange: (stageId: string | null) => void
  label?: string
  errorMessage?: string
  placeholder?: string
  className?: string
  defaultValue?: Stage
}

const StageSelector = ({
  onStageChange,
  label,
  errorMessage,
  className,
  placeholder = 'Search stage',
  defaultValue,
}: StageSelectorProps) => {
  const [inputValue, setInputValue] = useState('')
  const [selectedStage, setSelectedStage] = useState<Stage | null>(defaultValue || null)
  const [isCreating, setIsCreating] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  const { data: stagesData, isLoading } = useSearchStages({
    query: inputValue,
  })

  const createStageMutation = useCreateStage()
  const deleteStageMutation = useDeleteStageTag()

  useEffect(() => {
    if (defaultValue) {
      onStageChange(defaultValue.id)
    }
  }, [])

  const handleSelect = (stage: Stage) => {
    setSelectedStage(stage)
    onStageChange(stage.id)
    setInputValue('')
  }

  const handleDelete = async (stageId: string) => {
    try {
      await deleteStageMutation.mutateAsync(stageId)
      if (selectedStage?.id === stageId) {
        setSelectedStage(null)
        onStageChange(null)
      }
      setInputValue('')
    } catch (error) {
      console.error('Error deleting stage:', error)
    }
  }

  const handleClearSelection = () => {
    setSelectedStage(null)
    onStageChange(null)
    setInputValue('')
  }

  const handleCreate = async (name: string) => {
    try {
      const newStage = await createStageMutation.mutateAsync(name)
      handleSelect(newStage)
      setInputValue('')
      setIsCreating(false)
    } catch (error) {
      console.error('Error creating stage:', error)
    }
  }

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      if (isCreating) {
        await handleCreate(inputValue)
      } else if (stagesData?.[0]) {
        handleSelect(stagesData[0])
      }
    }

    if (e.key === 'Backspace' && !inputValue && selectedStage) {
      e.preventDefault()
      handleClearSelection()
    }
  }

  return (
    <div className="space-y-2">
      {label && (
        <Typography variant="sm-medium" element="label" className="block text-gray-50">
          {label}
        </Typography>
      )}
      <div
        className={cn(
          'relative flex h-[48px] w-full items-center rounded-lg bg-gray-800 px-4',
          'border border-gray-300',
          'transition-all duration-200',
          'focus-within:border-light-green-500 focus-within:shadow-[0_0_0_1px_rgba(50,145,117,0.3)] hover:border-gray-400',
          errorMessage &&
            'border-red-ramo focus-within:border-red-ramo focus-within:shadow-[0_0_0_1px_rgba(215,27,61,0.3)]',
          className,
        )}
      >
        {selectedStage ? (
          <div className="flex items-center justify-between">
            <span className="text-sm-medium text-gray-50">{selectedStage.name}</span>
            <button
              onClick={handleClearSelection}
              className="grid h-6 w-6 place-items-center rounded-full text-gray-300 
                transition-colors hover:bg-gray-700 hover:text-white"
            >
              ×
            </button>
          </div>
        ) : (
          <input
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value)
              setIsCreating(false)
            }}
            onKeyDown={handleKeyDown}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setTimeout(() => setIsFocused(false), 200)}
            className="flex h-full w-full items-center bg-transparent text-base text-gray-50 outline-none placeholder:text-gray-300"
            placeholder={placeholder}
          />
        )}

        {inputValue && !isLoading && isFocused && !selectedStage && (
          <div className="absolute left-0 right-0 top-[48px] z-50 overflow-hidden rounded-lg border border-gray-300 bg-gray-800 shadow-lg">
            {stagesData?.map((stage) => (
              <div
                key={stage.id}
                className="flex items-center justify-between p-3 text-sm text-gray-50 transition-colors hover:bg-gray-700"
              >
                <button className="flex-1 text-left" onClick={() => handleSelect(stage)}>
                  {stage.name}
                </button>
                <button onClick={() => handleDelete(stage.id)} className="ml-2 p-1 text-gray-300 hover:text-red-500">
                  <Trash2 className="h-4 w-4" />
                </button>
              </div>
            ))}
            <button
              type="button"
              className="flex w-full items-center gap-2 border-t border-gray-700 p-3 text-sm text-gray-50 transition-colors hover:bg-gray-700"
              onClick={() => handleCreate(inputValue)}
            >
              <Plus className="h-4 w-4 text-light-green-500" />
              Create &quot;{inputValue}&quot;
            </button>
          </div>
        )}
      </div>
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </div>
  )
}

export default StageSelector
