export const TooltipContent = () => (
  <div className="tooltip-content">
    <div>You can define your project area in two ways:</div>
    <ul style={{ marginTop: 15, textAlign: 'left', gap: 30 }}>
      <li>• Upload a file if you already have a boundary mapped.</li>
      <li>• Draw directly on the map using the drawing tool.</li>
    </ul>
  </div>
)

export const steps = [
  {
    target: '.tool-bar',
    content: <TooltipContent />,
    title: 'Define Your Project Area',
    disableBeacon: true,
  },
]
export const stylesJoyride = {
  options: {
    primaryColor: '#2e846a',
    backgroundColor: '#1B1C1E',
    textColor: '#F5F6F6',
    arrowColor: '#1B1C1E',
  },
  tooltip: {
    fontFamily: '"Nexa", sans-serif',
  },
  tooltipTitle: {
    fontFamily: '"Nexa", sans-serif',
    fontWeight: 600,
  },
  tooltipContent: {
    fontFamily: '"Nexa", sans-serif',
    fontSize: 15,
  },
  buttonNext: {
    fontFamily: '"Nexa", sans-serif',
  },
  buttonBack: {
    fontFamily: '"Nexa", sans-serif',
  },
  buttonSkip: {
    fontFamily: '"Nexa", sans-serif',
  },
}
