import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { useTenantStore } from '../store/tenentStore'
import { ITenant, SUBSCRIPTION_PLAN } from 'src/types/user'
import { plans, roles } from '../constants/constants'
import { ROUTES } from 'src/types/routes'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function formatArea(number: number) {
  const units = ['', 'k', 'M', 'B', 'T', 'Q']
  let unitIndex = 0

  while (number >= 1000 && unitIndex < units.length - 1) {
    number /= 1000
    unitIndex++
  }

  return `${number.toFixed(1)}${units[unitIndex]} ha`
}

export function setDefaultTenant(tenants: ITenant[]) {
  if (tenants && tenants.length > 0) {
    useTenantStore.setState({ currentTenant: tenants[0] })
  }
}

export const getCurrentRoleLabel = (value: string) => {
  return roles.find((role) => role.value === value)?.label || 'Select Role'
}

export const getCurrentPlanLabel = (value: SUBSCRIPTION_PLAN) => {
  return plans.find((plan) => plan.value === value)?.label || 'Select Plan'
}

export function capitalizeText(text?: string) {
  if (!text) return ''
  return text.toLowerCase().replace(/^[a-z]/, (letter) => letter.toUpperCase())
}

export const isActiveLink = (paths: string | string[]) => {
  const currentPath = location.pathname

  if (typeof paths === 'string') {
    return currentPath === paths
  }

  if (paths.includes(ROUTES.PROJECTS)) {
    return currentPath === ROUTES.PROJECTS || currentPath.startsWith('/dashboard/project/')
  }

  return paths.some((path) => currentPath === path)
}

export const zoomToPercent = (zoom: number): number => {
  return Math.round((zoom / 22) * 100)
}

export const percentToZoom = (percent: number): number => {
  return (percent / 100) * 22
}

export const truncateFilename = (name: string, maxLength = 50) => {
  if (name.length <= maxLength) return name

  const extension = name.split('.').pop() || ''
  const nameWithoutExtension = name.substring(0, name.length - extension.length - 1)

  const availableLength = maxLength - 3 - extension.length

  if (availableLength <= 0) {
    return name.substring(0, maxLength - 3) + '...'
  }

  return nameWithoutExtension.substring(0, availableLength) + '...' + (extension ? '.' + extension : '')
}
