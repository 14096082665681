import { IconTrash } from '@tabler/icons-react'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogTrigger,
} from '../../atoms/alert-dialog'
import { FC, useCallback, useState } from 'react'

interface DeleteDialogProps {
  title?: string
  description?: string
  onDelete: () => Promise<void>
  isLoading?: boolean
  triggerClassName?: string
  iconSize?: number
}

const DeleteDialog: FC<DeleteDialogProps> = ({
  title = 'Delete Item',
  description = "You're about to delete this item. Are you sure you want to proceed?",
  onDelete,
  isLoading = false,
  triggerClassName = 'flex h-10 w-16 items-center justify-center rounded-full border border-red-ramo text-red-600 transition-all hover:cursor-pointer hover:bg-red-600 hover:text-white active:scale-95',
  iconSize = 24,
}) => {
  const [opened, setOpened] = useState(false)
  const handleClose = useCallback(() => setOpened(false), [])

  const handleDelete = useCallback(async () => {
    try {
      await onDelete()
      handleClose()
    } catch (error) {
      console.log(error)
    }
  }, [onDelete, handleClose])

  return (
    <AlertDialog open={opened} onOpenChange={setOpened}>
      <AlertDialogTrigger className={triggerClassName}>
        <IconTrash size={iconSize} />
      </AlertDialogTrigger>
      <AlertDialogContent>
        <div className="flex flex-col items-center gap-4">
          <div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#FFADAD]">
            <IconTrash className="h-6 w-6 text-red-600" />
          </div>
          <div className="flex flex-col justify-center gap-6">
            <span className="text-center text-xl text-green-50">{title}</span>
            <span className="text-center text-gray-50">{description}</span>
          </div>
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={isLoading} onClick={handleClose} className="w-40">
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction disabled={isLoading} onClick={handleDelete} className="w-40">
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default DeleteDialog
