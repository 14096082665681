import React, { useState } from 'react'
import * as Tooltip from '@radix-ui/react-tooltip'
import { IconPencil, IconEraser, IconDownload, IconFileUpload, IconTrash } from '@tabler/icons-react'
import CreateProjectViaFileUpload from '../organisms/dialogs/CreateProjectViaFileUpload'

interface ToolbarButtonProps {
  icon: React.ReactNode
  onClick?: () => void
  isLast?: boolean
  isActive?: boolean
  tooltip: string
  className?: string
  forceTooltip?: boolean
}

const ToolbarButton: React.FC<ToolbarButtonProps> = ({
  icon,
  onClick,
  isLast = false,
  isActive = false,
  tooltip,
  className,
  forceTooltip = false,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(forceTooltip)

  React.useEffect(() => {
    setIsTooltipOpen(forceTooltip)
  }, [forceTooltip])

  return (
    <Tooltip.Provider delayDuration={300}>
      <Tooltip.Root open={forceTooltip || isTooltipOpen}>
        <Tooltip.Trigger asChild>
          <button
            className={`flex w-full items-center justify-center rounded-full p-3 text-white transition-colors
              ${isActive ? 'border border-orange-500 bg-gray-600' : 'hover:bg-gray-700'}
              ${isLast ? '' : 'mb-1'} ${className || ''}`}
            onClick={onClick}
            onMouseEnter={() => !forceTooltip && setIsTooltipOpen(true)}
            onMouseLeave={() => !forceTooltip && setIsTooltipOpen(false)}
          >
            {icon}
          </button>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            side="left"
            sideOffset={5}
            className="animate-fadeIn rounded bg-gray-800 px-3 py-2 text-sm text-white shadow-lg"
          >
            {tooltip}
            <Tooltip.Arrow className="fill-gray-800" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  )
}

interface VerticalToolbarProps {
  onPencil?: () => void
  onEraser?: () => void
  onDownload?: () => void
  onAdjust?: () => void
  onShare?: () => void
  onPin?: () => void
  onDelete?: () => void
  isDrawing?: boolean
  hasSelectedFeatures?: boolean
  isGuideTourActive?: boolean
}

export const VerticalToolbar: React.FC<VerticalToolbarProps> = ({
  onPencil,
  onEraser,
  onDownload,
  onAdjust,
  onShare,
  onPin,
  onDelete,
  hasSelectedFeatures = false,
  isDrawing = false,
  isGuideTourActive = false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const openUploadModal = () => setIsModalOpen(!isModalOpen)

  // Determine which buttons to show based on drawing state, guide state, or if features are selected
  const shouldShowAllButtons = isDrawing || isGuideTourActive || hasSelectedFeatures

  return (
    <div className="tool-bar flex w-14 flex-col rounded-full bg-gray-800 px-1 py-2">
      <div className=" mb-3 space-y-1">
        {/* Pencil button - always visible */}
        {/* Upload File button - always visible */}
        <ToolbarButton
          icon={<IconFileUpload size={24} stroke={1.5} />}
          onClick={openUploadModal}
          tooltip="Upload File"
          className="vertical-toolbar-upload"
          forceTooltip={isDrawing}
        />
        <ToolbarButton
          icon={<IconPencil size={24} stroke={1.5} />}
          onClick={onPencil}
          isActive={isDrawing}
          tooltip="Draw Area"
          className="vertical-toolbar-pencil"
          forceTooltip={isDrawing}
        />

        {/* Conditional buttons - only shown when drawing or during guide */}
        {shouldShowAllButtons && (
          <>
            <ToolbarButton
              icon={<IconEraser size={24} stroke={1.5} />}
              onClick={onEraser}
              isActive={hasSelectedFeatures}
              tooltip="Erase"
              className="vertical-toolbar-eraser"
              forceTooltip={isDrawing}
            />
            <ToolbarButton
              icon={<IconDownload size={24} stroke={1.5} />}
              onClick={onDownload}
              tooltip="Download"
              className="vertical-toolbar-download"
              forceTooltip={isDrawing}
            />
          </>
        )}
      </div>

      {shouldShowAllButtons && (
        <>
          <div className="mb-3 h-px w-full bg-gray-600"></div>
          <div className="space-y-1">
            <ToolbarButton
              icon={<IconTrash size={24} stroke={1.5} />}
              onClick={onDelete}
              isLast={true}
              tooltip="Delete"
              className="vertical-toolbar-delete"
              forceTooltip={isDrawing}
            />
          </div>
        </>
      )}

      <CreateProjectViaFileUpload isOpen={isModalOpen} onOpenChange={setIsModalOpen} />
    </div>
  )
}
