import { zodResolver } from '@hookform/resolvers/zod'
import { IconSearch, IconTags, IconTrash } from '@tabler/icons-react'
import { useCallback, useState, useEffect, useMemo } from 'react'
import { useSearchStages, useCreateStage, useDeleteStageTag } from '../../../enviroment/api/services/stageApi'
import { useSearchTypes, useCreateType, useDeleteTypeTag } from '../../../enviroment/api/services/typesApi'
import { SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'
import { SecondaryButton } from '../../atoms/buttons/SecondaryButton'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '../../atoms/dialog'
import { Input } from '../../atoms/inputs/Input'
import Typography from '../../atoms/Typography'
import { Tabs, TabsList, TabsTrigger } from 'src/components/molecules/Tabs'
import { debounce } from 'lodash'

// Schema for creating new tags
const CreateTagSchema = z.object({
  name: z.string().trim().max(35, { message: 'Name cannot exceed 35 characters' }),
})

export type TagFormData = z.infer<typeof CreateTagSchema>

const TagManagementDialog = () => {
  const [opened, setOpened] = useState(false)
  const [activeTab, setActiveTab] = useState('stage')
  const [searchQuery, setSearchQuery] = useState('')
  const [debouncedQuery, setDebouncedQuery] = useState('')
  const [isTyping, setIsTyping] = useState(false)

  // Create a debounced function for search
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        setDebouncedQuery(value)
        setIsTyping(false)
      }, 500),
    [],
  )

  // Handle search input changes with debounce
  const handleSearchChange = (e: { target: { value: string } }) => {
    const value = e.target.value
    setSearchQuery(value)
    setIsTyping(true)
    debouncedSearch(value)
  }

  // Reset search query when switching tabs
  useEffect(() => {
    setSearchQuery('')
    setDebouncedQuery('')
  }, [activeTab])

  // Cleanup debounce on unmount
  useEffect(() => {
    return () => {
      debouncedSearch.cancel()
    }
  }, [debouncedSearch])

  // API integration for stages
  const { data: stages = [], isLoading: isLoadingStages } = useSearchStages({
    query: activeTab === 'stage' ? debouncedQuery : '',
  })
  const { mutateAsync: createStage, isLoading: isCreatingStage } = useCreateStage()
  const { mutateAsync: deleteStage, isLoading: isDeletingStage } = useDeleteStageTag()

  // API integration for types
  const { data: types = [], isLoading: isLoadingTypes } = useSearchTypes({
    query: activeTab === 'type' ? debouncedQuery : '',
  })
  const { mutateAsync: createType, isLoading: isCreatingType } = useCreateType()
  const { mutateAsync: deleteType, isLoading: isDeletingType } = useDeleteTypeTag()

  const form = useForm<TagFormData>({
    mode: 'onSubmit',
    defaultValues: {
      name: '',
    },
    resolver: zodResolver(CreateTagSchema),
  })

  const name = form.watch()
  const handleSubmit: SubmitHandler<TagFormData> = useCallback(
    async (data) => {
      try {
        if (activeTab === 'stage') {
          // Use the API for stages
          await createStage(data.name)
        } else {
          // Use the API for types
          await createType(data.name)
        }

        form.reset()
      } catch (error) {
        console.error('Error creating tag:', error)
        // Handle error appropriately (could add toast notification here)
      }
    },
    [form, activeTab, createStage, createType],
  )

  const handleDelete = useCallback(
    async (id: string) => {
      try {
        if (activeTab === 'stage') {
          // Use the API for stages
          await deleteStage(id)
        } else {
          // Use the API for types
          await deleteType(id)
        }
      } catch (error) {
        console.error('Error deleting tag:', error)
        // Handle error appropriately (could add toast notification here)
      }
    },
    [activeTab, deleteStage, deleteType],
  )

  // For both stages and types, filtering is handled by the API through the query parameter
  const filteredItems = activeTab === 'stage' ? stages : types

  const isDeleting = activeTab === 'stage' ? isDeletingStage : isDeletingType
  const isLoading = (activeTab === 'stage' ? isLoadingStages : isLoadingTypes) && !isTyping

  return (
    <Dialog open={opened} onOpenChange={setOpened}>
      <DialogTrigger className="flex h-11 w-11 items-center justify-center rounded-[8px] text-light-green-500 transition-all hover:cursor-pointer hover:bg-light-green-500 hover:text-white active:scale-95">
        <IconTags size={24} />
      </DialogTrigger>
      <DialogContent className="w-full max-w-3xl" aria-description="tag-management-dialog">
        <DialogHeader aria-describedby="tag-management-dialog">
          <DialogTitle>
            <Typography variant="2xl-nexa-bold">Manage Tags</Typography>
          </DialogTitle>
        </DialogHeader>

        <Tabs defaultValue="stage" value={activeTab} onValueChange={setActiveTab}>
          <TabsList className="mb-4 grid w-full grid-cols-2">
            <TabsTrigger value="stage">Stages</TabsTrigger>
            <TabsTrigger value="type">Types</TabsTrigger>
          </TabsList>

          <div className="mb-4 w-full rounded-lg bg-gray-900 p-2">
            <Input
              type="text"
              placeholder={`Search ${activeTab === 'stage' ? 'stages' : 'types'}...`}
              value={searchQuery}
              onChange={handleSearchChange}
              disabled={false} // Allow typing at all times
              icon={<IconSearch size={20} />}
            />
          </div>

          <div className="max-h-64 overflow-y-auto rounded-lg border border-gray-700 bg-gray-800">
            {isLoading ? (
              <div className="p-4 text-center text-gray-400">
                Loading {activeTab === 'stage' ? 'stages' : 'types'}...
              </div>
            ) : filteredItems.length > 0 ? (
              <div>
                {filteredItems.map((item) => (
                  <div
                    key={item.id}
                    className="group flex items-center justify-between border-b border-gray-700 p-3 hover:bg-gray-700"
                  >
                    <span className="truncate font-medium text-gray-50">{item.name}</span>
                    <button
                      onClick={() => handleDelete(item.id)}
                      className="ml-2 flex items-center justify-center p-2 text-red-500 transition-colors hover:text-red-700"
                      disabled={isDeleting}
                      aria-label={`Delete ${item.name}`}
                    >
                      <IconTrash size={18} />
                    </button>
                  </div>
                ))}
              </div>
            ) : (
              <div className="p-4 text-center text-gray-400">No {activeTab === 'stage' ? 'stages' : 'types'} found</div>
            )}
          </div>

          <form onSubmit={form.handleSubmit(handleSubmit)} className="mt-4">
            <div className="flex w-full flex-row justify-between">
              <div className="w-[60%]">
                <Input
                  type="text"
                  disabled={form.formState.isSubmitting}
                  autoComplete="off"
                  errorMessage={form.formState.errors.name?.message}
                  label={`New ${activeTab === 'stage' ? 'stage' : 'type'} name`}
                  placeholder={`Enter ${activeTab === 'stage' ? 'stage' : 'type'} name`}
                  {...form.register('name')}
                />
              </div>
              <div className="flex items-end">
                <SecondaryButton
                  type="submit"
                  size="sm"
                  disabled={name.name.length < 2}
                  isLoading={
                    form.formState.isSubmitting ||
                    (activeTab === 'stage' && isCreatingStage) ||
                    (activeTab === 'type' && isCreatingType)
                  }
                >
                  Add
                </SecondaryButton>
              </div>
            </div>
          </form>
        </Tabs>
      </DialogContent>
    </Dialog>
  )
}

export default TagManagementDialog
