import { z } from 'zod'
import { userTypes, userPurposes } from '../constants/constants'
const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

const email = z
  .string()
  .transform((str) => str.trim()) // Trim spaces before validation
  .pipe(
    z
      .string()
      .min(1, 'Email is required')
      .max(254, 'Email is too long')
      .refine((email) => emailRegex.test(email), {
        message: 'Please enter a valid email address',
      }),
  )
  .refine((val) => val === val.trim(), {
    message: 'Email cannot start or end with spaces',
  })

const isAgree = z.boolean().refine((value) => value === true, { message: 'You must agree to the terms and conditions' })
const password = z
  .string()
  .trim()
  .min(8, 'Password must be at least 8 characters long')
  .regex(/^(?=.*[A-Z])/, 'Password must include at least one uppercase letter')
  .regex(/^(?=.*[a-z])/, 'Password must include at least one lowercase letter')
  .regex(/^(?=.*\d)/, 'Password must include at least one number')
  .regex(/^(?=.*[!@#$%^&*])/, 'Password must include at least one special character (!@#$%^&*)')
  .regex(/^(?!.*\s)/, 'Password cannot contain spaces')

const firstName = z
  .string()
  .trim()
  .min(1, 'First name is required')
  .max(50, 'First name cannot exceed 50 characters')
  .regex(/^[\p{L}]+$/u, 'First name must contain only letters')
  .refine((val) => val.length > 0, 'First name is required')

const lastName = z
  .string()
  .trim()
  .min(1, 'Last name is required')
  .max(50, 'Last name cannot exceed 50 characters')
  .regex(/^[\p{L}]+$/u, 'Last name must contain only letters')
  .refine((val) => val.length > 0, 'Last name is required')

const companyName = z
  .string()
  .trim()
  .min(1, 'Company name must be at least 2 characters long')
  .max(45, 'Company name cannot exceed 100 characters')
  .refine((value) => !/[<>]/.test(value), 'Company name must not contain "<" or ">" characters to prevent XSS attacks')

const userTypesArray = userTypes.map((type) => type.value)
const purposesArray = userPurposes.map((purpose) => purpose.value)

const description = z.enum(userTypesArray as [string, ...string[]])
const purpose = z.enum(purposesArray as [string, ...string[]])

export { isAgree, email, password, firstName, lastName, companyName, description, purpose }
