// columns/learningMaterialsColumns.tsx
import { IconEye } from '@tabler/icons-react'
import { ColumnDef } from '@tanstack/react-table'
import { FileIcon } from 'lucide-react'
import IdCell from 'src/components/atoms/IdCell'
import DeleteDialog from 'src/components/organisms/dialogs/DeleteDialog'
import EditMaterialAccess from 'src/components/organisms/dialogs/EditMaterialAccess'
import { LearningMaterial } from 'src/enviroment/api/services/superAdminApi'
import { formatBytes } from 'src/enviroment/constants/constants'

export const useLearningMaterialColumns = (onDelete: (id: string) => void) => {
  const columns: ColumnDef<LearningMaterial>[] = [
    {
      accessorKey: 'id',
      header: 'ID',
      cell: (info) => <IdCell id={info.getValue()} />,
    },
    {
      id: 'preview',
      header: 'Preview',
      cell: ({ row }) => (
        <div className="relative h-16 w-24">
          <div className="absolute inset-0 flex items-center justify-center rounded bg-secondary">
            <FileIcon className="h-6 w-6 text-muted-foreground" />
          </div>
        </div>
      ),
    },
    {
      accessorKey: 'name',
      header: 'Name',
      // concut big name ...
      cell: ({ row }) => (
        <span className="text-sm text-gray-400">
          {row.original.name.length > 20 ? `${row.original.name.slice(0, 20)}...` : row.original.name}
        </span>
      ),
    },
    {
      accessorKey: 'type',
      header: 'Type',
    },
    {
      accessorKey: 'mimeType',
      header: 'File Type',
      cell: ({ row }) => <span className="text-sm text-gray-400">{row.original.mimeType}</span>,
    },
    {
      accessorKey: 'sizeBytes',
      header: 'Size',
      cell: ({ row }) => <span className="text-sm text-gray-400">{formatBytes(row.original.sizeBytes)}</span>,
    },
    {
      id: 'actions',
      header: 'Actions',
      cell: ({ row }) => (
        <div className="flex items-center gap-5">
          <a href={row.original.blobUrl || '#'} target="_blank" rel="noopener noreferrer">
            <IconEye className="h-5 w-5 text-light-green-400" />
          </a>
          <EditMaterialAccess material={row.original} />
          <DeleteDialog
            title="Delete learming material"
            description={`You're about to delete the ${row.original.name}  material. Are you sure you want to proceed?`}
            onDelete={async () => onDelete(row.original.id)}
          />
        </div>
      ),
    },
  ]

  return columns
}
