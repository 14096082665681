import React, { useCallback, useState } from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import { IconFileUpload, IconX } from '@tabler/icons-react'
import Typography from 'src/components/atoms/Typography'
import { formatBytes } from 'src/enviroment/constants/constants'

interface SingleFileDropzoneProps {
  onFileChange: (file: File | null) => void
  file: File | null
  accept?: Accept
}

const SingleFileDropzone: React.FC<SingleFileDropzoneProps> = ({ onFileChange, file, accept }) => {
  const [error, setError] = useState<string | null>(null)

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setError(null)
      if (acceptedFiles.length > 0) {
        onFileChange(acceptedFiles[0]) // Take only the first file
      }
    },
    [onFileChange],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      // 'application/octet-stream': ['.shp', '.kmz', '.kml', '.geojson'],
      'application/octet-stream': ['.geojson'],
    },
    maxFiles: 1,
    multiple: false,
    onDropRejected: (fileRejections) => {
      const errorMessage = fileRejections[0]?.errors[0]?.message || 'File type not allowed'
      setError(`${errorMessage}. Please upload GeoJSON, Shapefile, KMZ, or KML files.`)
      setTimeout(() => setError(null), 3000) // Clear error after 3 seconds
    },
  })

  const removeFile = useCallback(() => {
    onFileChange(null)
  }, [onFileChange])

  return (
    <div className="space-y-4">
      {!file ? (
        <div
          {...getRootProps()}
          className={`relative flex min-h-[200px] cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed p-6 transition-colors ${
            isDragActive ? 'border-light-green-500 bg-gray-800' : 'border-gray-300 bg-gray-800'
          }`}
        >
          <input {...getInputProps()} />
          <div className="mb-4 rounded-lg border border-light-green-500 bg-light-green-100 p-2">
            <IconFileUpload size={40} className="text-light-green-500" stroke={1.5} />
          </div>
          <Typography variant="base-bold" className="mb-2 text-center text-light-green-50">
            Drop file here or click to upload
          </Typography>
          <Typography variant="sm" className="text-center text-gray-100">
            Upload a GeoJSON, to begin analyzing
          </Typography>
          <Typography variant="xs" className="mt-2 text-center text-gray-100">
            If you have a spatial file for your project boundary, upload it. If not, simply draw your boundary directly
            on the map to get started.
          </Typography>
          {error && (
            <Typography variant="sm" className="mt-2 text-center text-red-500">
              {error}
            </Typography>
          )}
        </div>
      ) : (
        <div className="flex w-full items-center gap-3 rounded-lg bg-gray-800 p-3">
          <div className="flex-shrink-0">
            <div className="rounded-lg border border-light-green-500 bg-light-green-100 p-2">
              <IconFileUpload size={24} className="text-light-green-500" stroke={1.5} />
            </div>
          </div>
          <div className="flex flex-1 flex-col">
            <div className="flex items-center justify-between">
              <Typography variant="sm" className="text-light-green-50">
                {file.name.length > 60 ? `${file.name.slice(0, 20)}...` : file.name}
              </Typography>
              {/* Change button to div with onClick and add type="button" */}
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation()
                  removeFile()
                }}
                className="text-light-green-50 hover:text-light-green-100"
              >
                <IconX size={16} stroke={1.5} />
              </button>
            </div>
            <Typography variant="xs" className="text-gray-400">
              {formatBytes(file.size)}
            </Typography>
          </div>
        </div>
      )}
    </div>
  )
}

export default SingleFileDropzone
