import { useState, useCallback } from 'react'
import { IconBrush } from '@tabler/icons-react'

import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '../../atoms/dialog'
import { PrimaryButton } from '../../atoms/buttons/PrimaryButton'
import { SecondaryButton } from '../../atoms/buttons/SecondaryButton'
import Typography from '../../atoms/Typography'
import { useUploadStyle } from 'src/enviroment/api/services/layersApi'
import JSONEditor from 'src/components/molecules/JsonEditor'

interface UploadJsonStylesDialogProps {
  id: string
  json: any
}

const UploadJsonStylesDialog = ({ id, json }: UploadJsonStylesDialogProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [jsonContent, setJsonContent] = useState<string>(JSON.stringify(json))
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const { mutateAsync, isLoading: isUploading } = useUploadStyle()

  const handleClose = useCallback((): void => {
    setIsOpen(false)
  }, [])

  const handleJsonChange = useCallback((value: string): void => {
    setJsonContent(value)
    setError(null)
  }, [])

  const handleSubmit = useCallback(async (): Promise<void> => {
    try {
      setError(null)
      setIsSubmitting(true)

      const parsedJson = JSON.parse(jsonContent || '{}')

      await mutateAsync({
        fileId: id,
        style: parsedJson,
      })

      setIsOpen(false)
      setJsonContent(JSON.stringify(json))
    } catch (error) {
      if (error instanceof SyntaxError) {
        setError('Invalid JSON format')
      } else {
        setError('Failed to upload JSON')
      }
    } finally {
      setIsSubmitting(false)
    }
  }, [jsonContent, id, mutateAsync])

  const isLoading = isSubmitting || isUploading

  const handleOpenChange = useCallback((open: boolean): void => {
    setIsOpen(open)
    if (!open) {
      setError(null)
    } else {
      setJsonContent(JSON.stringify(json))
    }
  }, [])

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogTrigger className="flex h-11 w-11 items-center justify-center rounded-[8px] text-light-green-500 transition-all hover:cursor-pointer hover:text-white active:scale-95">
        <IconBrush size={24} />
      </DialogTrigger>

      <DialogContent className="w-[800px] max-w-3xl" aria-description="upload-json-styles-dialog">
        <DialogHeader aria-describedby="upload-json-styles-dialog">
          <DialogTitle>
            <Typography variant="xl">Upload Styles</Typography>
          </DialogTitle>
        </DialogHeader>

        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <JSONEditor value={jsonContent} onChange={handleJsonChange} height="500px" isDisabled={isLoading} />
            {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
          </div>
        </div>

        <DialogFooter className="sm:justify-center sm:space-x-6">
          <SecondaryButton isLoading={isLoading} onClick={handleClose} className="w-40">
            Cancel
          </SecondaryButton>
          <PrimaryButton onClick={handleSubmit} isLoading={isLoading} className="w-40">
            Upload
          </PrimaryButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default UploadJsonStylesDialog
