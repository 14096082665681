import { FC } from 'react'

interface JumpingDotsProps {
  className?: string
}

export const JumpingDots: FC<JumpingDotsProps> = ({ className = '' }) => {
  return (
    <div className={`flex items-center justify-center space-x-1 ${className}`}>
      <span className="inline-block h-2 w-2 animate-[bounce_0.6s_0s_infinite] rounded-full bg-current">&nbsp;</span>
      <span className="inline-block h-2 w-2 animate-[bounce_0.6s_0.1s_infinite] rounded-full bg-current">&nbsp;</span>
      <span className="inline-block h-2 w-2 animate-[bounce_0.6s_0.2s_infinite] rounded-full bg-current">&nbsp;</span>
    </div>
  )
}
