import React from 'react'
import { Helmet } from 'react-helmet-async'
import AuthWrapper from 'src/enviroment/layouts/auth-wrapper'
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { z } from 'zod'
import { forgotPasswordSchema } from 'src/enviroment/validation/schema'
import Typography from 'src/components/atoms/Typography'
import { Input } from 'src/components/atoms/inputs/Input'
import { PrimaryButton } from 'src/components/atoms/buttons/PrimaryButton'
import { SecondaryButton } from 'src/components/atoms/buttons/SecondaryButton'

type ForgotPasswordFormData = z.infer<typeof forgotPasswordSchema>

const defaultValues = { email: '' } satisfies ForgotPasswordFormData

export interface ForgotPasswordTemplateProps {
  onSubmit: SubmitHandler<ForgotPasswordFormData>
  goBack: () => void
  isLoading?: boolean
}

const ForgotPasswordTemplate: React.FC<ForgotPasswordTemplateProps> = ({ onSubmit, goBack, isLoading = false }) => {
  const methods = useForm<ForgotPasswordFormData>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(forgotPasswordSchema),
  })

  const {
    handleSubmit,
    formState: { isValid },
  } = methods

  return (
    <FormProvider {...methods}>
      <Helmet>
        <title>RAMO - Forgot Password</title>
      </Helmet>
      <AuthWrapper>
        <div className="space-y-8">
          <div className="space-y-8 text-left">
            <Typography variant="4xl-nexa-bold">Forgot Password</Typography>
            <div>
              <Typography variant="base" className="text-left">
                Enter the email address registered with your account.
              </Typography>
              <Typography variant="base" className="text-left">
                We’ll send you a link to reset your password.
              </Typography>
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <Controller
              name="email"
              control={methods.control}
              render={({ field: { onChange, ...field }, formState }) => (
                <Input
                  {...field}
                  onChange={onChange}
                  label="Email Address"
                  autoFocus
                  type="email"
                  errorMessage={formState.errors.email?.message}
                />
              )}
            />

            <div className="space-y-4">
              <PrimaryButton type="submit" isLoading={isLoading} disabled={!isValid && !isLoading} fullWidth>
                Send Email
              </PrimaryButton>
              <SecondaryButton
                onClick={goBack}
                type="button"
                fullWidth
                className="text-light-green-500 hover:text-light-green-400"
              >
                Cancel
              </SecondaryButton>
            </div>
          </form>
        </div>
      </AuthWrapper>
    </FormProvider>
  )
}

export default ForgotPasswordTemplate
