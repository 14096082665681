import React from 'react'
import Typography from '../atoms/Typography'

interface DashboardHeaderProps {
  title: string
  rightSide?: React.ReactNode
  subtitle?: string
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ title, subtitle, rightSide }) => {
  return (
    <div className="flex w-full items-center justify-between border-b border-b-gray-600 px-8 py-3 text-gray-50">
      <div>
        <Typography variant="lg-nexa-bold">{title}</Typography>

        {subtitle && <Typography variant="sm">{subtitle}</Typography>}
      </div>
      {rightSide && <div className="ml-4">{rightSide}</div>}
    </div>
  )
}

export default DashboardHeader
