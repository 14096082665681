import { FC, useState, useEffect } from 'react'
import Typography from 'src/components/atoms/Typography'
import { Input } from 'src/components/atoms/inputs/Input'
import { IconSearch } from '@tabler/icons-react'
import AddWorkspaceDialog from 'src/components/organisms/dialogs/AddWorkSpaceDialog'
import DataTable from 'src/templates/members/data-table'
import { useColumns } from 'src/templates/dashboard/super-admin/colums/columsTenants'
import { useQueryClient } from '@tanstack/react-query'
import { SuperAdminQueryKeys, useGlobalTenantsQuery } from 'src/enviroment/api/services/superAdminApi'
import { useDebounceValue } from 'src/enviroment/hooks/useDebounceValue'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface WorkspacesTabProps {}

export const WorkspacesTab: FC<WorkspacesTabProps> = () => {
  const queryClient = useQueryClient()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearch = useDebounceValue(searchTerm, 800)
  const columns = useColumns()

  useEffect(() => {
    queryClient.invalidateQueries([SuperAdminQueryKeys.GlobalTenantsList, debouncedSearch])
  }, [debouncedSearch, queryClient])

  const {
    data: tenants,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isLoading,
  } = useGlobalTenantsQuery({
    query: searchTerm,
  })

  return (
    <div className={`my-6 flex flex-col gap-8 rounded-[8px] bg-gray-800 p-8`}>
      <div className="flex items-center justify-between gap-8">
        <Typography variant="xl" className="shrink-0 text-light-green-50">
          Platform Workspaces
        </Typography>
        <div className="ml-auto flex items-center gap-6">
          <Input
            containerClassName="w-[300px]"
            icon={<IconSearch />}
            placeholder="Search"
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
          />
          <AddWorkspaceDialog />
        </div>
      </div>
      <DataTable
        columns={columns}
        data={tenants?.pages.flatMap((page) => page.tenants) || []}
        onLoadMore={fetchNextPage}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
    </div>
  )
}
