import { FC, useState, useEffect } from 'react'
import Typography from 'src/components/atoms/Typography'
import { Input } from 'src/components/atoms/inputs/Input'
import { IconSearch } from '@tabler/icons-react'
import FilterButton from 'src/components/molecules/FilterButton'
import { UserTable } from 'src/templates/dashboard/super-admin/tables/users-table'
import { useQueryClient } from '@tanstack/react-query'
import { SuperAdminQueryKeys, useUsersQuery, useApproveUser } from 'src/enviroment/api/services/superAdminApi'
import { useDebounceValue } from 'src/enviroment/hooks/useDebounceValue'
import { enqueueSnackbar } from 'notistack'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UsersTabProps {}

export const UsersTab: FC<UsersTabProps> = () => {
  const queryClient = useQueryClient()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [approvalFilter, setApprovalFilter] = useState<'approved' | 'not_approved' | null>(null)

  const debouncedSearch = useDebounceValue(searchTerm, 800)
  const debouncedApprovalFilter = useDebounceValue(approvalFilter, 300)

  const approveMutation = useApproveUser()

  useEffect(() => {
    queryClient.invalidateQueries([SuperAdminQueryKeys.UsersList, debouncedSearch, debouncedApprovalFilter])
  }, [debouncedSearch, debouncedApprovalFilter, queryClient])

  const {
    data: users,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useUsersQuery({
    isApproved:
      debouncedApprovalFilter === 'approved' ? true : debouncedApprovalFilter === 'not_approved' ? false : undefined,
    query: debouncedSearch,
  })

  const handleApprove = async (userId: string) => {
    try {
      await approveMutation.mutateAsync(userId)
    } catch (error) {
      enqueueSnackbar('Failed to approve user', { variant: 'error' })
    }
  }

  return (
    <div className={`my-6 flex flex-col gap-8 rounded-[8px] bg-gray-800 p-8`}>
      <div className="flex items-center justify-between">
        <Typography variant="xl" className="w-2/4 text-light-green-50">
          Platform Users
        </Typography>
        <div className="flex w-full items-center gap-6">
          <Input
            containerClassName="w-[70%]"
            icon={<IconSearch />}
            placeholder="Search platform user"
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
          />
          <div className="w-[30%]">
            <FilterButton onFilterChange={setApprovalFilter} initialFilter={approvalFilter} />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <UserTable
          users={users?.pages.flatMap((page) => page.users) || []}
          isLoading={isLoading}
          error={undefined}
          onApprove={handleApprove}
          fetchNextPageUsers={fetchNextPage}
          hasNextPageUsers={hasNextPage}
          isFetchingNextPageUsers={isFetchingNextPage}
        />
      </div>
    </div>
  )
}
