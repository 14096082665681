import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { AlertDialogTitle } from '@radix-ui/react-alert-dialog'
import { Button } from '../atoms/buttons/Button'
import { PrimaryButton } from '../atoms/buttons/PrimaryButton'
import { Input } from '../atoms/inputs/Input'
import { TextArea } from '../atoms/inputs/InputTextArea'
import { z } from 'zod'
import { supportFormSchema } from 'src/enviroment/validation/schema'
import FileUploadContainer from '../organisms/FileUploadContainer'
import ModalContainer from '../organisms/ModalContainer'
import { IconChecks, IconSend } from '@tabler/icons-react'
import { useCreateSupportTicket } from 'src/enviroment/api/services/supportApi'
import Typography from '../atoms/Typography'
import { ErrorMessage } from '../atoms/ErrorMessage'

export type SupportFormData = z.infer<typeof supportFormSchema>

interface SupportDialogProps {
  isOpen: boolean
  onClose: () => void
}

type FormStep = 'form' | 'success'

const SupportDialog: React.FC<SupportDialogProps> = ({ isOpen, onClose }) => {
  const [formStep, setFormStep] = useState<FormStep>('form')
  const { mutateAsync, isLoading } = useCreateSupportTicket()
  const {
    control,
    handleSubmit,
    reset: resetForm,
    formState,
    setValue,
  } = useForm<SupportFormData>({
    resolver: zodResolver(supportFormSchema),
    defaultValues: {
      subject: '',
      description: '',
      file: null,
    },
  })

  const handleClose = () => {
    setFormStep('form')
    resetForm()
    onClose()
  }

  const handleFileSelect = (selectedFile: File, onChange: (value: File | null) => void) => {
    onChange(selectedFile)
  }

  const handleFileRemove = (onChange: (value: File | null) => void) => {
    onChange(null)
  }

  const onSubmit = async (data: SupportFormData) => {
    try {
      await mutateAsync({
        ...data,
        file: data.file || undefined,
      })
      setFormStep('success')
    } catch (error) {
      console.error('Failed to submit support request:', error)
    }
  }

  if (formStep === 'success') {
    return (
      <ModalContainer isOpen={isOpen} onClose={handleClose}>
        <div className="flex flex-col items-center py-6">
          <div className="mb-4 rounded-full bg-light-green-100 p-3">
            <IconChecks className="h-6 w-6 text-light-green-500" />
          </div>
          <AlertDialogTitle className="text-xl font-semibold text-light-green-50">
            Your request has been sent.
          </AlertDialogTitle>
          <p className="mt-2 text-center text-sm text-gray-400">
            Assistance will be provided to the email address you provided during registration.
          </p>
          <Button onClick={handleClose} className="mt-6 w-full bg-light-green-500 text-white hover:bg-light-green-600">
            Back
          </Button>
        </div>
      </ModalContainer>
    )
  }

  return (
    <ModalContainer isOpen={isOpen} onClose={handleClose}>
      <div>
        <AlertDialogTitle className=" mt-2 font-nexa text-[24px] font-bold text-light-green-50">
          Need support?
        </AlertDialogTitle>
        <Typography variant="base" className="text-light-green-50">
          Tell us what issue you have encountered, and we will send you an email response with help on resolving these
          issues.
        </Typography>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
        <div className="space-y-4">
          <div>
            <Controller
              name="subject"
              control={control}
              render={({ field: { onChange, ...field }, formState }) => (
                <Input
                  {...field}
                  onChange={onChange}
                  label="Subject"
                  autoFocus
                  placeholder="Text"
                  type="text"
                  errorMessage={formState.errors.subject?.message}
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="description"
              control={control}
              render={({ field: { onChange, ...field }, formState }) => (
                <TextArea
                  {...field}
                  onChange={onChange}
                  label="How we can help you?"
                  placeholder="Type here with what issue you encountered, and we will send you an email response with help on resolving these issues."
                  errorMessage={formState.errors.description?.message}
                />
              )}
            />
          </div>

          <Controller
            name="file"
            control={control}
            render={({ field: { onChange, value, ...field }, formState }) => (
              <>
                <FileUploadContainer
                  file={value}
                  accept=".jpg,.jpeg,.png,.pdf,.geojson,.json,.kml,.kmz,.shp"
                  onFileSelect={(file) => handleFileSelect(file, onChange)}
                  onFileRemove={() => handleFileRemove(onChange)}
                />
                <ErrorMessage message={formState.errors.file?.message} />
              </>
            )}
          />

          <div className="flex justify-end">
            <PrimaryButton type="submit" disabled={isLoading} rightIcon={<IconSend size={18} />}>
              {isLoading ? 'Sending...' : 'Send'}
            </PrimaryButton>
          </div>
        </div>
      </form>
    </ModalContainer>
  )
}

export default SupportDialog
