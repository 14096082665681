import React, { useState } from 'react'
import Typography from 'src/components/atoms/Typography'
import { IconMapPin, IconRuler, IconStack2, IconTree, IconPhotoOff, IconPhoto } from '@tabler/icons-react'
import { Project } from 'src/types/projects'
import useAuth from 'src/enviroment/hooks/useAuth'
import { UserRole } from 'src/types/user'
import { formatArea } from 'src/enviroment/lib/utils'
import { ActionButton } from '../atoms/buttons/ActionButton'
import DeleteMyProjectDialog from '../organisms/dialogs/DeleteMyProjectDialog'
import EditMyProjectDialog from '../organisms/dialogs/EditMyProjectDialog'

interface ProjectCardProps {
  project: Project
  onOpen?: (project: Project) => void
}

const ProjectCard: React.FC<ProjectCardProps> = ({ project, onOpen }) => {
  const { userRole } = useAuth()
  const isDeleteAllowed = userRole === UserRole.ADMIN || userRole === UserRole.CONTRIBUTOR
  const [isLoading, setIsLoading] = useState(true)
  const [imageError, setImageError] = useState(false)

  const leftColumnDetails = [
    {
      icon: <IconMapPin className="h-5 w-5" />,
      text: project.location,
    },
    {
      icon: <IconRuler className="h-5 w-5" />,
      text: formatArea(project.totalAreaHectares),
    },
  ]

  const rightColumnDetails = [
    {
      icon: <IconStack2 className="h-5 w-5" />,
      text: project.stage?.name,
      show: !!project.stage,
    },
    {
      icon: <IconTree className="h-5 w-5" />,
      text: project.types.map((type) => type.name).join(', '),
      show: project.types.length > 0,
    },
  ].filter((detail) => detail.show !== false)

  const handleImageLoad = () => {
    setIsLoading(false)
  }

  const handleImageError = () => {
    setIsLoading(false)
    setImageError(true)
  }

  // Determine if we should show the loading component
  const shouldShowLoadingComponent = isLoading && !project.thumbnailUrl

  return (
    <div className="rounded-lg p-px transition-all hover:bg-gradient-to-r hover:from-[#F5F5DC] hover:to-[#329175]">
      <div className="group relative overflow-hidden rounded-lg bg-gray-800 transition-colors hover:bg-gray-900">
        <div className="relative h-40 w-full">
          {project.thumbnailUrl && (
            <img
              src={project.thumbnailUrl}
              alt={project.name}
              className={`h-40 w-full object-cover ${isLoading ? 'opacity-0' : 'opacity-100'}`}
              onLoad={handleImageLoad}
              onError={handleImageError}
            />
          )}

          {/* Loading state with pulse animation - only shown when no image or error */}
          {shouldShowLoadingComponent && (
            <div className="absolute inset-0 flex flex-col items-center justify-center bg-gray-700">
              <div className="animate-pulse">
                <IconPhoto className="h-8 w-8 text-gray-400" />
              </div>
              <Typography variant="sm" className="mt-3 text-gray-400">
                Thumbnail is being generated...
              </Typography>
            </div>
          )}

          {/* Error state or no thumbnail - only when not loading */}
          {(!project.thumbnailUrl || imageError) && !isLoading && (
            <div className="absolute inset-0 flex flex-col items-center justify-center bg-gray-700">
              <IconPhotoOff className="h-8 w-8 text-gray-400" />
              <Typography variant="sm" className="mt-2 text-gray-400">
                No image available
              </Typography>
            </div>
          )}

          {/* Delete Dialog - will be shown only on hover via CSS */}
          {isDeleteAllowed && (
            <div className="absolute right-2 top-2 z-10 opacity-0 transition-opacity duration-200 group-hover:opacity-100">
              <div className="flex flex-col space-y-2">
                <DeleteMyProjectDialog projectId={project.id} projectName={project.name} />
                <EditMyProjectDialog project={project} />
              </div>
            </div>
          )}
        </div>

        <div className="space-y-4 p-4">
          <div className="flex flex-row items-center justify-between">
            <Typography variant="base-bold" className="text-light-green-50">
              {project.name}
            </Typography>
            <ActionButton onClick={() => onOpen?.(project)} />
          </div>

          <div className="flex gap-4">
            {/* Left Column */}
            <div className="flex-1 space-y-4">
              {leftColumnDetails.map((detail, index) => (
                <div key={index} className="flex items-center gap-2 text-light-green-50">
                  {detail.icon}
                  <Typography variant="sm">{detail.text}</Typography>
                </div>
              ))}
            </div>

            {/* Right Column */}
            <div className="flex-1 space-y-4">
              {rightColumnDetails.map((detail, index) => (
                <div key={index} className="flex items-center gap-2 text-light-green-50">
                  {detail.icon}
                  <Typography variant="sm" className="capitalize">
                    {detail.text}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectCard
