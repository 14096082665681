import React, { forwardRef, useState } from 'react'
import { cn } from 'src/enviroment/lib/utils'
import { IconEye, IconEyeOff } from '@tabler/icons-react'
import Typography from '../Typography'
import { ErrorMessage } from '../ErrorMessage'

type InputType = 'text' | 'email' | 'password' | 'number' | 'tel' | 'url' | 'search' | 'date'

export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  icon?: React.ReactNode
  label?: string
  errorMessage?: string
  className?: string
  containerClassName?: string
  type?: InputType
  labelClassName?: string
  autoComplete?: string
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { className, containerClassName, type = 'text', icon, errorMessage, label, labelClassName, autoComplete, ...props },
    ref,
  ) => {
    const [showPassword, setShowPassword] = useState(false)

    const isPassword = type === 'password'
    const inputType = isPassword ? (showPassword ? 'text' : 'password') : type

    // Определяем значение autocomplete по умолчанию на основе типа поля
    const defaultAutoComplete = (() => {
      if (type === 'email') return 'new-email'
      if (type === 'password') return 'new-password'
      return 'off'
    })()

    return (
      <div className={cn('space-y-2', containerClassName)}>
        {label && (
          <Typography variant="sm-medium" element="label" className={cn('block text-gray-50', labelClassName)}>
            {label}
          </Typography>
        )}
        <div className="relative">
          <input
            type={inputType}
            className={cn(
              'font-regular flex h-12 w-full rounded-lg bg-gray-800 px-4 py-2 font-inter text-[14px] text-gray-50',
              'border border-gray-300',
              'file:border-0 file:bg-transparent file:text-sm file:font-medium',
              'placeholder:text-gray-50',
              'focus-visible:outline-none',
              'transition-colors duration-200',
              'disabled:cursor-not-allowed disabled:opacity-50',
              errorMessage
                ? ['border-red-ramo', 'focus:border-red-ramo']
                : ['hover:border-gray-400', 'focus:border-light-green-500'],
              icon && 'pl-12',
              className,
            )}
            autoComplete={autoComplete || defaultAutoComplete}
            ref={ref}
            {...props}
          />

          {icon && <div className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-200">{icon}</div>}

          {isPassword && (
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-200 hover:text-white"
            >
              {showPassword ? <IconEyeOff className="h-6 w-6" /> : <IconEye className="h-6 w-6" />}
            </button>
          )}
        </div>
        {errorMessage && <ErrorMessage message={errorMessage} />}
      </div>
    )
  },
)

Input.displayName = 'Input'

export { Input }
