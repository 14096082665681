import React, { useCallback, useState, useEffect } from 'react'
import { Upload, X } from 'lucide-react'

const FileUpload = React.forwardRef<
  HTMLInputElement,
  {
    logoUrl?: string | null
    error?: string
    accept?: string
    disabled?: boolean
    onChange?: (file: File | null) => void
    onClear?: () => void
  }
>((props, ref) => {
  const { logoUrl, error, accept, disabled, onChange, onClear } = props
  const [preview, setPreview] = useState<string | null>(null)

  // Reset preview when logoUrl changes externally
  useEffect(() => {
    if (logoUrl) {
      setPreview(null)
    }
  }, [logoUrl])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      const newPreview = URL.createObjectURL(file)
      setPreview(newPreview)
      onChange?.(file)
    }
  }

  const handleClear = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()

    // Clear the file input
    const fileInput = document.getElementById('fileInput') as HTMLInputElement
    if (fileInput) {
      fileInput.value = ''
    }

    // Clear the preview
    if (preview) {
      URL.revokeObjectURL(preview)
    }
    setPreview(null)

    // Notify parent of clearing
    onChange?.(null)
    onClear?.()
  }

  // Determine which image to show - preview has priority over logoUrl
  const displayImage = preview || logoUrl

  return (
    <div className="relative">
      <div
        onClick={() => !disabled && document.getElementById('fileInput')?.click()}
        className={`
          relative 
          flex 
          h-[145px] 
          w-[140px]
          items-center
          justify-center 
          rounded-[8px] 
          border-2
          transition-all
          ${displayImage ? '' : 'border-dashed border-gray-300 hover:border-light-green-500'}
          ${disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}
        `}
      >
        {displayImage ? (
          <>
            <img src={displayImage} alt="Logo" className="h-full w-full rounded-[8px] object-contain" />
            {!disabled && (
              <button
                type="button"
                onClick={handleClear}
                className="absolute -right-2 -top-2 rounded-full border border-gray-200 bg-white p-1 transition-colors hover:bg-gray-100"
              >
                <X className="h-4 w-4 text-gray-600" />
              </button>
            )}
          </>
        ) : (
          <Upload className="h-6 w-6 text-light-green-500" />
        )}
      </div>
      <input
        id="fileInput"
        ref={ref}
        type="file"
        accept={accept}
        disabled={disabled}
        onChange={handleChange}
        className="hidden"
      />
      {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
    </div>
  )
})

FileUpload.displayName = 'FileUpload'

export default FileUpload
