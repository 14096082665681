import React from 'react'
import { IconHelp } from '@tabler/icons-react'
import Typography from '../atoms/Typography'

interface SupportCardProps {
  title?: string
  description?: string
  buttonText?: string
  onRequestSupport?: () => void
  className?: string
  isCollapsed?: boolean
}

const SupportCard: React.FC<SupportCardProps> = ({
  title = 'Support',
  description = 'Tell us what issue you have encountered, and we will help you resolving those issues.',
  buttonText = 'Request Support',
  onRequestSupport,
  className = '',
  isCollapsed = false,
}) => {
  const handleRequestSupport = () => {
    if (onRequestSupport) {
      onRequestSupport()
    }
  }

  return (
    <div
      className={`${
        isCollapsed ? 'flex items-center justify-center p-2 py-2' : 'rounded-lg bg-gray-700 p-4'
      } text-gray-50 ${className}`}
      onClick={isCollapsed ? handleRequestSupport : undefined}
    >
      {isCollapsed ? (
        <IconHelp size={20} stroke={1.5} className="mx-auto" />
      ) : (
        <>
          <div className="mb-4 flex items-center">
            <IconHelp size={13} stroke={1.5} className="mr-2" />
            <Typography variant="sm-medium" className="font-semibold">
              {title}
            </Typography>
          </div>

          <Typography variant="sm" className="mb-3 text-gray-50">
            {description}
          </Typography>

          <button
            onClick={handleRequestSupport}
            className="rounded-full bg-light-green-900 px-7 py-1 text-lg text-gray-50 transition-colors hover:bg-light-green-800"
          >
            <Typography variant="sm-medium">{buttonText}</Typography>
          </button>
        </>
      )}
    </div>
  )
}

export default SupportCard
