import React, { useCallback, useEffect, useState } from 'react'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { IconPlus } from '@tabler/icons-react'
import { useInView } from 'react-intersection-observer'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '../../atoms/dialog'
import { Input } from '../../atoms/inputs/Input'
import { SecondaryButton } from '../../atoms/buttons/SecondaryButton'
import { PrimaryButton } from '../../atoms/buttons/PrimaryButton'
import { useCreateNewWorkSpace, useUsersQuery } from 'src/enviroment/api/services/superAdminApi'
import { Label } from '../../atoms/label'
import { RadioGroup, RadioGroupItem } from '../../atoms/radio-group'
import { ErrorMessage } from '../../atoms/ErrorMessage'
import Typography from '../../atoms/Typography'
import { zodResolver } from '@hookform/resolvers/zod'

const AddWorkspaceSchema = z.object({
  name: z
    .string()
    .trim()
    .min(3, { message: 'Name must be at least 3 characters' })
    .max(50, { message: 'Name cannot exceed 50 characters' })
    .regex(/^[a-zA-Z0-9\s]+$/, {
      message: 'Name cannot contain special characters',
    }),
  userId: z.string().min(1, { message: 'Owner selection is required' }),
})

type AddWorkspaceFormData = z.infer<typeof AddWorkspaceSchema>

const defaultValues: AddWorkspaceFormData = {
  name: '',
  userId: '',
}

const AddWorkspaceDialog = () => {
  const [opened, setOpened] = useState(false)
  const { ref: loadMoreRef, inView } = useInView({
    threshold: 0.1,
  })

  const createWorkspace = useCreateNewWorkSpace()

  const handleClose = useCallback(() => setOpened(false), [])

  const {
    data,
    isLoading,
    fetchNextPage: fetchNextPageUsers,
    hasNextPage,
    isFetchingNextPage,
  } = useUsersQuery({
    isApproved: true,
  })

  const users = data?.pages?.flatMap((page) => page.users) || []

  const form = useForm<AddWorkspaceFormData>({
    mode: 'all',
    defaultValues,
    reValidateMode: 'onChange',
    resolver: zodResolver(AddWorkspaceSchema),
  })

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPageUsers()
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPageUsers])

  const handleSubmit = useCallback(async (data: AddWorkspaceFormData) => {
    try {
      await createWorkspace.mutateAsync(data)
      setOpened(false)
      form.reset()
    } catch (error) {
      console.error('Error creating workspace:', error)
    }
  }, [])

  return (
    <Dialog open={opened} onOpenChange={setOpened}>
      <DialogTrigger asChild>
        <SecondaryButton className="flex-shrink-0" leftIcon={<IconPlus />}>
          Add Workspace
        </SecondaryButton>
      </DialogTrigger>

      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>
            <Typography variant="2xl-nexa-bold">Add Workspace</Typography>
          </DialogTitle>
        </DialogHeader>

        <div className="flex flex-col gap-4">
          <Input
            type="text"
            disabled={form.formState.isSubmitting}
            autoComplete="off"
            label="Workspace Name"
            placeholder="Enter workspace name"
            errorMessage={form.formState.errors.name?.message}
            {...form.register('name')}
          />

          <div className="w-full rounded-lg bg-gray-900">
            <Typography variant="sm-medium" element="label" className="mb-2 block text-gray-50">
              Select Admin
            </Typography>
            <div className="mb-2 rounded-lg border border-gray-700 bg-gray-800">
              <div className="max-h-[400px] overflow-y-auto">
                <RadioGroup
                  value={form.watch('userId')}
                  onValueChange={(value) => form.setValue('userId', value, { shouldValidate: true })}
                >
                  {users.map((user) => (
                    <div
                      key={user.id}
                      className="group flex cursor-pointer items-center border-b border-gray-700 p-3 hover:bg-gray-700"
                    >
                      <div className="flex items-center gap-3">
                        <RadioGroupItem value={user.id} id={user.id} className="border-gray-400" />
                        <div>
                          <Label htmlFor={user.id} className="block font-medium text-gray-50">
                            {user.firstName} {user.lastName}
                          </Label>
                          <span className="text-sm text-gray-400">{user.id}</span>
                        </div>
                      </div>
                    </div>
                  ))}

                  {isLoading && <div className="p-3 text-center text-sm text-gray-400">Loading users...</div>}

                  {/* Load more trigger element */}
                  {!isLoading && (hasNextPage || isFetchingNextPage) && (
                    <div ref={loadMoreRef} className="p-3 text-center text-sm text-gray-400">
                      {isFetchingNextPage ? 'Loading more users...' : 'Loading more...'}
                    </div>
                  )}

                  {!isLoading && !isFetchingNextPage && !hasNextPage && users.length > 0 && (
                    <div className="p-3 text-center text-sm text-gray-400">No more users to load</div>
                  )}
                </RadioGroup>
              </div>
            </div>
            <ErrorMessage message={form.formState.errors.userId?.message} />
          </div>
        </div>

        <DialogFooter className="sm:justify-end sm:space-x-4">
          <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
          <PrimaryButton onClick={form.handleSubmit(handleSubmit)} isLoading={form.formState.isSubmitting}>
            Create
          </PrimaryButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default AddWorkspaceDialog
