import { zodResolver } from '@hookform/resolvers/zod'
import { IconPlus } from '@tabler/icons-react'
import { useCallback, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useCreateMemberMutation } from 'src/enviroment/api/services/membersApi'
import { roles } from 'src/enviroment/constants/constants'
import { z } from 'zod'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../atoms/accordion'
import { PrimaryButton } from '../../atoms/buttons/PrimaryButton'
import { SecondaryButton } from '../../atoms/buttons/SecondaryButton'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '../../atoms/dialog'
import { Input } from '../../atoms/inputs/Input'
import { Label } from '../../atoms/label'
import { RadioGroup, RadioGroupItem } from '../../atoms/radio-group'
import { Checkbox } from '../../atoms/buttons/CheckBoxButton'
import { UserRole } from 'src/types/user'
import { useProjectsNamesQuery } from 'src/enviroment/api/services/projectsApi'
import { ErrorMessage } from '../../atoms/ErrorMessage'
import Typography from '../../atoms/Typography'

const defaultValues: AddMemberFormData = {
  email: '',
  role: UserRole.VIEWER,
  projects: [],
}

const AddMemberSchema = z.object({
  email: z.string().trim().email(),
  role: z.nativeEnum(UserRole),
  projects: z.array(z.string().uuid()).min(1, { message: 'Please select at least one project' }),
})

type AddMemberFormData = z.infer<typeof AddMemberSchema>

const AddMemberDialog = () => {
  const { data: projects, isLoading, isError } = useProjectsNamesQuery()
  const [opened, setOpened] = useState(false)
  const handleClose = useCallback(() => setOpened(false), [])

  const form = useForm<AddMemberFormData>({
    mode: 'all',
    defaultValues,
    reValidateMode: 'onChange',
    resolver: zodResolver(AddMemberSchema),
  })

  const selectedRole = form.watch('role')
  const selectedProjects = form.watch('projects')

  const { mutateAsync: createMemberAsync } = useCreateMemberMutation()

  const handleSubmit: SubmitHandler<AddMemberFormData> = useCallback(
    async ({ email, role, projects }) => {
      try {
        await createMemberAsync({ email, role, projectIds: projects })
        setOpened(false)
      } catch (err) {
        console.error(err)
      }
    },
    [createMemberAsync],
  )

  const getCurrentRoleLabel = useCallback(() => {
    return roles.find((role) => role.value === selectedRole)?.label || 'Select Role'
  }, [selectedRole])

  return (
    <Dialog open={opened} onOpenChange={setOpened}>
      <DialogTrigger asChild>
        <PrimaryButton className="flex-shrink-0" leftIcon={<IconPlus />}>
          Add Member
        </PrimaryButton>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            <Typography variant="2xl-nexa-bold">Add Member</Typography>
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-6">
          <Input
            type="email"
            disabled={form.formState.isSubmitting}
            autoComplete="off"
            errorMessage={form.formState.errors.email?.message}
            label="Member Email"
            placeholder="Member Email"
            {...form.register('email')}
          />

          <Accordion className="flex flex-col gap-6" type="single" collapsible>
            <AccordionItem value="role" className="flex flex-col gap-6">
              <AccordionTrigger
                className="flex min-h-[44px] w-full items-center justify-between rounded-[8px] border border-gray-200 px-4 py-1.5"
                label={getCurrentRoleLabel()}
              ></AccordionTrigger>
              <AccordionContent className="rounded-[8px] border border-gray-300 bg-gray-800 p-4 transition-all">
                <RadioGroup defaultValue={selectedRole} value={selectedRole}>
                  {roles.map(({ label, value }) => (
                    <div
                      key={value}
                      data-selected={selectedRole === value}
                      className="flex h-11 cursor-pointer items-center justify-between rounded-[8px] p-4 data-[selected=true]:bg-gray-900"
                      onClick={() => form.setValue('role', value)}
                    >
                      <Label htmlFor={value} className="text-gray-50">
                        {label}
                      </Label>
                      <RadioGroupItem value={value} id={value} />
                    </div>
                  ))}
                </RadioGroup>
              </AccordionContent>
            </AccordionItem>
            {projects && (
              <AccordionItem value="projects" className="flex flex-col gap-6">
                <AccordionTrigger
                  className="flex min-h-[44px] w-full items-center justify-between rounded-[8px] border border-gray-200 px-4 py-1.5"
                  label="Which projects will be visible?"
                ></AccordionTrigger>
                <AccordionContent className="w-full rounded-[8px] border border-gray-300 bg-gray-800 p-4 transition-all">
                  <div
                    className="h-11 cursor-pointer p-4"
                    onClick={() => {
                      if (selectedProjects.length === projects.length) {
                        form.setValue('projects', [], { shouldValidate: true })
                      } else {
                        form.setValue(
                          'projects',
                          projects.map(({ id }) => id),
                          { shouldValidate: true },
                        )
                      }
                    }}
                  >
                    <div className="pointer-events-none flex items-center justify-between">
                      <Label htmlFor="all-projects" className="w-full text-gray-50">
                        All Projects
                      </Label>
                      <Checkbox id="all-projects" checked={selectedProjects.length === projects.length} />
                    </div>
                  </div>
                  <div className="custom-scrollbar max-h-60 overflow-y-auto">
                    {projects.map(({ id, name }) => {
                      const checked = selectedProjects.includes(id)

                      const onCheckedChange = () => {
                        if (!checked) {
                          form.setValue('projects', [...selectedProjects, id], { shouldValidate: true })
                        } else {
                          form.setValue(
                            'projects',
                            selectedProjects.filter((projectId) => projectId !== id),
                            { shouldValidate: true },
                          )
                        }
                      }

                      return (
                        <div key={id} className="h-11 cursor-pointer p-4" onClick={onCheckedChange}>
                          <div className="pointer-events-none flex items-center justify-between">
                            <Label htmlFor={id} className="w-full text-gray-50">
                              {name}
                            </Label>
                            <Checkbox id={id} checked={checked} />
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </AccordionContent>
              </AccordionItem>
            )}
          </Accordion>
          <ErrorMessage message={form.formState.errors.projects?.message} />
        </div>
        <DialogFooter className="sm:justify-center sm:space-x-6">
          <SecondaryButton isLoading={form.formState.isSubmitting} onClick={handleClose} className="w-40">
            Back
          </SecondaryButton>
          <PrimaryButton
            onClick={form.handleSubmit(handleSubmit)}
            isLoading={form.formState.isSubmitting}
            className="w-40"
          >
            Invite
          </PrimaryButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default AddMemberDialog
