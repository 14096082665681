import { ColumnDef } from '@tanstack/react-table'
import Typography from '../../atoms/Typography'
import IdCell from '../../atoms/IdCell'
import { formatBytes } from 'src/enviroment/constants/constants'
import { getStatusIcon } from './getStatusIcon'
import { IconCopy } from '@tabler/icons-react'
import { LayerItem, LayerStatus, LayerType } from 'src/enviroment/api/services/layersApi'
import { enqueueSnackbar } from 'notistack'
import DeleteDialog from 'src/components/organisms/dialogs/DeleteDialog'
import UploadJsonStylesDialog from 'src/components/organisms/dialogs/UploadJsonStylesDialog'
import UploadJsonComponentsDialog from 'src/components/organisms/dialogs/UploadJsonComponentsDialog'
import EditableNameCell from './EditableNameCell' // Import the new component

export const useColumns = (
  // TO DO
  // handleDownload: (layer: LayerItem) => void,
  // handleRetry: (id: string) => void,
  handleDelete: (id: string) => void,
  handleUpdateName: (id: string, newName: string) => Promise<void>, // New handler for name updates
) => {
  const copyToClipboard = async (text: string, message = 'Copied to clipboard') => {
    await navigator.clipboard.writeText(text)
    enqueueSnackbar(message, { variant: 'success' })
  }

  const columns: ColumnDef<LayerItem>[] = [
    {
      accessorKey: 'id',
      header: 'ID',
      cell: ({ getValue }) => {
        const id = getValue<string>()
        return (
          <div className="flex items-center">
            <IdCell id={id} />
          </div>
        )
      },
    },
    {
      accessorKey: 'fileName',
      header: 'File Name',
      cell: ({ row, getValue }) => (
        <EditableNameCell initialValue={getValue<string>()} rowData={row.original} onUpdate={handleUpdateName} />
      ),
    },
    {
      accessorKey: 'sizeBytes',
      header: 'Size',
      cell: ({ getValue }) => (
        <Typography variant="base" className="text-white">
          {formatBytes(getValue<number>())}
        </Typography>
      ),
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ getValue }) => {
        const status = getValue<LayerStatus>()
        return (
          <div className="flex items-center gap-2">
            {getStatusIcon(status)}
            <Typography variant="base" className="lowercase text-white">
              {status}
            </Typography>
          </div>
        )
      },
    },
    {
      accessorKey: 'blobUrl',
      header: 'URL',
      cell: ({ getValue }) => {
        const url = getValue<string>()
        return (
          <button
            onClick={() => copyToClipboard(url, 'URL copied to clipboard')}
            className="text-gray-400 transition-colors hover:text-blue-500"
            title="Copy Path"
          >
            <IconCopy className="h-5 w-5" />
          </button>
        )
      },
    },
    {
      id: 'progress',
      header: 'Progress',
      cell: ({ row }) => {
        const { chunks, totalChunks } = row.original
        const percentage = Math.round((chunks / totalChunks) * 100)
        return (
          <Typography variant="base" className="text-white">
            {chunks}/{totalChunks} ({percentage}%)
          </Typography>
        )
      },
    },
    {
      accessorKey: 'createdAt',
      header: 'Upload Date',
      cell: ({ getValue }) => (
        <Typography variant="base" className="text-white">
          {new Date(getValue<string>()).toLocaleDateString()}
        </Typography>
      ),
    },
    {
      id: 'actions',
      header: 'Actions',
      cell: ({ row }) => {
        const { status, id, type, widgets, style } = row.original

        return (
          <div className="flex items-center gap-2">
            {status === LayerStatus.COMPLETED && <UploadJsonStylesDialog id={id} json={style} />}
            {type === LayerType.VECTOR && status === LayerStatus.COMPLETED && (
              <UploadJsonComponentsDialog id={id} json={widgets} />
            )}
            <DeleteDialog
              title="Delete File"
              description={`You're about to delete the ${row.original.fileName} file. Are you sure you want to proceed?`}
              onDelete={async () => handleDelete(id)}
            />
          </div>
        )
      },
    },
  ]

  return columns
}
