export const getMimeType = (file: File): string => {
  if (file.type) {
    return file.type
  }

  const extension = file.name.toLowerCase().split('.').pop()

  switch (extension) {
    case 'gpkg':
      return 'application/geopackage+sqlite3'
    default:
      return 'application/octet-stream'
  }
}