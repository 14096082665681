import { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import SuperAdminTemplate from 'src/templates/dashboard/super-admin/SuperAdminTemplate'

const SuperAdminPage: FC = () => {
  return (
    <>
      <Helmet>
        <title>RAMO - Platform Admin</title>
      </Helmet>

      <SuperAdminTemplate />
    </>
  )
}

export default SuperAdminPage
