import axios, { AxiosInstance } from 'axios'

// Types
export interface Message {
  role: 'user' | 'assistant'
  content: string
}

export interface ConversationRequest {
  content: Message[]
}

export interface ConversationResponse {
  response: string
  extras: Record<string, any>
}

// API instance configuration
const api: AxiosInstance = axios.create({
  baseURL: 'https://ramogenai.azurewebsites.net/api',
  headers: {
    'Content-Type': 'application/json',
  },
})

// API methods
export const sendConversation = async (
  messages: Message[],
  country?: string,
  abort?: AbortSignal,
): Promise<ConversationResponse> => {
  try {
    const endpoint = country ? `/chatbot/response?country=${country}` : '/chatbot/response'

    const response = await api.post<ConversationResponse>(
      endpoint,
      {
        content: messages,
      },
      {
        signal: abort,
      },
    )
    return response.data
  } catch (error) {
    // Check if the error was caused by an abort
    if (axios.isCancel(error)) {
      console.log('Request was cancelled:', error.message)
    } else {
      console.error('Error sending conversation:', error)
    }
    throw error
  }
}
