import React from 'react'
import Typography from '../atoms/Typography'
import Logo from '../atoms/icons/Logo'

interface AuthHeaderProps {
  title: string
  subtitle?: string
}

const AuthHeader: React.FC<AuthHeaderProps> = ({ title, subtitle }) => {
  return (
    <div className="space-y-2 px-4 text-left text-light-green-50 sm:space-y-4 sm:px-0">
      <div className="justify-left flex flex-col items-center gap-2 sm:flex-row sm:gap-3">
        <div className="order-2 sm:order-1">
          <Typography variant="4xl-nexa-bold" className="sm:text-2xl-bold whitespace-pre-wrap sm:whitespace-normal">
            {title}
          </Typography>
        </div>
        <div className="order-1 scale-75 transform-gpu sm:order-2 sm:scale-100">
          <Logo />
        </div>
      </div>

      {subtitle && (
        <Typography variant="base" className="mx-auto max-w-md px-4 text-sm sm:px-0 sm:text-base">
          {subtitle}
        </Typography>
      )}
    </div>
  )
}

export default AuthHeader
