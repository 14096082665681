import { IconPencil } from '@tabler/icons-react'
import { FC, useCallback, useState } from 'react'
import { useEditMemberMutation } from 'src/enviroment/api/services/membersApi'
import { roles } from 'src/enviroment/constants/constants'
import { PrimaryButton } from '../../atoms/buttons/PrimaryButton'
import { SecondaryButton } from '../../atoms/buttons/SecondaryButton'
import { Input } from '../../atoms/inputs/Input'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../atoms/accordion'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '../../atoms/dialog'
import { Label } from '../../atoms/label'
import { RadioGroup, RadioGroupItem } from '../../atoms/radio-group'
import { z } from 'zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Member } from 'src/templates/members/columns'
import { UserRole } from 'src/types/user'
import Typography from '../../atoms/Typography'
import { firstName, lastName } from 'src/enviroment/validation/fields'

const EditMemberSchema = z.object({
  firstName: firstName,
  lastName: lastName,
  role: z.nativeEnum(UserRole),
  projects: z.array(z.string().uuid()),
})

type EditMemberFormData = z.infer<typeof EditMemberSchema>

interface Props {
  member: Member
}

const EditMemberDialog: FC<Props> = ({ member }) => {
  const [opened, setOpened] = useState(false)
  const handleClose = useCallback(() => setOpened(false), [])

  const form = useForm<EditMemberFormData>({
    mode: 'all',
    defaultValues: {
      firstName: member.firstName,
      lastName: member.lastName,
      role: member.tenantMemberAccess.role,
      projects: [],
    },
    resolver: zodResolver(EditMemberSchema),
  })

  const selectedRole = form.watch('role')
  const selectedProjects = form.watch('projects')

  const { mutateAsync: editMemberAsync } = useEditMemberMutation()

  const handleSubmit: SubmitHandler<EditMemberFormData> = useCallback(
    async ({ firstName, lastName, role }) => {
      try {
        await editMemberAsync({ id: member.id, firstName, lastName, role })
        setOpened(false)
      } catch (err) {
        console.error(err)
      }
    },
    [editMemberAsync, member.id],
  )

  const getCurrentRoleLabel = useCallback(() => {
    return roles.find((role) => role.value === selectedRole)?.label || 'Select Role'
  }, [selectedRole])

  return (
    <Dialog open={opened} onOpenChange={setOpened}>
      <DialogTrigger
        className="w-15 flex h-10 items-center justify-center rounded-[8px] bg-gray-800 text-light-green-500 transition-all hover:cursor-pointer hover:bg-light-green-500  active:scale-95"
        style={{
          borderImage: 'linear-gradient(to right, #329175, #EFEFCF) 1',
          paddingLeft: 15,
          paddingRight: 15,
          border: '1px solid',
          borderRadius: 1000,
        }}
      >
        <IconPencil size={24} className="text-gray-50" />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            <Typography variant="2xl-nexa-bold">Edit Member</Typography>
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-6">
          <div className="flex justify-between">
            <Input
              disabled={form.formState.isSubmitting}
              autoComplete="off"
              minLength={1}
              maxLength={32}
              label="Member First Name"
              errorMessage={form.formState.errors.firstName?.message}
              placeholder="Member First Name"
              {...form.register('firstName')}
            />
            <Input
              disabled={form.formState.isSubmitting}
              autoComplete="off"
              minLength={1}
              maxLength={32}
              label="Member Last Name"
              errorMessage={form.formState.errors.lastName?.message}
              placeholder="Member Last Name"
              {...form.register('lastName')}
            />
          </div>

          <Accordion className="flex flex-col gap-6" type="single" collapsible>
            <AccordionItem value="role" className="flex flex-col gap-6">
              <AccordionTrigger
                className="flex min-h-[44px] w-full items-center justify-between rounded-[8px] border border-gray-200 px-4 py-1.5"
                label={getCurrentRoleLabel()}
              ></AccordionTrigger>
              <AccordionContent className="rounded-[8px] border border-gray-300 bg-gray-800 p-4 transition-all">
                <RadioGroup defaultValue={selectedRole} value={selectedRole}>
                  {roles.map(({ label, value }) => (
                    <div
                      key={value}
                      data-selected={selectedRole === value}
                      className="flex h-11 cursor-pointer items-center justify-between rounded-[8px] p-4 data-[selected=true]:bg-gray-900"
                      onClick={() => form.setValue('role', value)}
                    >
                      <Label htmlFor={value} className="text-gray-50">
                        {label}
                      </Label>
                      <RadioGroupItem value={value} id={value} />
                    </div>
                  ))}
                </RadioGroup>
              </AccordionContent>
            </AccordionItem>
            {/* <AccordionItem value="projects" className="flex flex-col gap-6">
              <AccordionTrigger
                className="flex min-h-[44px] w-full items-center justify-between rounded-[8px] border border-gray-200 px-4 py-1.5"
                label="Which projects will be visible?"
              ></AccordionTrigger>
              <AccordionContent className="w-full rounded-[8px] border border-gray-300 bg-gray-800 p-4 transition-all">
                <div
                  className="h-11 cursor-pointer p-4"
                  onClick={() => {
                    if (selectedProjects.length === projects.length) {
                      form.setValue('projects', [])
                    } else {
                      form.setValue(
                        'projects',
                        projects.map(({ id }) => id),
                      )
                    }
                  }}
                >
                  <div className="pointer-events-none flex items-center justify-between">
                    <Label htmlFor="all-projects" className="w-full text-gray-50">
                      All Projects
                    </Label>
                    <Checkbox id="all-projects" checked={selectedProjects.length === projects.length} />
                  </div>
                </div>
                {projects.map(({ id, name }) => {
                  const checked = selectedProjects.includes(id)

                  const onCheckedChange = () => {
                    if (!checked) {
                      form.setValue('projects', [...selectedProjects, id])
                    } else {
                      form.setValue(
                        'projects',
                        selectedProjects.filter((projectId) => projectId !== id),
                      )
                    }
                  }

                  return (
                    <div key={id} className="h-11 cursor-pointer p-4" onClick={onCheckedChange}>
                      <div className="pointer-events-none flex items-center justify-between">
                        <Label htmlFor={id} className="w-full text-gray-50">
                          {name}
                        </Label>
                        <Checkbox id={id} checked={checked} />
                      </div>
                    </div>
                  )
                })}
              </AccordionContent>
            </AccordionItem> */}
          </Accordion>
        </div>
        <DialogFooter className="sm:justify-center sm:space-x-6">
          <SecondaryButton isLoading={form.formState.isSubmitting} onClick={handleClose} className="w-40">
            Cancel
          </SecondaryButton>
          <PrimaryButton
            isLoading={form.formState.isSubmitting}
            onClick={form.handleSubmit(handleSubmit)}
            className="w-40"
          >
            Save
          </PrimaryButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default EditMemberDialog
