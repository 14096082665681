import React, { ReactNode } from 'react'
import ImageForest from 'src/assets/img/auth-background.png'
interface AuthWrapperProps {
  children: ReactNode
}

const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
  return (
    <div className="flex h-screen w-full overflow-hidden">
      <div className="flex w-full items-center justify-center overflow-y-auto bg-gray-900 p-8 lg:w-1/2">
        <div className="w-full max-w-md space-y-6 text-white">{children}</div>
      </div>
      <div className="relative hidden lg:block lg:w-1/2">
        <div className="absolute inset-0 p-10">
          <img src={ImageForest} alt="Authentication background" className="h-full w-full rounded-2xl object-cover" />
        </div>
      </div>
    </div>
  )
}

export default AuthWrapper
