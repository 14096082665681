import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import {
  IconMapPin2,
  IconUsers,
  IconTower,
  IconLayoutSidebarRight,
  IconWorld,
  IconHome,
  IconLeaf,
  IconBrandYoutube,
} from '@tabler/icons-react'
import LogoBig from '../atoms/icons/LogoBig'

import SidebarSection from './SidebarSection'
import useAuth from 'src/enviroment/hooks/useAuth'
import { ROLE_PERMISSIONS, ROUTES } from 'src/types/routes'
import SupportDialog from '../organisms/SupportDialog'
import SectionHeader from '../molecules/SectionHeader'
import { useTenantStore } from 'src/enviroment/store/tenentStore'
import CompanyLogo from '../atoms/CompanyLogo'
import ProfileCard from '../molecules/ProfileCard'
import SupportContainer from './SupportContainer'
import { isActiveLink } from 'src/enviroment/lib/utils'

interface SidebarProps {
  isCollapsed: boolean
  onCollapse: (collapsed: boolean) => void
}

// Define a type for sidebar items to fix the TypeScript errors
interface SidebarItem {
  to: string
  icon: React.ReactNode
  label: string
  isActive: boolean
  route: string
}

const Sidebar: React.FC<SidebarProps> = ({ isCollapsed, onCollapse }) => {
  const navigate = useNavigate()
  const { userRole, user } = useAuth()
  const [isSupportOpen, setIsSupportOpen] = useState<boolean>(false)
  const currentTenant = useTenantStore((state) => state.currentTenant)

  const toggleSupport = () => setIsSupportOpen(!isSupportOpen)

  const workspaceItems: SidebarItem[] = [
    // {
    //   to: ROUTES.HOME_ALT,
    //   icon: <IconHome className="h-5 w-5" />,
    //   label: 'Home',
    //   isActive: isActiveLink(ROUTES.HOME_ALT),
    //   route: ROUTES.HOME_ALT,
    // },
    {
      to: ROUTES.ATLAS,
      icon: <IconWorld className="h-5 w-5" />,
      label: 'Atlas',
      isActive: isActiveLink(ROUTES.ATLAS),
      route: ROUTES.ATLAS,
    },
    {
      to: ROUTES.PROJECTS,
      icon: <IconMapPin2 className="h-5 w-5" />,
      label: 'My Projects',
      isActive: isActiveLink([ROUTES.PROJECTS, ROUTES.PROJECT_DETAILS]),
      route: ROUTES.PROJECTS,
    },
    // {
    //   to: ROUTES.LEARN,
    //   icon: <IconBrandYoutube className="h-5 w-5" />,
    //   label: 'Learning',
    //   isActive: isActiveLink(ROUTES.LEARN),
    //   route: ROUTES.LEARN,
    // },
    // Former account items now in workspace
    {
      to: ROUTES.TEAM,
      icon: <IconUsers className="h-5 w-5" />,
      label: 'Team',
      isActive: isActiveLink(ROUTES.TEAM),
      route: ROUTES.TEAM,
    },
    // {
    //   to: ROUTES.SUBS,
    //   icon: <IconLeaf className="h-5 w-5" />,
    //   label: 'Subscription',
    //   isActive: isActiveLink(ROUTES.SUBS),
    //   route: ROUTES.SUBS,
    // },
  ]

  // Add Platform Admin item conditionally if user is superadmin
  if (user?.isSuperadmin) {
    workspaceItems.splice(6, 0, {
      to: ROUTES.SUPER_ADMIN,
      icon: <IconTower className="h-5 w-5" />,
      label: 'Platform Admin',
      isActive: isActiveLink(ROUTES.SUPER_ADMIN),
      route: ROUTES.SUPER_ADMIN,
    })
  }

  // Filter items based on user permissions
  const filteredWorkspaceItems = workspaceItems.filter((item) => {
    // Special case for superadmin route
    if (item.route === ROUTES.SUPER_ADMIN) {
      return user?.isSuperadmin
    }

    // For other routes, check if the user role has permission
    return ROLE_PERMISSIONS[userRole]?.includes(item.route as any) ?? false
  })

  return (
    <aside
      className={`fixed left-0 top-0 z-30 h-full border-r border-gray-600 bg-gray-800 text-green-50 transition-all duration-300 ${
        isCollapsed ? 'w-[88px]' : 'w-[265px]'
      }`}
    >
      <div className="flex h-full flex-col py-4 pt-10">
        <div
          aria-label="logo"
          className={`flex items-center px-4 ${isCollapsed ? 'mb-5 justify-center' : 'justify-between'}`}
        >
          {!isCollapsed && (
            <>
              {currentTenant.logoUrl ? (
                <CompanyLogo logoUrl={currentTenant.logoUrl} altText={currentTenant.name} />
              ) : (
                <div className="mb-4 flex justify-center">
                  <LogoBig />
                </div>
              )}
            </>
          )}
          <button
            onClick={() => onCollapse(!isCollapsed)}
            className="text-green-50 hover:text-white focus:outline-none"
            aria-label={isCollapsed ? 'Expand sidebar' : 'Collapse sidebar'}
          >
            <IconLayoutSidebarRight className="h-5 w-5" />
          </button>
        </div>
        <div className={isCollapsed ? 'px-3' : 'px-0'}>
          <SectionHeader isCollapsed={isCollapsed} />
        </div>

        {filteredWorkspaceItems.length > 0 && (
          <SidebarSection isCollapsed={isCollapsed} items={filteredWorkspaceItems} title="MY WORKSPACE" />
        )}

        <div className="mt-auto p-2">
          <SupportContainer onRequestSupport={toggleSupport} isCollapsed={isCollapsed} />
          <div className="my-3 h-[1px] w-full bg-gray-600" />
        </div>
        <ProfileCard isCollapsed={isCollapsed} />
      </div>
      <SupportDialog isOpen={isSupportOpen} onClose={toggleSupport} />
    </aside>
  )
}

export default Sidebar
