import React from 'react'
import { IconTrash } from '@tabler/icons-react'
import { cn } from 'src/enviroment/lib/utils'

interface DeleteButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
}

const DeleteButton = React.forwardRef<HTMLButtonElement, DeleteButtonProps>(({ className, ...props }, ref) => {
  return (
    <button
      ref={ref}
      className={cn(
        'flex items-center justify-center',
        'h-9 w-9',
        'text-red-ramo',
        'rounded-full bg-transparent',
        'shadow-sm backdrop-blur-sm',
        'transition-all duration-200',
        'border',
        'border-red-ramo',
        'hover:bg-red-ramo hover:text-light-green-50',
        className,
      )}
      {...props}
    >
      <IconTrash className="h-6 w-6" stroke={1.5} />
    </button>
  )
})

DeleteButton.displayName = 'DeleteButton'

export default DeleteButton
