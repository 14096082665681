import React, { FC, useState } from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { Controller, useForm } from 'react-hook-form'
import Typography from '../atoms/Typography'
import { PrimaryButton } from '../atoms/buttons/PrimaryButton'
import { Input } from '../atoms/inputs/Input'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { enqueueSnackbar } from 'notistack'
import { useCreateSetupIntent, useSavePaymentMethod } from 'src/enviroment/api/services/paymentMethodsApi'

interface Props {
  onSuccess?: () => void
  onError?: (error: string) => void
}

const addressSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  line1: z.string().min(1, 'Address is required'),
  city: z.string().min(1, 'City is required'),
  state: z.string().min(1, 'State is required'),
  postal_code: z
    .string()
    .min(1, 'Postal code is required')
    .regex(/^\d{5}(-\d{4})?$/, 'Invalid postal code format'),
  country: z.string().min(2, 'Country is required').max(2, 'Use 2-letter country code (e.g. US)').toUpperCase(),
})

export type AddressFormData = z.infer<typeof addressSchema>

const PaymentForm: FC<Props> = ({ onSuccess, onError }) => {
  const stripe = useStripe()
  const elements = useElements()
  const [error, setError] = useState<string | null | undefined>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { mutateAsync: createSetupIntent } = useCreateSetupIntent()
  const { mutateAsync: savePaymentMethod } = useSavePaymentMethod()

  const { control, handleSubmit, reset } = useForm<AddressFormData>({
    resolver: zodResolver(addressSchema),
    defaultValues: {
      name: '',
      line1: '',
      city: '',
      state: '',
      postal_code: '',
      country: '',
    },
  })

  const onSubmit = async (addressData: AddressFormData) => {
    setIsLoading(true)
    if (!stripe || !elements) return

    const card = elements.getElement(CardElement)
    if (!card) return

    setError(null)

    try {
      const { clientSecret } = await createSetupIntent()

      const result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: card,
          billing_details: {
            name: addressData.name,
            address: {
              city: addressData.city,
              country: addressData.country,
              line1: addressData.line1,
              postal_code: addressData.postal_code,
              state: addressData.state,
            },
          },
        },
      })

      if (result.error) {
        const errorMessage = result.error.message
        setError(errorMessage)
        onError?.(errorMessage || 'Failed to process card')
        return
      }

      await savePaymentMethod(result.setupIntent.payment_method)

      enqueueSnackbar('Payment method added successfully', { variant: 'success' })
      reset()
      onSuccess?.()
    } catch (err) {
      const errorMessage = 'An unexpected error occurred'
      setError(errorMessage)
      onError?.(errorMessage)
      enqueueSnackbar('Failed to add payment method', { variant: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
      <div className="space-y-4">
        <Typography variant="xl" className="mb-4 block font-medium text-light-green-50">
          Card
        </Typography>
        <div className="rounded-md bg-gray-800 p-4">
          <CardElement
            options={{
              hidePostalCode: true,
              iconStyle: 'solid',
              style: {
                base: {
                  fontSize: '17px',
                  color: '#F5F6F6',
                  '::placeholder': {
                    color: '#F7F7F7',
                  },
                },
                invalid: {
                  color: '#9e2146',
                },
              },
            }}
          />
        </div>

        {error && (
          <Typography variant="sm" className="text-red-500">
            {error}
          </Typography>
        )}

        <Typography variant="xl" className="mb-2 block font-medium text-light-green-50">
          Billing Address
        </Typography>
        <div className="space-y-4">
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, ...field }, formState }) => (
              <Input
                {...field}
                onChange={onChange}
                label="Full Name"
                placeholder="John Doe"
                errorMessage={formState.errors.name?.message}
              />
            )}
          />

          <Controller
            name="line1"
            control={control}
            render={({ field: { onChange, ...field }, formState }) => (
              <Input
                {...field}
                onChange={onChange}
                label="Address Line 1"
                placeholder="Street address"
                errorMessage={formState.errors.line1?.message}
              />
            )}
          />

          <div className="grid grid-cols-2 gap-4">
            <Controller
              name="city"
              control={control}
              render={({ field: { onChange, ...field }, formState }) => (
                <Input
                  {...field}
                  onChange={onChange}
                  label="City"
                  placeholder="City"
                  errorMessage={formState.errors.city?.message}
                />
              )}
            />

            <Controller
              name="state"
              control={control}
              render={({ field: { onChange, ...field }, formState }) => (
                <Input
                  {...field}
                  onChange={onChange}
                  label="State"
                  placeholder="State"
                  errorMessage={formState.errors.state?.message}
                />
              )}
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <Controller
              name="postal_code"
              control={control}
              render={({ field: { onChange, ...field }, formState }) => (
                <Input
                  {...field}
                  onChange={onChange}
                  label="Postal Code"
                  placeholder="Postal code"
                  errorMessage={formState.errors.postal_code?.message}
                />
              )}
            />

            <Controller
              name="country"
              control={control}
              render={({ field: { onChange, ...field }, formState }) => (
                <Input
                  {...field}
                  onChange={onChange}
                  label="Country"
                  placeholder="Country"
                  errorMessage={formState.errors.country?.message}
                />
              )}
            />
          </div>
        </div>
      </div>

      <div className="mt-4">
        <PrimaryButton type="submit" disabled={!stripe || isLoading} className="w-full">
          {isLoading ? 'Processing...' : 'Add Card'}
        </PrimaryButton>
      </div>
    </form>
  )
}

export default PaymentForm
