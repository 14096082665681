import React, { useMemo } from 'react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { createRouter } from './enviroment/routes/router'
import { SnackbarProvider } from 'notistack'
import { createQueryClient } from './enviroment/api/queryClient'
import { HelmetProvider } from 'react-helmet-async'
import { RouterProvider } from 'react-router'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

const stripePromise = loadStripe(import.meta.env.VITE_APP_STRIPE_PK)
export default function App() {
  const queryClient = useMemo(() => createQueryClient(), [])

  return (
    <Elements stripe={stripePromise}>
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={createRouter()} />
          <ReactQueryDevtools />
          <SnackbarProvider
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          />
        </QueryClientProvider>
      </HelmetProvider>
    </Elements>
  )
}
