import React from 'react'
import { MoveUpRight } from 'lucide-react'

interface ActionButtonProps {
  onClick?: () => void
  className?: string
}

export const ActionButton: React.FC<ActionButtonProps> = ({ onClick, className = '' }) => {
  return (
    <button
      className={`
        relative flex 
        h-8 w-8 items-center 
        justify-center
        rounded-full
        focus:outline-none
        ${className}
      `}
      onClick={onClick}
      style={{
        background: 'linear-gradient(to right, #329175, #EFEFCF)',
        padding: '1px',
      }}
    >
      <div className="flex h-full w-full items-center justify-center rounded-full bg-gray-800">
        <MoveUpRight size={16} strokeWidth={2} className="text-gray-50" />
      </div>
    </button>
  )
}
