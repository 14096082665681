import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { enqueueSnackbar } from 'notistack'
import { client } from '../axios'
import { handleApiError } from '../errorHandler'
import { getMimeType } from 'src/components/molecules/chunks/getMimeType'

export enum LayerStatus {
  UPLOADING = 'UPLOADING',
  COMPLETED = 'COMPLETED',
  PROCESSING = 'PROCESSING',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
}

export enum LayerType {
  VECTOR = 'VECTOR',
  RASTER = 'RASTER',
}

export interface LayerAttributes {
  displayName?: string
  format?: {
    mantissa?: number
    thousandSeparated?: boolean
  }
}

export interface LayerStyle {
  type: string
  label: {
    color: string
    justify: string
    maxZoom: number
    minZoom: number
    padding: number
    fontSize: number
    fontStyle: string
    haloColor: string
    haloWidth: number
    placement: string
    fontWeight: number
    lineHeight: number
    maxLineChars: number
    letterSpacing: number
    textTransform: string
  }
  paint: {
    color: string[]
    opacity: number
    strokeColor: string
    strokeWidth: number
    isSandwiched: boolean
  }
  popup: {
    keyAttributes: string[]
  }
  config: {
    showOther: boolean
    categories: {
      type: string
      count: number
    }
    labelAttribute: string[]
    categoricalAttribute: string
  }
  legend: {
    displayName: string
  }
  version: string
  attributes: Record<string, LayerAttributes>
}

export interface LayerItem {
  id: string
  fileName: string
  containerName: string
  blobPath: string
  blobUrl: string
  mimeType: string
  status: LayerStatus
  sizeBytes: number
  chunks: number
  totalChunks: number
  style: LayerStyle | null
  widgets: any
  srid: number | null
  xMin: number | null
  yMin: number | null
  xMax: number | null
  yMax: number | null
  errorMessage: string | null
  type: LayerType
  createdAt: string
  updatedAt: string
  vectorAttributes: any[] // This could be refined if you have details about vectorAttributes structure
}

interface InitUploadResponse {
  fileId: string
}

export enum LayersQueryKeys {
  Layers = 'Layers',
}

// Get layers list with pagination
export function useLayersQuery() {
  return useInfiniteQuery({
    queryKey: [LayersQueryKeys.Layers],
    queryFn: async () => {
      const response = await client.get<LayerItem[]>('/layers')
      return response.data
    },
    refetchInterval: 10000,
  })
}

// Initialize file upload
export function useInitUpload() {
  return useMutation({
    mutationFn: async (data: { filename: string; mimeType: string; sizeBytes: number; totalChunks: number }) => {
      const response = await client.post<InitUploadResponse>('/layers/init', data)
      return response.data
    },
    onError: handleApiError,
  })
}

// Upload file chunk
export function useUploadChunk() {
  return useMutation({
    mutationFn: async ({
      fileId,
      chunk,
      chunkIndex,
      signal,
    }: {
      fileId: string
      chunk: Blob
      chunkIndex: number
      signal?: AbortSignal
    }) => {
      const formData = new FormData()
      formData.append('chunk', chunk)
      formData.append('chunkIndex', chunkIndex.toString())

      await client.post(`/layers/chunk/${fileId}`, formData, { signal })
    },
    onError: handleApiError,
  })
}

// Delete layer
export function useDeleteLayer() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (fileId: string) => {
      await client.delete(`/layers/${fileId}`)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [LayersQueryKeys.Layers] })
      enqueueSnackbar('File deleted successfully', { variant: 'success' })
    },
    onError: (error) => {
      handleApiError(error)
    },
  })
}

// Custom hook for handling file upload with chunks
export function useChunkedUpload(chunkSize: number = 5 * 1024 * 1024) {
  const initUploadMutation = useInitUpload()
  const uploadChunkMutation = useUploadChunk()
  const queryClient = useQueryClient()

  const upload = async (
    file: File,
    {
      onProgress,
      signal,
    }: {
      onProgress?: (progress: { chunks: number; totalChunks: number; percentage: number }) => void
      signal?: AbortSignal
    } = {},
  ) => {
    try {
      const totalChunks = Math.ceil(file.size / chunkSize)

      // Initialize upload
      const { fileId } = await initUploadMutation.mutateAsync({
        filename: file.name,
        mimeType: getMimeType(file),
        sizeBytes: file.size,
        totalChunks,
      })

      // Upload chunks
      for (let i = 0; i < totalChunks; i++) {
        const start = i * chunkSize
        const end = Math.min(start + chunkSize, file.size)
        const chunk = file.slice(start, end)

        await uploadChunkMutation.mutateAsync({
          fileId,
          chunk,
          chunkIndex: i,
          signal,
        })

        onProgress?.({
          chunks: i + 1,
          totalChunks,
          percentage: Math.round(((i + 1) / totalChunks) * 100),
        })
      }

      // Refresh layers list
      await queryClient.invalidateQueries({ queryKey: [LayersQueryKeys.Layers] })
      enqueueSnackbar('File uploaded successfully', { variant: 'success' })

      return fileId
    } catch (error) {
      handleApiError(error)
      throw error
    }
  }

  return {
    upload,
    isLoading: initUploadMutation.isLoading || uploadChunkMutation.isLoading,
  }
}

export function useUploadStyle() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ fileId, style }: { fileId: string; style: object }) => {
      const response = await client.put(`/layers/${fileId}/style`, { style })
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [LayersQueryKeys.Layers] })
      enqueueSnackbar('Style uploaded successfully', { variant: 'success' })
    },
    onError: (error) => {
      handleApiError(error)
    },
  })
}

export function useUploadComponents() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ fileId, widgets }: { fileId: string; widgets: object }) => {
      const response = await client.put(`/layers/${fileId}/widgets`, { widgets })
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [LayersQueryKeys.Layers] })
      enqueueSnackbar('Style uploaded successfully', { variant: 'success' })
    },
    onError: (error) => {
      handleApiError(error)
    },
  })
}

export function useUpdateLayerName() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ fileId, fileName }: { fileId: string; fileName: string }) => {
      const response = await client.patch(`/layers/${fileId}`, { fileName })
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [LayersQueryKeys.Layers] })
      enqueueSnackbar('File name updated successfully', { variant: 'success' })
    },
    onError: (error) => {
      handleApiError(error)
    },
  })
}

// Direct API function if you prefer not to use React Query
export const updateLayerName = async (id: string, newName: string): Promise<LayerItem> => {
  try {
    const response = await client.patch(`/layers/${id}`, { fileName: newName })
    return response.data
  } catch (error) {
    console.error('Error updating layer name:', error)
    throw error
  }
}
