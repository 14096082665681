import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { PrimaryButton } from '../../atoms/buttons/PrimaryButton'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../../atoms/dialog'
import { Input } from '../../atoms/inputs/Input'
import Typography from '../../atoms/Typography'
import { zodResolver } from '@hookform/resolvers/zod'
import { ProjectTags, useCreateProjectFromShape } from 'src/enviroment/api/services/projectsApi'
import { useNavigate } from 'react-router'
import { ROUTES } from 'src/types/routes'
import { useQueryClient } from '@tanstack/react-query'
import { IconLeaf } from '@tabler/icons-react'
import SingleFileDropzone from '../SingleFileDropzone'
import { ErrorMessage } from 'src/components/atoms/ErrorMessage'

// Form schema for validation
const projectFormSchema = z.object({
  name: z
    .string()
    .trim()
    .min(1, 'Project name is required')
    .transform((val) => val.trim()),
  shape: z.instanceof(File).nullable(),
})

export type ProjectFormData = z.infer<typeof projectFormSchema>

type ProjectRequestData = {
  name: string
  shape: File
}

interface Props {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
}

const CreateProjectViaFileUpload = ({ isOpen, onOpenChange }: Props) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [isLoading, setIsLoading] = useState(false)
  const createProject = useCreateProjectFromShape()
  const { control, handleSubmit, reset, setValue } = useForm<ProjectFormData>({
    mode: 'onChange',
    resolver: zodResolver(projectFormSchema),
    defaultValues: {
      name: '',
      shape: null,
    },
  })

  const handleFileChange = (file: File | null) => {
    setValue('shape', file)
  }

  const handleDialogChange = (open: boolean) => {
    onOpenChange(open)
    if (!open) {
      reset()
    }
  }

  const onSubmit = async (data: ProjectFormData) => {
    if (!data.shape) {
      console.error('File is required')
      return
    }

    const requestData: ProjectRequestData = {
      name: data.name,
      shape: data.shape,
    }

    try {
      setIsLoading(true)
      await createProject.mutateAsync(requestData)
      onOpenChange(false)
      reset()
      queryClient.invalidateQueries([ProjectTags.List])
      navigate(ROUTES.PROJECTS)
    } catch (error) {
      console.error('Failed to create project:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={handleDialogChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            <Typography variant="2xl-nexa-bold">Create New Project</Typography>
            <Typography variant="base" className="mt-2">
              Name your project and upload a spatial file for your project boundary.
            </Typography>
          </DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
          <div className="mb-2 space-y-4">
            <div>
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, ...field }, formState }) => (
                  <Input
                    {...field}
                    onChange={onChange}
                    label="Project Name"
                    autoFocus
                    placeholder="New project"
                    type="text"
                    errorMessage={formState.errors.name?.message}
                  />
                )}
              />
            </div>

            <Controller
              name="shape"
              control={control}
              render={({ field: { value }, formState }) => (
                <>
                  <SingleFileDropzone file={value} onFileChange={handleFileChange} />
                  <ErrorMessage message={formState.errors.shape?.message} />
                </>
              )}
            />
          </div>
          <DialogFooter className="mt-4 sm:justify-end sm:space-x-6">
            {isLoading && (
              <div className="mb-4 w-full text-center">
                <div className="animate-pulse text-gray-600">
                  <Typography variant="sm">
                    Please wait while we create your project. This process may take a few moments...
                  </Typography>
                </div>
              </div>
            )}
            <PrimaryButton type="submit" disabled={isLoading} isLoading={isLoading} rightIcon={<IconLeaf size={20} />}>
              Create Project
            </PrimaryButton>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default CreateProjectViaFileUpload
