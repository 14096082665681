import { FC, useState, useEffect } from 'react'
import Typography from 'src/components/atoms/Typography'
import { Input } from 'src/components/atoms/inputs/Input'
import { IconSearch } from '@tabler/icons-react'
import AddProjectDialog from 'src/components/organisms/dialogs/AddProjectDialog'
import { ProjectTable } from 'src/templates/dashboard/super-admin/tables/project-table'
import { useQueryClient } from '@tanstack/react-query'
import { useInView } from 'react-intersection-observer'
import {
  SuperAdminQueryKeys,
  useGlobalProjectQuery,
  useRefreshThumbnailMutation,
} from 'src/enviroment/api/services/superAdminApi'
import { useDebounceValue } from 'src/enviroment/hooks/useDebounceValue'
import { JumpingDots } from 'src/components/atoms/JumpingDots'
import TagManagementDialog from 'src/components/organisms/dialogs/TagManagmentDialog'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ProjectsTabProps {}

export const ProjectsTab: FC<ProjectsTabProps> = () => {
  const queryClient = useQueryClient()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearch = useDebounceValue(searchTerm, 800)
  const handleRefreshThumbnail = useRefreshThumbnailMutation()

  // Setup intersection observer
  const { ref, inView } = useInView({
    threshold: 0.1,
  })

  useEffect(() => {
    queryClient.invalidateQueries([SuperAdminQueryKeys.GlobalProjectList, debouncedSearch])
  }, [debouncedSearch, queryClient])

  const {
    data: projectsData,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGlobalProjectQuery({
    query: debouncedSearch,
  })

  // Fetch next page when bottom element is in view
  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage])

  // Flatten projects from all pages
  const projects = projectsData?.pages.flatMap((page) => page.projects) || []

  return (
    <div className={`my-6 flex flex-col gap-8 rounded-[8px] bg-gray-800 p-8`}>
      <div className="flex items-center justify-between gap-8">
        <Typography variant="xl" className="shrink-0 text-light-green-50">
          Platform Projects
        </Typography>
        <div className="ml-auto flex items-center gap-6">
          <TagManagementDialog />
          <Input
            containerClassName="w-[300px]"
            icon={<IconSearch />}
            placeholder="Search by name, location"
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
          />
          <AddProjectDialog />
        </div>
      </div>

      <ProjectTable
        projects={projects}
        isLoading={isLoading}
        onRefreshThumbnail={(projectId) => handleRefreshThumbnail.mutateAsync(projectId)}
      />

      <div ref={ref} className="py-2 text-center">
        {isFetchingNextPage && <JumpingDots className="text-light-green-50" />}
      </div>
    </div>
  )
}
