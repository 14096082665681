import React, { useState } from 'react'
import { ChevronUp, ChevronDown, Check } from 'lucide-react'
import { cn } from 'src/enviroment/lib/utils'

interface FilterOption {
  id: string
  name: string
  checked: boolean
}

interface FilterDropdownProps {
  type: string
  className?: string
  options: { id: string; name: string }[]
  onChange: (selectedIds: string[]) => void
  theme?: 'dark' | 'light'
}

const FilterDropdown = ({
  options: initialOptions,
  type,
  onChange,
  className,
  theme = 'dark',
}: FilterDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [options, setOptions] = useState<FilterOption[]>(
    initialOptions.map((opt) => ({
      id: opt.id,
      name: opt.name,
      checked: false, // Changed from true to false - no items selected by default
    })),
  )

  const handleOptionChange = (optionId: string) => {
    const newOptions = options.map((option) =>
      option.id === optionId ? { ...option, checked: !option.checked } : option,
    )
    setOptions(newOptions)
    onChange(newOptions.filter((opt) => opt.checked).map((opt) => opt.id))
  }

  const getSelectedLabels = () => {
    const selected = options.filter((opt) => opt.checked).map((opt) => opt.name)
    if (selected.length === 0) return ''
    // Removed "All" condition
    return selected.slice(0, 2).join(', ') + (selected.length > 2 ? '...' : '')
  }

  const isDisabled = initialOptions.length === 0

  // Theme-based styles
  const styles = {
    button: cn(
      'flex w-full items-center justify-between rounded-lg border px-4 py-3',
      theme === 'dark' ? 'text-gray-50 border-gray-700 bg-gray-800' : 'text-gray-600 border-gray-300 bg-white',
      isDisabled && theme === 'dark'
        ? 'cursor-not-allowed border-gray-700 opacity-50'
        : isDisabled && theme === 'light'
        ? 'cursor-not-allowed border-gray-200 opacity-50'
        : 'cursor-pointer',
    ),
    selectedText: cn(theme === 'dark' ? 'text-gray-50' : 'text-gray-900'),
    dropdown: cn(
      'absolute z-50 mt-2 w-full rounded-lg p-2',
      theme === 'dark' ? 'bg-gray-800' : 'bg-white border border-gray-200',
    ),
    option: cn(
      'flex cursor-pointer items-center justify-between rounded p-2',
      theme === 'dark' ? 'hover:bg-gray-700' : 'hover:bg-gray-100',
    ),
    optionText: cn('text-sm font-medium', theme === 'dark' ? 'text-gray-300' : 'text-gray-600'),
    checkmark: cn(
      'flex h-6 w-6 items-center justify-center rounded',
      theme === 'dark' ? 'bg-emerald-600' : 'bg-emerald-500',
    ),
  }

  return (
    <div className={cn('relative', className)}>
      <button onClick={() => setIsOpen(!isOpen)} disabled={isDisabled} className={styles.button}>
        <span className="truncate text-sm font-medium">
          {isDisabled ? (
            'No options'
          ) : getSelectedLabels() === '' ? (
            <span className={styles.selectedText}>{type}</span>
          ) : (
            <>
              {type}: <span className={styles.selectedText}>{getSelectedLabels()}</span>
            </>
          )}
        </span>
        {isOpen ? (
          <ChevronUp size={16} className="text-light-green-500" />
        ) : (
          <ChevronDown size={16} className="text-light-green-500" />
        )}
      </button>

      {isOpen && !isDisabled && (
        <div className={styles.dropdown}>
          {options.map((option) => (
            <div key={option.id} onClick={() => handleOptionChange(option.id)} className={styles.option}>
              <span className={styles.optionText}>{option.name}</span>
              {option.checked && (
                <div className={styles.checkmark}>
                  <Check size={16} className="text-white" />
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default FilterDropdown
