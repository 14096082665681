import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { ROUTES } from 'src/types/routes'
import Typography from '../atoms/Typography'
import { IconChevronRight } from '@tabler/icons-react'
import { isActiveLink } from 'src/enviroment/lib/utils'
import Avatar from './Avatar'
import { useUserInformation } from 'src/enviroment/api/services/userApi'

interface ProfileCardProps {
  name?: string
  email?: string
  className?: string
  maxNameLength?: number
  maxEmailLength?: number
  isCollapsed?: boolean
}

const ProfileCard: React.FC<ProfileCardProps> = ({
  name,
  email,
  className = '',
  maxNameLength = 20,
  maxEmailLength = 25,
  isCollapsed = false,
}) => {
  const navigate = useNavigate()
  const { data: user } = useUserInformation()
  const isActiveProfile = isActiveLink(ROUTES.PROFILE)

  const displayName = name || user?.firstName + ' ' + user?.lastName
  const displayEmail = email || user?.email || ''

  // Truncate name and email if they exceed maximum length
  const truncateName = (name: string): string => {
    return name.length > maxNameLength ? `${name.substring(0, maxNameLength)}...` : name
  }

  const truncateEmail = (email: string): string => {
    return email.length > maxEmailLength ? `${email.substring(0, maxEmailLength)}...` : email
  }

  const handleRedirect = () => {
    navigate(ROUTES.PROFILE)
  }

  // Add active route styling with background color and left border
  const activeRouteStyles = isActiveProfile ? 'bg-gray-700 border-l-2 border-orange-500' : ''


  return (
    <div
      className={`flex cursor-pointer items-center p-2 py-2 ${activeRouteStyles} ${className}`}
      onClick={handleRedirect}
    >
      <div className={`flex ${isCollapsed ? 'w-full justify-center' : 'w-full'} items-center`}>
        <Avatar
          size="sm"
          avatarUrl={user?.avatarUrl || null}
          className={`${isCollapsed ? 'mx-auto' : 'mr-2'} h-8 w-8 rounded-full`}
        />
        {/* {renderAvatar()} */}

        {!isCollapsed && (
          <>
            <div className="justify-left flex flex-col overflow-hidden">
              <Typography variant="sm" className="overflow-hidden text-ellipsis whitespace-nowrap">
                {truncateName(displayName)}
              </Typography>
              <Typography variant="sm" className="overflow-hidden text-ellipsis whitespace-nowrap">
                {truncateEmail(displayEmail)}
              </Typography>
            </div>

            <div className="ml-auto">
              <IconChevronRight stroke={2} />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ProfileCard
