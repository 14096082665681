import Typography from 'src/components/atoms/Typography'
import { formatArea } from 'src/enviroment/lib/utils'
import { DrawnAreasState } from 'src/types/mapbox'
import CreateProjectDrawDialog from '../../dialogs/CreateProjectDrawDialog'

export const AreasInfo: React.FC<{
  drawnAreas: DrawnAreasState
  file: File | null
}> = ({ drawnAreas, file }) => (
  <div className="  p-1 ">
    <div className="flex items-center justify-between space-x-5 px-4">
      <Typography variant="lg-nexa-bold">
        {formatArea(drawnAreas.totalHectares)}
        {drawnAreas.features.length > 1 && ` (${drawnAreas.features.length} areas)`}
      </Typography>
      <CreateProjectDrawDialog initialFile={file} />
    </div>
  </div>
)
