import React from 'react'
import { cn } from 'src/enviroment/lib/utils'

export type TypographyVariant =
  | '4xl'
  | '4xl-nexa-bold'
  | '3xl-nexa-bold'
  | '2xl-inter'
  | '2xl-nexa-bold'
  | '2xl-nexa'
  | 'xl-bold'
  | 'xl'
  | 'lg'
  | 'lg-nexa-bold'
  | 'lg-inter-medium'
  | 'base-bold'
  | 'base-semibold'
  | 'base'
  | 'base-inter-medium'
  | 'sm-semibold'
  | 'sm-inter-bold'
  | 'sm-medium'
  | 'sm'
  | 'xs'

type TypographyElement = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'div' | 'label'

interface TypographyVariantConfig {
  family: string
  size: string
  weight: string
  element: TypographyElement
}

interface TypographyProps extends React.HTMLAttributes<HTMLElement> {
  variant: TypographyVariant
  element?: TypographyElement
  className?: string
  children: React.ReactNode
}

const TYPOGRAPHY_VARIANTS: Record<TypographyVariant, TypographyVariantConfig> = {
  '4xl': {
    family: 'font-nexa',
    size: 'text-4xl',
    weight: 'font-normal',
    element: 'h1',
  },
  '4xl-nexa-bold': {
    family: 'font-nexa',
    size: 'text-[36px]',
    weight: 'font-bold',
    element: 'h1',
  },
  '3xl-nexa-bold': {
    family: 'font-nexa',
    size: 'text-[30px]',
    weight: 'font-bold',
    element: 'h1',
  },
  '2xl-nexa-bold': {
    family: 'font-nexa',
    size: 'text-[24px]',
    weight: 'font-bold',
    element: 'h1',
  },
  '2xl-nexa': {
    family: 'font-nexa',
    size: 'text-2xl',
    weight: 'font-normal',
    element: 'h1',
  },
  '2xl-inter': {
    family: 'font-inter',
    size: 'text-2xl',
    weight: 'font-semibold',
    element: 'h1',
  },
  'xl-bold': {
    family: 'font-nexa',
    size: 'text-xl',
    weight: 'font-bold',
    element: 'h2',
  },
  xl: {
    family: 'font-nexa',
    size: 'text-xl',
    weight: 'font-normal',
    element: 'h2',
  },
  'lg-nexa-bold': {
    family: 'font-nexa',
    size: 'text-lg',
    weight: 'font-bold',
    element: 'h3',
  },
  lg: {
    family: 'font-nexa',
    size: 'text-lg',
    weight: 'font-normal',
    element: 'h3',
  },
  'lg-inter-medium': {
    family: 'font-inter',
    size: 'text-[18px]',
    weight: 'font-medium',
    element: 'h4',
  },
  'base-bold': {
    family: 'font-nexa',
    size: 'text-base',
    weight: 'font-bold',
    element: 'h4',
  },
  'base-semibold': {
    family: 'font-inter',
    size: 'text-base',
    weight: 'font-semibold',
    element: 'div',
  },
  'base-inter-medium': {
    family: 'font-inter',
    size: 'text-[16px]',
    weight: 'font-medium',
    element: 'div',
  },
  base: {
    family: 'font-inter',
    size: 'text-base',
    weight: 'font-normal',
    element: 'div',
  },
  'sm-semibold': {
    family: 'font-inter',
    size: 'text-sm',
    weight: 'font-semibold',
    element: 'div',
  },
  'sm-medium': {
    family: 'font-inter',
    size: 'text-sm',
    weight: 'font-medium',
    element: 'div',
  },
  sm: {
    family: 'font-inter',
    size: 'text-sm',
    weight: 'font-normal',
    element: 'div',
  },
  'sm-inter-bold': {
    family: 'font-inter',
    size: 'text-[14px]',
    weight: 'font-bold',
    element: 'span',
  },
  xs: {
    family: 'font-inter',
    size: 'text-xs',
    weight: 'font-normal',
    element: 'span',
  },
}

const Typography = ({ variant, element, className, children, ...props }: TypographyProps) => {
  const config = TYPOGRAPHY_VARIANTS[variant]

  // If element is explicitly passed, use it. Otherwise check if we need to prevent invalid nesting
  const Component = element || (props['aria-label'] || props.role === 'paragraph' ? 'p' : config.element)

  const variantClasses = [config.family, config.size, config.weight].join(' ')

  return (
    <Component className={cn(variantClasses, className)} {...props}>
      {children}
    </Component>
  )
}

export default Typography
