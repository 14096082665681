import React from 'react'
import * as Tooltip from '@radix-ui/react-tooltip'
import { IconCopy } from '@tabler/icons-react'
import { enqueueSnackbar } from 'notistack'

const IdCell = ({ id }: { id: unknown }) => {
  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(id as string)
    enqueueSnackbar('ID copied to clipboard', { variant: 'success' })
  }

  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <div className="flex cursor-pointer items-center gap-2" onClick={copyToClipboard}>
            <IconCopy className="h-4 w-4 text-gray-400 transition-opacity group-hover:opacity-100" />
          </div>
        </Tooltip.Trigger>

        <Tooltip.Portal>
          <Tooltip.Content className="rounded bg-gray-800 p-2 text-xs text-white shadow-lg" sideOffset={5}>
            ID: {id as string}
            <Tooltip.Arrow className="fill-gray-800" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  )
}

export default IdCell
