import { AxiosError } from 'axios'
import { enqueueSnackbar } from 'notistack'

interface ApiErrorResponse {
  message?: string
  error?: string
  statusCode?: number
  reason?: {
    file?: string
    [key: string]: unknown
  }
}

export class ApiError extends Error {
  constructor(public message: string, public status?: number, public code?: string) {
    super(message)
    this.name = 'ApiError'
  }
}

export const handleApiError = (error: unknown) => {
  if (error instanceof AxiosError) {
    const response = error.response?.data as ApiErrorResponse

    // Handle the case with reason property (file validation error)
    if (response?.reason?.file) {
      const errorMessage = response.reason.file
      enqueueSnackbar(errorMessage, { variant: 'error' })
      throw new ApiError(errorMessage, error.response?.status, response?.error)
    }

    // Handle standard error response
    const errorMessage = response?.message || 'An unexpected error occurred'
    enqueueSnackbar(errorMessage, { variant: 'error' })
    throw new ApiError(errorMessage, error.response?.status, response?.error)
  }

  const defaultError = 'An unexpected error occurred'
  enqueueSnackbar(defaultError, { variant: 'error' })
  throw new ApiError(defaultError)
}
