import React from 'react'
import { Helmet } from 'react-helmet-async'
import AuthWrapper from 'src/enviroment/layouts/auth-wrapper'
import Typography from 'src/components/atoms/Typography'
import { PrimaryButton } from 'src/components/atoms/buttons/PrimaryButton'
import { SecondaryButton } from 'src/components/atoms/buttons/SecondaryButton'
import ResendTimer from 'src/components/atoms/ResendTimer'

export interface EmailSentPasswordTemplateProps {
  email: string
  handleResend: () => void
  handleGoBack: () => void
  handleTryAnotherEmail: () => void
}

const EmailSentPasswordTemplate: React.FC<EmailSentPasswordTemplateProps> = ({
  email = 'UNKNOWN',
  handleResend,
  handleGoBack,
  handleTryAnotherEmail,
}) => {
  return (
    <>
      <Helmet>
        <title>RAMO - Email Sent</title>
      </Helmet>
      <AuthWrapper>
        <div className="items-center justify-center space-y-8 text-left">
          <Typography variant="4xl-nexa-bold">Email Sent</Typography>
          <div>
            <Typography variant="base">Follow instructions sent to</Typography>
            <Typography variant="base-semibold" className="text-light-green-500">
              {email}
            </Typography>
          </div>
          <div className="space-y-6">
            <div className="space-y-4">
              <ResendTimer>
                {(timeLeft, startTimer, isActive) => (
                  <PrimaryButton
                    onClick={() => {
                      handleResend()
                      startTimer()
                    }}
                    fullWidth
                    disabled={isActive}
                  >
                    {isActive ? `Resend in ${timeLeft}s` : 'Resend an Email'}
                  </PrimaryButton>
                )}
              </ResendTimer>
              <SecondaryButton onClick={handleGoBack} fullWidth>
                Cancel
              </SecondaryButton>
            </div>
            <div>
              <Typography variant="base">Do not receive the email?</Typography>
              <Typography variant="base" className="flex flex-row text-light-green-50">
                Check your spam filter, or
                <Typography
                  variant="base-semibold"
                  className="ml-2 cursor-pointer text-light-green-500 underline hover:text-light-green-400"
                  onClick={handleTryAnotherEmail}
                >
                  try another email
                </Typography>
              </Typography>
            </div>
          </div>
        </div>
      </AuthWrapper>
    </>
  )
}

export default EmailSentPasswordTemplate
