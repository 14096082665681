import React from 'react'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { cn } from 'src/enviroment/lib/utils'
import { IconCheck } from '@tabler/icons-react'

type BaseCheckboxProps = Omit<React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>, 'onCheckedChange'>

export interface CheckboxProps extends BaseCheckboxProps {
  label?: string
  errorMessage?: string
  onCheckedChange?: (checked: boolean) => void
}

const Checkbox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps>(
  ({ className, label, errorMessage, onCheckedChange, ...props }, ref) => {
    const handleChange = (checked: CheckboxPrimitive.CheckedState) => {
      if (onCheckedChange) {
        onCheckedChange(checked === true)
      }
    }

    return (
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2">
          <CheckboxPrimitive.Root
            ref={ref}
            className={cn(
              'peer h-5 w-5 shrink-0 rounded border border-light-green-500 ring-offset-white',
              'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-light-green-500 focus-visible:ring-offset-2',
              'disabled:cursor-not-allowed disabled:border-light-green-300 disabled:bg-light-green-300',
              'data-[state=checked]:bg-light-green-500 data-[state=checked]:text-white',
              'transition-colors duration-200',
              errorMessage && 'border-red-ramo',
              className,
            )}
            onCheckedChange={handleChange}
            {...props}
          >
            <CheckboxPrimitive.Indicator className={cn('flex items-center justify-center text-current')}>
              <IconCheck className="h-4 w-4" />
            </CheckboxPrimitive.Indicator>
          </CheckboxPrimitive.Root>
          {label && (
            <label
              className={cn(
                'font-inter text-sm font-normal text-gray-50 peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
                errorMessage && 'text-red-ramo',
              )}
            >
              {label}
            </label>
          )}
        </div>
        {errorMessage && <span className="text-sm text-red-ramo">{errorMessage}</span>}
      </div>
    )
  },
)

Checkbox.displayName = 'Checkbox'

export { Checkbox }
