import { FC } from 'react'
import Typography from 'src/components/atoms/Typography'
import FileChunksTable from 'src/components/molecules/chunks/FileChunksTable'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LibraryTabProps {}

export const LibraryTab: FC<LibraryTabProps> = () => {
  return (
    <div className={`my-6 flex flex-col gap-8 rounded-[8px] bg-gray-800 p-8`}>
      <div className="flex items-center justify-between gap-8">
        <Typography variant="xl" className="shrink-0 text-light-green-50">
          Library
        </Typography>
      </div>
      <FileChunksTable />
    </div>
  )
}
