import { useCallback, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { IconPencil } from '@tabler/icons-react'

import { PrimaryButton } from '../../atoms/buttons/PrimaryButton'
import { SecondaryButton } from '../../atoms/buttons/SecondaryButton'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '../../atoms/dialog'
import { Input } from '../../atoms/inputs/Input'
import Typography from '../../atoms/Typography'
import { useUpdateTenantAdmin } from 'src/enviroment/api/services/superAdminApi'
import { ITenant, SUBSCRIPTION_PLAN } from 'src/types/user'
import FileUpload from 'src/components/atoms/inputs/StyledFileInput'
import DropDown from 'src/components/atoms/DropDown'
import { plans } from 'src/enviroment/constants/constants'

const EditWorkspaceSchema = z.object({
  name: z
    .string()
    .trim()
    .min(3, { message: 'Name must be at least 3 characters' })
    .max(50, { message: 'Name cannot exceed 50 characters' })
    .regex(/^[a-zA-Z0-9\s]+$/, {
      message: 'Name cannot contain special characters',
    }),
  plan: z.nativeEnum(SUBSCRIPTION_PLAN),
})

export type EditWorkspaceFormData = z.infer<typeof EditWorkspaceSchema>

// Define the type for the update payload
interface UpdateTenantPayload {
  id: string
  name: string
  plan: SUBSCRIPTION_PLAN
  logo?: File | null
}

interface Props {
  workspace: ITenant
}

// Three possible states: unchanged, new file, or delete existing
type LogoAction = { type: 'unchanged' } | { type: 'update'; file: File } | { type: 'delete' }

const EditWorkspaceDialog = ({ workspace }: Props) => {
  const defaultValues: EditWorkspaceFormData = {
    name: workspace.name,
    plan: workspace.plan,
  }

  const [opened, setOpened] = useState(false)
  const [logoAction, setLogoAction] = useState<LogoAction>({ type: 'unchanged' })

  const { mutateAsync: updateTenant, isLoading } = useUpdateTenantAdmin()

  const form = useForm<EditWorkspaceFormData>({
    mode: 'all',
    defaultValues,
    reValidateMode: 'onChange',
    resolver: zodResolver(EditWorkspaceSchema),
  })

  const handleClose = useCallback(() => {
    setOpened(false)
    setLogoAction({ type: 'unchanged' })
    form.reset(defaultValues)
  }, [form, defaultValues])

  const handleSubmit: SubmitHandler<EditWorkspaceFormData> = useCallback(
    async (data) => {
      try {
        const updatePayload: UpdateTenantPayload = {
          id: workspace.id,
          name: data.name,
          plan: data.plan,
        }

        // Add logo field based on the action type
        if (logoAction.type === 'update') {
          updatePayload.logo = logoAction.file
        } else if (logoAction.type === 'delete') {
          updatePayload.logo = null
        }

        await updateTenant(updatePayload)
        handleClose()
      } catch (error) {
        console.error('Failed to update workspace:', error)
      }
    },
    [updateTenant, workspace.id, logoAction, handleClose],
  )

  const handleLogoChange = (file: File | null) => {
    if (file) {
      setLogoAction({ type: 'update', file })
    } else {
      setLogoAction({ type: 'delete' })
    }
  }

  // Determine which logo URL to display
  const getDisplayLogoUrl = () => {
    switch (logoAction.type) {
      case 'delete':
        return null
      case 'update':
        return undefined // FileUpload will handle preview for new files
      case 'unchanged':
      default:
        return workspace.logoUrl
    }
  }

  return (
    <Dialog open={opened} onOpenChange={setOpened}>
      <DialogTrigger asChild>
        <button className="flex h-11 w-11 items-center justify-center rounded-[8px] text-light-green-500 transition-all hover:cursor-pointer hover:bg-light-green-500 hover:text-white active:scale-95">
          <IconPencil size={24} />
        </button>
      </DialogTrigger>
      <DialogContent className="sm:min-w-md">
        <DialogHeader>
          <DialogTitle>
            <Typography variant="2xl-nexa-bold">Edit Workspace</Typography>
          </DialogTitle>
        </DialogHeader>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-6">
          <div className="flex gap-6">
            <div className="w-1/3">
              <Typography variant="sm-medium" element="label" className="mb-2 block text-gray-50">
                Logo
              </Typography>
              <FileUpload
                logoUrl={getDisplayLogoUrl()}
                disabled={isLoading}
                accept="image/*"
                onChange={handleLogoChange}
              />
            </div>
            <div className="w-2/3 space-y-4">
              <Input
                label="Workspace Name"
                placeholder="Enter workspace name"
                errorMessage={form.formState.errors.name?.message}
                disabled={isLoading}
                {...form.register('name')}
              />
              <Controller
                name="plan"
                control={form.control}
                render={({ field }) => (
                  <DropDown
                    label="Change plan"
                    options={plans}
                    value={field.value}
                    errorMessage={form.formState.errors.plan?.message}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
          </div>

          <DialogFooter className="sm:justify-center sm:space-x-6">
            <SecondaryButton type="button" onClick={handleClose} disabled={isLoading} className="w-40">
              Cancel
            </SecondaryButton>
            <PrimaryButton type="submit" isLoading={isLoading} className="w-40">
              Save Changes
            </PrimaryButton>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default EditWorkspaceDialog
