import { IconSearch, IconShieldCog, IconCopy, IconCheck, IconUserPlus, IconUserMinus } from '@tabler/icons-react'
import { FC, useState, useCallback, useEffect } from 'react'
import { PrimaryButton } from '../../atoms/buttons/PrimaryButton'
import { SecondaryButton } from '../../atoms/buttons/SecondaryButton'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '../../atoms/dialog'
import { Label } from '../../atoms/label'
import { Project } from 'src/types/projects'
import { Checkbox } from '../../atoms/buttons/CheckBoxButton'
import { Input } from '../../atoms/inputs/Input'
import {
  useGetTenantsByProject,
  useGetCurrentTenantsOfProject,
  useUpdateProjectTenants,
} from 'src/enviroment/api/services/superAdminApi'
import { useInView } from 'react-intersection-observer'
import Typography from '../../atoms/Typography'
import { enqueueSnackbar } from 'notistack'

interface Props {
  project: Project
}

const EditProjectAccess: FC<Props> = ({ project }) => {
  const [opened, setOpened] = useState<boolean>(false)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [selectedTenants, setSelectedTenants] = useState<string[]>([])
  const [copiedId, setCopiedId] = useState<string | null>(null)
  const { ref, inView } = useInView()

  const { data: currentTenants } = useGetCurrentTenantsOfProject(project.id)
  const { data, hasNextPage, fetchNextPage, isFetchingNextPage } = useGetTenantsByProject(
    { query: searchQuery, take: 40 },
    opened,
  )

  const { mutate: updateTenants, isLoading } = useUpdateProjectTenants(project.id)
  const tenants = data?.pages.flatMap((page) => page.tenants) || []
  const currentTenantIds = currentTenants?.map((tenant) => tenant.id) || []

  useEffect(() => {
    if (currentTenants) {
      setSelectedTenants(currentTenants.map((tenant) => tenant.id))
    }
  }, [currentTenants])

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(e.target.value)
      setSelectedTenants(currentTenants?.map((tenant) => tenant.id) || [])
    },
    [currentTenants],
  )

  const copyToClipboard = async (id: string, e: React.MouseEvent) => {
    e.stopPropagation()
    await navigator.clipboard.writeText(id)
    setCopiedId(id)
    setTimeout(() => setCopiedId(null), 2000)
  }

  const toggleAllTenants = useCallback(() => {
    if (selectedTenants.length === tenants.length) {
      setSelectedTenants([])
    } else {
      setSelectedTenants(tenants.map(({ id }) => id))
    }
  }, [selectedTenants.length, tenants])

  const handleDialogChange = (open: boolean) => {
    setOpened(open)
    if (!open) {
      setSearchQuery('')
      setSelectedTenants(currentTenants?.map((tenant) => tenant.id) || [])
    }
  }

  const handleSubmit = () => {
    updateTenants(selectedTenants, {
      onSuccess: () => {
        setOpened(false)
        enqueueSnackbar('Tenants access updated successfully', { variant: 'success' })
      },
    })
  }

  if (inView && hasNextPage && !isFetchingNextPage) {
    fetchNextPage()
  }

  return (
    <Dialog open={opened} onOpenChange={handleDialogChange}>
      <DialogTrigger className="flex h-11 w-11 items-center justify-center rounded-[8px] text-light-green-500 transition-all hover:cursor-pointer hover:bg-light-green-500 hover:text-white active:scale-95">
        <IconShieldCog size={24} />
      </DialogTrigger>
      <DialogContent className="sm:max-w-[800px]">
        <DialogHeader>
          <DialogTitle>
            <Typography variant="xl">Project Access Management</Typography>
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Typography variant="sm" className="text-gray-400">
                Current Access: {currentTenantIds.length} tenants
              </Typography>
              <Typography variant="sm" className="text-gray-400">
                |
              </Typography>
              <Typography variant="sm" className="text-gray-400">
                Selected: {selectedTenants.length} tenants
              </Typography>
            </div>
            <div className="flex gap-2">
              <SecondaryButton onClick={toggleAllTenants} className="h-8 px-3">
                {selectedTenants.length === tenants.length ? 'Deselect All' : 'Select All'}
              </SecondaryButton>
            </div>
          </div>

          <div className="w-full rounded-lg bg-gray-900 p-4">
            <div className="mb-4">
              <Input
                placeholder="Search tenants by name..."
                value={searchQuery}
                icon={<IconSearch size={20} />}
                onChange={handleSearchChange}
              />
            </div>

            <div className="rounded-lg border border-gray-700 bg-gray-800">
              <div className="max-h-[400px] overflow-y-auto">
                {tenants.map(({ id, name }) => {
                  const checked = selectedTenants.includes(id)
                  const hasAccess = currentTenantIds.includes(id)

                  const onCheckedChange = () => {
                    setSelectedTenants((prev) => (checked ? prev.filter((tid) => tid !== id) : [...prev, id]))
                  }

                  return (
                    <div
                      key={id}
                      onClick={onCheckedChange}
                      className={`group flex cursor-pointer items-center justify-between border-b border-gray-700 p-3 hover:bg-gray-700 ${
                        checked ? 'bg-gray-700/50' : ''
                      }`}
                    >
                      <div className="flex items-center gap-3">
                        <Checkbox id={id} checked={checked} />
                        <div>
                          <Label htmlFor={id} className="block font-medium text-gray-50">
                            {name}
                          </Label>
                          <div className="flex items-center gap-2">
                            <span className="text-sm text-gray-400">{id}</span>
                            <button
                              className="text-gray-400 opacity-0 transition-opacity group-hover:opacity-100"
                              onClick={(e) => copyToClipboard(id, e)}
                            >
                              {copiedId === id ? (
                                <IconCheck size={14} className="text-green-500" />
                              ) : (
                                <IconCopy size={14} />
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                      {hasAccess ? (
                        <span className="flex items-center gap-1 text-xs text-gray-400">
                          <IconUserPlus size={14} /> Has Access
                        </span>
                      ) : (
                        <span className="flex items-center gap-1 text-xs text-gray-400">
                          <IconUserMinus size={14} /> No Access
                        </span>
                      )}
                    </div>
                  )
                })}

                {(hasNextPage || isFetchingNextPage) && (
                  <div ref={ref} className="p-3 text-center text-sm text-gray-400">
                    {isFetchingNextPage ? 'Loading more...' : 'Scroll to load more'}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <DialogFooter className="sm:justify-end sm:space-x-4">
          <SecondaryButton onClick={() => setOpened(false)}>Cancel</SecondaryButton>
          <PrimaryButton onClick={handleSubmit} isLoading={isLoading}>
            Save Changes
          </PrimaryButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default EditProjectAccess
