import { FC, useCallback, useState } from 'react'
import { IconTrash } from '@tabler/icons-react'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogTrigger,
} from '../../atoms/alert-dialog'
import { Input } from '../../atoms/inputs/Input'
import Typography from '../../atoms/Typography'
import DeleteButton from 'src/components/atoms/buttons/DeleteButton'
import { useDeleteTenantProject } from 'src/enviroment/api/services/projectsApi'

interface DeleteMyProjectDialogProps {
  projectId: string
  projectName: string
}

const DeleteMyProjectDialog: FC<DeleteMyProjectDialogProps> = ({ projectId, projectName }) => {
  const [opened, setOpened] = useState(false)
  const [confirmName, setConfirmName] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { mutateAsync } = useDeleteTenantProject()
  const handleClose = useCallback(() => {
    setOpened(false)
    setConfirmName('')
  }, [])

  const isConfirmed = confirmName === projectName

  const handleDelete = useCallback(async () => {
    if (!isConfirmed) return

    try {
      setIsLoading(true)
      await mutateAsync(projectId)
      handleClose()
    } catch (error) {
      console.error('Error deleting project:', error)
    } finally {
      setIsLoading(false)
    }
  }, [isConfirmed, mutateAsync, projectId, handleClose])

  return (
    <AlertDialog open={opened} onOpenChange={setOpened}>
      <AlertDialogTrigger asChild>
        <DeleteButton className="opacity-100" />
      </AlertDialogTrigger>
      <AlertDialogContent>
        <div className="flex flex-col items-center gap-4">
          <div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#FFADAD]">
            <IconTrash className="h-6 w-6 text-red-600" />
          </div>
          <div className="flex flex-col justify-center gap-6">
            <Typography variant="2xl-nexa-bold" className="text-center text-green-50">
              Delete Project
            </Typography>
            <Typography variant="base" className="text-center text-gray-50">
              You&apos;re about to delete the{' '}
              <Typography variant="base-semibold" className="inline">
                {projectName}
              </Typography>{' '}
              project. Please type the project name to confirm deletion.
            </Typography>
            <Input
              value={confirmName}
              onChange={(e) => setConfirmName(e.target.value)}
              placeholder="Enter project name"
              className="text-center"
            />
          </div>
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={isLoading} onClick={handleClose} className="w-40">
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction
            disabled={isLoading || !isConfirmed}
            onClick={handleDelete}
            className="w-40 disabled:cursor-not-allowed disabled:opacity-50"
          >
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default DeleteMyProjectDialog
