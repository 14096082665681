import React, { useState, useRef, useEffect } from 'react'
import { IconSend, IconLoader2, IconArrowsDiagonalMinimize, IconMapPin } from '@tabler/icons-react'
import Broom from '../atoms/icons/Broom'
import Typography from '../atoms/Typography'
import { cn } from 'src/enviroment/lib/utils'
import { useChatStore } from 'src/enviroment/store/chatStore'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import CopaLogo from '../../assets/logo-copa.svg?react'
import { sendConversation } from 'src/enviroment/api/chat'
import { ChatMessage } from 'src/enviroment/api/chat/models'

const ChatWidget = () => {
  const { messages, addMessage, addErrorMessage, clearMessages, initializeMessages, selectedCountry } = useChatStore()
  const [inputMessage, setInputMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const messagesEndRef = useRef<HTMLDivElement>(null)
  const abortControllerRef = useRef<AbortController | null>(null)

  useEffect(() => {
    initializeMessages()
  }, [initializeMessages])

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  useEffect(() => {
    return () => {
      abortControllerRef.current?.abort()
    }
  }, [])

  const handleSendMessage = async () => {
    if (!inputMessage.trim() || isLoading) return

    const userMessage = {
      id: Date.now().toString(),
      role: 'user' as const,
      content: inputMessage,
      date: new Date().toISOString(),
    }

    addMessage(userMessage)
    setInputMessage('')
    setIsLoading(true)

    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
    }

    abortControllerRef.current = new AbortController()

    try {
      // Format messages for the API
      const formattedMessages: ChatMessage[] = [...messages.filter((msg) => msg.id !== 'welcome'), userMessage].map(
        (msg) => ({
          role: msg.role as 'user' | 'assistant',
          content: typeof msg.content === 'string' ? msg.content : JSON.stringify(msg.content),
        }),
      )

      // Call the API
      const response = await sendConversation(
        formattedMessages,
        selectedCountry || undefined,
        abortControllerRef.current.signal,
      )

      // Add the response to the chat
      addMessage({
        id: Date.now().toString(),
        role: 'assistant',
        content: response.response,
        date: new Date().toISOString(),
      })
    } catch (error) {
      if (error instanceof Error && error.name === 'AbortError') {
        console.log('Request aborted')
        return
      }
      addErrorMessage(error)
    } finally {
      setIsLoading(false)
      abortControllerRef.current = null
    }
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleSendMessage()
    }
  }

  return (
    <div className="fixed bottom-12 right-10 z-50">
      {isExpanded ? (
        <div className="flex h-[700px] w-[500px] flex-col rounded-lg border border-gray-700 bg-gray-900 shadow-lg">
          {/* Header */}
          <div className="flex items-center justify-between border-b border-gray-800 p-4">
            <div className="flex items-center">
              <div className="flex h-8 w-8 items-center justify-center">
                <CopaLogo width={32} height={32} />
              </div>
              <Typography variant="base-semibold" className="ml-2 text-gray-100">
                Copa
              </Typography>
            </div>
            <div className="flex items-center gap-2">
              {/* Show selected country indicator if one is selected */}
              {selectedCountry && (
                <div className="flex items-center gap-1 rounded-full px-2 py-1 text-sm text-gray-300">
                  <IconMapPin className="h-4 w-4" stroke={1.5} />
                  <span>{selectedCountry}</span>
                </div>
              )}

              {messages.length > 0 && (
                <button
                  onClick={clearMessages}
                  className="rounded-full p-1 transition-colors hover:bg-gray-800"
                  title="Clear chat"
                >
                  <Broom className="text-gray-500" />
                </button>
              )}
              <button
                onClick={() => setIsExpanded(false)}
                className="rounded-full p-1 transition-colors hover:bg-gray-800"
                title="Minimize"
              >
                <IconArrowsDiagonalMinimize className="h-5 w-5 text-gray-400" stroke={1.5} />
              </button>
            </div>
          </div>

          {/* Messages Area */}
          <div className={cn('custom-scrollbar flex-1 space-y-4 overflow-y-auto p-4')}>
            {messages.map((message) => (
              <div
                key={message.id}
                className={cn('flex items-center gap-3', message.role === 'user' ? 'justify-end' : 'justify-start')}
              >
                {message.role === 'assistant' && (
                  <div className="flex-shrink-0">
                    <CopaLogo width={24} height={24} />
                  </div>
                )}
                <div
                  className={cn(
                    'max-w-[80%] rounded-lg p-3',
                    message.role === 'user'
                      ? 'bg-gray-700'
                      : message.isError
                      ? 'border border-red-700 bg-red-900/50'
                      : 'bg-gray-800',
                    'text-gray-100',
                  )}
                >
                  <Markdown
                    remarkPlugins={[remarkGfm]}
                    components={{
                      ul: ({ children }) => <ul className="ml-4 list-disc space-y-1">{children}</ul>,
                      ol: ({ children }) => <ol className="ml-4 list-decimal space-y-1">{children}</ol>,
                      li: ({ children }) => <li className="text-gray-100">{children}</li>,
                    }}
                  >
                    {typeof message.content === 'string' ? message.content : JSON.stringify(message.content)}
                  </Markdown>
                </div>
              </div>
            ))}
            {isLoading && (
              <div className="flex items-center gap-3">
                <div className="flex-shrink-0">
                  <CopaLogo width={24} height={24} />
                </div>
                <div className="max-w-[80%] rounded-lg bg-gray-800 p-3">
                  <IconLoader2 className="h-4 w-4 animate-spin text-gray-400" stroke={1.5} />
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>

          {/* Stop Generation Button */}
          {isLoading && (
            <div className="border-t border-gray-800 px-4 pt-2">
              <button
                onClick={() => abortControllerRef.current?.abort()}
                className="flex w-full items-center justify-center gap-2 rounded-lg bg-gray-800 px-3 py-1.5 text-gray-100 hover:bg-gray-700"
              >
                <div className="h-2 w-2 border border-gray-100" />
                <span className="text-sm">Stop Generation</span>
              </button>
            </div>
          )}

          {/* Input Area */}
          <div className="border-t border-gray-800 p-4">
            <div className="flex gap-2">
              <textarea
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyDown={handleKeyPress}
                placeholder="Type your message..."
                className="flex flex-1 resize-none items-center rounded-lg 
                         border border-gray-700 bg-gray-800 px-3 py-2
                         align-middle leading-6 text-gray-100
                         placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-700"
                rows={1}
              />
              <button
                onClick={handleSendMessage}
                disabled={isLoading || !inputMessage.trim()}
                className="rounded-lg bg-gray-700 p-3 text-gray-100 transition-colors 
                         hover:bg-gray-600 focus:outline-none focus:ring-2 
                         focus:ring-gray-700 disabled:cursor-not-allowed disabled:opacity-50"
              >
                <IconSend className="h-4 w-4" stroke={1.5} />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <button onClick={() => setIsExpanded(true)} className="hover:animate-pulse">
          <CopaLogo width={48} height={48} />
        </button>
      )}
    </div>
  )
}

export default ChatWidget
