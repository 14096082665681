import { SUBSCRIPTION_PLAN, UserRole } from 'src/types/user'

export const CONSTANT_A = 'CONSTANT_A'

export const roles = [
  {
    label: 'Admin',
    value: UserRole.ADMIN,
  },
  {
    label: 'Contributor',
    value: UserRole.CONTRIBUTOR,
  },
  {
    label: 'Viewer',
    value: UserRole.VIEWER,
  },
]

export const plans = [
  {
    id: 'Free',
    label: 'Free',
    value: SUBSCRIPTION_PLAN.FREE,
  },
  {
    id: 'Premium',
    label: 'Premium',
    value: SUBSCRIPTION_PLAN.PREMIUM,
  },
  {
    id: 'Enterprise',
    label: 'Enterprise',
    value: SUBSCRIPTION_PLAN.ENTERPRISE,
  },
]
export const userTypes = [
  {
    id: 'CarbonDeveloper',
    label: 'Carbon developer',
    value: 'CarbonDeveloper',
  },
  {
    id: 'LandOwner',
    label: 'Land owner or steward',
    value: 'LandOwner',
  },
  {
    id: 'Investor',
    label: 'Investor',
    value: 'Investor',
  },
  {
    id: 'Government',
    label: 'Government Representative',
    value: 'Government',
  },
  {
    id: 'Curious',
    label: 'Curious about carbon',
    value: 'Curious',
  },
]

export const userPurposes = [
  {
    id: 'DevelopProject',
    label: 'Looking to develop a carbon project',
    value: 'DevelopProject',
  },
  {
    id: 'FindSolutions',
    label: 'Searching for carbon solutions to my land',
    value: 'FindSolutions',
  },
  {
    id: 'UnderstandMarket',
    label: 'Understanding the market & landscape',
    value: 'UnderstandMarket',
  },
  {
    id: 'Invest',
    label: 'Want to invest in carbon credits',
    value: 'Invest',
  },
]

export const stylesDrawPolygon = [
  // ACTIVE (being drawn)
  // line stroke
  {
    id: 'gl-draw-line',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#FFA800',
      'line-dasharray': [0.2, 2],
      'line-width': 3,
    },
  },
  // polygon fill
  {
    id: 'gl-draw-polygon-fill',
    type: 'fill',
    filter: ['all', ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': '#6CC0A7',
      'fill-outline-color': '#FFA800',
      'fill-opacity': 0.32,
    },
  },
  // polygon mid points
  {
    id: 'gl-draw-polygon-midpoint',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
    paint: {
      'circle-radius': 3,
      'circle-color': '#fbb03b',
    },
  },
  // polygon outline stroke
  // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: ['all', ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#FFA800',
      'line-width': 3,
    },
  },
  // vertex point halos
  {
    id: 'gl-draw-polygon-and-line-vertex-halo-active',
    type: 'circle',
    filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point']],
    paint: {
      'circle-radius': 5,
      'circle-color': '#FFF',
    },
  },
  // vertex points
  {
    id: 'gl-draw-polygon-and-line-vertex-active',
    type: 'circle',
    filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point']],
    paint: {
      'circle-radius': 6,
      'circle-color': '#000000',
      'circle-stroke-width': 4,
      'circle-stroke-color': '#FFA800',
    },
  },
] as object[]

export const ACTIVE_COUNTRIES = ['GHA', 'AGO', 'MOZ', 'COD', 'ZMB', 'PRT']

export const CHUNK_SIZE = 5 * 1024 * 1024

export const formatBytes = (bytes: number): string => {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const sizes = ['Bytes', 'KB', 'MB', 'GB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`
}

export const WIDGET_TYPES = [
  // { id: 'statistic', label: 'Statistic', value: 'statistic' },
  // { id: 'bar-height', label: 'Bar Height', value: 'bar-height' },
  { id: 'histogram', label: 'Histogram', value: 'histogram' },
  // { id: 'filter', label: 'Filter', value: 'filter' },
]
