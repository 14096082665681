import { useState, useRef, useEffect } from 'react'
import Typography from '../../atoms/Typography'
import { LayerItem } from 'src/enviroment/api/services/layersApi'
import { enqueueSnackbar } from 'notistack'

interface EditableNameCellProps {
  initialValue: string
  rowData: LayerItem
  onUpdate: (id: string, newName: string) => Promise<void>
}

const EditableNameCell = ({ initialValue, rowData, onUpdate }: EditableNameCellProps) => {
  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState(initialValue)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isEditing])

  const handleClick = () => {
    setIsEditing(true)
  }

  const handleBlur = async () => {
    setIsEditing(false)

    if (value !== initialValue) {
      try {
        await onUpdate(rowData.id, value)
      } catch (error) {
        setValue(initialValue)
      }
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      inputRef.current?.blur()
    } else if (e.key === 'Escape') {
      setValue(initialValue)
      setIsEditing(false)
    }
  }

  return (
    <div className="min-w-[150px]">
      {isEditing ? (
        <input
          ref={inputRef}
          className="w-full rounded border border-gray-600 bg-gray-800 px-2 py-1 text-white outline-none focus:border-light-green-100"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />
      ) : (
        <Typography
          variant="base"
          className="cursor-pointer text-white transition-colors hover:text-blue-400"
          onClick={handleClick}
        >
          {value}
        </Typography>
      )}
    </div>
  )
}

export default EditableNameCell
