import { FC, useState } from 'react'
import { IconLogout } from '@tabler/icons-react'
import { PrimaryButton } from '../atoms/buttons/PrimaryButton'
import Typography from '../atoms/Typography'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '../atoms/dialog'
import { useNavigate } from 'react-router'
import { ROUTES } from 'src/types/routes'

const LogoutDialog: FC = () => {
  const navigate = useNavigate()
  const [opened, setOpened] = useState<boolean>(false)

  const handleDialogChange = (open: boolean) => {
    setOpened(open)
  }

  const handleLogout = async () => {
    navigate(ROUTES.LOGOUT)
    setOpened(false)
  }

  return (
    <Dialog open={opened} onOpenChange={handleDialogChange}>
      <DialogTrigger asChild>
        <div className="flex items-end justify-end">
          <PrimaryButton rightIcon={<IconLogout size={20} />}>Logout</PrimaryButton>
        </div>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <div className="flex flex-col items-center gap-2">
            <div className="mb-2 items-center rounded-full bg-[#FFADAD] p-2">
              <IconLogout className="text-red-ramo" />
            </div>
            <DialogTitle>
              <Typography variant="xl" className="text-light-green-50">
                Already leaving?
              </Typography>
            </DialogTitle>
          </div>
        </DialogHeader>

        <div className="mt-4 space-y-6">
          <Typography variant="base" className="text-center text-gray-50">
            We will be eagerly waiting for you to return with your exciting carbon projects
          </Typography>
        </div>

        <DialogFooter className="mt-6 flex flex-col-reverse gap-4 sm:flex-row sm:justify-center">
          <PrimaryButton
            onClick={() => setOpened(false)}
            className="h-[48px] w-[160px] border border-light-green-500 bg-transparent text-light-green-500 hover:bg-gray-800 hover:text-light-green-50"
          >
            Cancel
          </PrimaryButton>
          <PrimaryButton onClick={handleLogout} className="h-[48px] w-[160px] bg-red-500 hover:bg-red-600">
            Log out
          </PrimaryButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default LogoutDialog
