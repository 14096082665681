import React from 'react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import Typography from 'src/components/atoms/Typography'
import { IconArrowsUpDown, IconDownload } from '@tabler/icons-react'
import { format } from 'date-fns'
import { useGetPaymentHistory, PaymentHistoryItem } from 'src/enviroment/api/services/paymentHistoryApi'
import { capitalizeText } from 'src/enviroment/lib/utils'
import { SecondaryButton } from 'src/components/atoms/buttons/SecondaryButton'
import { Loader2 } from 'lucide-react'

const columnHelper = createColumnHelper<PaymentHistoryItem>()

const columns = [
  columnHelper.accessor('number', {
    header: ({ column }) => (
      <button className="flex items-center gap-1" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
        <Typography variant="base">Invoice Number</Typography>
        <IconArrowsUpDown className="h-4 w-4" />
      </button>
    ),
    cell: (info) => <Typography variant="base">{info.getValue()}</Typography>,
  }),
  columnHelper.accessor('amountInCents', {
    header: ({ column }) => (
      <button className="flex items-center gap-1" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
        <Typography variant="base">Amount</Typography>
        <IconArrowsUpDown className="h-4 w-4" />
      </button>
    ),
    cell: (info) => <Typography variant="base">${(info.getValue() / 100).toLocaleString()}</Typography>,
  }),
  columnHelper.accessor('createdAt', {
    header: ({ column }) => (
      <button className="flex items-center gap-1" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
        <Typography variant="base">Date</Typography>
        <IconArrowsUpDown className="h-4 w-4" />
      </button>
    ),
    cell: (info) => <Typography variant="base">{format(new Date(info.getValue()), 'dd/MM/yyyy')}</Typography>,
  }),
  columnHelper.accessor('status', {
    header: ({ column }) => (
      <button className="flex items-center gap-1" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
        <Typography variant="base-semibold">Status</Typography>
        <IconArrowsUpDown className="h-4 w-4" />
      </button>
    ),
    cell: (info) => {
      const status = info.getValue()
      return <Typography variant="base">{capitalizeText(status)}</Typography>
    },
  }),
  columnHelper.accessor('invoiceUrl', {
    header: 'Actions',
    cell: (info) => (
      <a
        href={info.getValue()}
        target="_parent"
        rel="noopener noreferrer"
        className="flex items-center gap-2 text-light-green-500 hover:text-light-green-600"
      >
        <IconDownload className="h-4 w-4" />
        <Typography variant="base-semibold">Download</Typography>
      </a>
    ),
  }),
]

export default function PaymentTable() {
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [payments, setPayments] = React.useState<PaymentHistoryItem[]>([])
  const [lastId, setLastId] = React.useState<string>()

  const { data, isLoading, isFetching } = useGetPaymentHistory({
    take: 10,
    cursorId: lastId,
  })

  React.useEffect(() => {
    if (data?.payments && !lastId) {
      setPayments(data.payments)
    } else if (data?.payments) {
      setPayments((prev) => [...prev, ...data.payments])
    }
  }, [data?.payments, lastId])

  const table = useReactTable({
    data: payments,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const handleLoadMore = () => {
    if (data?.payments.length) {
      setLastId(data.payments[data.payments.length - 1].id)
    }
  }

  if (isLoading) {
    return (
      <div className="flex justify-center py-8">
        <Loader2 size={32} className="animate-spin text-light-green-700" />
      </div>
    )
  }

  if (!payments.length) {
    return (
      <div className="flex justify-center py-8">
        <Typography variant="base" className="text-light-green-50">
          No payment history available
        </Typography>
      </div>
    )
  }

  return (
    <div className="w-full">
      <div className="overflow-hidden rounded-lg border border-gray-800 bg-gray-900">
        <table className="w-full text-white">
          <thead className="bg-gray-900">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} className="px-6 py-4 text-left">
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="border-t border-gray-800 bg-gray-800 hover:bg-gray-900">
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="px-6 py-4">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {data?.hasMore && (
        <div className="mt-4 flex justify-center">
          <SecondaryButton onClick={handleLoadMore} disabled={isFetching}>
            {isFetching ? 'Loading...' : 'Load More'}
          </SecondaryButton>
        </div>
      )}
    </div>
  )
}
