import { Helmet } from 'react-helmet-async'
import { useGetAtlasProjects } from 'src/enviroment/api/services/projectsApi'
import { useState, useMemo } from 'react'
import { Types, useGetLocationsMyProjects, useGetTypesMyProjects } from 'src/enviroment/api/services/typesApi'
import { useGetStagesFromMyProject } from 'src/enviroment/api/services/stageApi'
import FilterDropdown from 'src/components/atoms/FilterDropDown'
import MapLoadingHolder from 'src/components/atoms/map-loading-holder'
import ReworkMapBox from 'src/components/organisms/mapbox/ReworkMapBox'
import DashboardHeader from 'src/components/molecules/DashboardHeader'

const AtlasPage: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const handleMapLoading = () => setLoading(false)

  const [selectedTypesIds, setSelectedTypesIds] = useState<string[]>([])
  const [selectedStagesIds, setSelectedStagesIds] = useState<string[]>([])
  const [selectedLocations, setSelectedLocations] = useState<string[]>([])
  const { data: projects, refetch } = useGetAtlasProjects({
    typeIds: selectedTypesIds,
    stageIds: selectedStagesIds,
    location: selectedLocations,
  })

  const { data: types } = useGetTypesMyProjects()
  const { data: stages } = useGetStagesFromMyProject()
  const { data: locations } = useGetLocationsMyProjects()
  const [component, setComponent] = useState<any>(null)
  const formattedLocations = useMemo(
    () =>
      locations?.map((location) => ({
        id: location,
        name: location,
      })) as unknown as Types[],
    [locations],
  )

  return (
    <>
      <Helmet>
        <title>RAMO - Atlas</title>
      </Helmet>

      <div className="flex h-full flex-col">
        <DashboardHeader title="Welcome to Atlas" subtitle="Select a project to start ..." rightSide={component} />

        <div className="relative h-full w-full">
          <div className="h-full w-full">
            <div className="absolute left-5 top-4 z-10 flex gap-2 p-2">
              {types && (
                <FilterDropdown
                  type="Types"
                  theme="dark"
                  options={types}
                  onChange={(ids) => setSelectedTypesIds(ids)}
                  className="w-full min-w-[150px] sm:w-[150px]"
                />
              )}
              {stages && (
                <FilterDropdown
                  type="Stages"
                  theme="dark"
                  options={stages}
                  onChange={(ids) => setSelectedStagesIds(ids)}
                  className="w-full min-w-[150px] sm:w-[150px]"
                />
              )}
              {formattedLocations && (
                <FilterDropdown
                  type="Location"
                  theme="dark"
                  options={formattedLocations}
                  onChange={(ids) => setSelectedLocations(ids)}
                  className="w-full min-w-[150px] sm:w-[150px]"
                />
              )}
            </div>
            <ReworkMapBox
              projects={projects}
              setComponent={setComponent}
              initialOptions={{
                style: 'mapbox://styles/mapbox/standard-satellite',
                projection: 'globe',
              }}
              onLoaded={handleMapLoading}
              refetch={refetch}
            />
          </div>
          {loading && <MapLoadingHolder />}
        </div>
      </div>
    </>
  )
}

export default AtlasPage
