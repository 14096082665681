import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import Confetti from 'react-confetti'
import useWindowSize from 'react-use/lib/useWindowSize'
import AuthWrapper from 'src/enviroment/layouts/auth-wrapper'
import Typography from 'src/components/atoms/Typography'
import { PrimaryButton } from 'src/components/atoms/buttons/PrimaryButton'
import { SecondaryButton } from 'src/components/atoms/buttons/SecondaryButton'

export interface RequestSentTemplateProps {
  handleGoMain: () => void
  handleLogout: () => void
}

const RequestSentTemplate: React.FC<RequestSentTemplateProps> = ({ handleGoMain, handleLogout }) => {
  const [showConfetti, setShowConfetti] = useState(true)
  const { width, height } = useWindowSize()

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(false)
    }, 8000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <>
      {showConfetti && <Confetti width={width} height={height} recycle={false} numberOfPieces={200} />}
      <Helmet>
        <title>RAMO - Awaiting approve</title>
      </Helmet>
      <AuthWrapper>
        <div className="justify-left items-left space-y-4 text-left">
          <Typography variant="4xl-nexa-bold">Request Sent</Typography>
          <Typography variant="base">RAMO will review and get in touch with you as soon as possible.</Typography>
          <div className="space-y-6">
            <div className="space-y-4">
              <PrimaryButton onClick={handleGoMain} fullWidth>
                Go to main page
              </PrimaryButton>
              <SecondaryButton onClick={handleLogout} fullWidth >
                Back
              </SecondaryButton>
            </div>
          </div>
        </div>
      </AuthWrapper>
    </>
  )
}

export default RequestSentTemplate
