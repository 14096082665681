import React from 'react'
import { cn } from 'src/enviroment/lib/utils'
import { Button, ButtonProps } from '../buttons/Button'

interface EditButtonProps extends ButtonProps {
  className?: string
  children: React.ReactNode
}

export function EditButton({ className, children, ...props }: EditButtonProps) {
  return (
    <Button
      className={cn(
        'h-10 w-10 rounded-lg border border-light-green-500 bg-transparent p-0 hover:bg-light-green-500/10',
        className,
      )}
      {...props}
    >
      <div className="text-light-green-500">{children}</div>
    </Button>
  )
}
