import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Project, ProjectNames } from 'src/types/projects'
import { client } from '../axios'
import { useTenantStore } from 'src/enviroment/store/tenentStore'
import { queryTool } from './superAdminApi'
import { handleApiError } from '../errorHandler'
import { ProjectUpdateFormData } from 'src/components/organisms/dialogs/EditMyProjectDialog'

interface CreateProjectFromShapeDTO {
  name: string
  shape: File
  typeIds?: string[]
  stageId?: string
}

interface CreateProjectFromShapeResponse {
  id: string
  name: string
  location: string
  area: number
  image: string
}
export enum ProjectTags {
  List = 'ProjectList',
  AtlasProject = 'AtlasProjects',
  ProjectNames = 'ProjectNames',
  Detail = 'ProjectDetail',
}

export function useGetAtlasProjects({
  typeIds = [],
  stageIds = [],
  location = [],
}: {
  searchTerm?: string
  typeIds?: string[]
  stageIds?: string[]
  location?: string[]
}) {
  const selectedTenantId = useTenantStore((state) => state.currentTenant?.id)
  if (!selectedTenantId) alert('Tenant not selected, please infrom support about this error')
  return useQuery({
    queryKey: [ProjectTags.AtlasProject, selectedTenantId, typeIds, stageIds, location],
    queryFn: async () => {
      // Create params object only with non-empty arrays
      const params = {
        ...(typeIds.length > 0 && { typeIds }),
        ...(stageIds.length > 0 && { stageIds }),
        ...(location.length > 0 && { location }),
      }

      const response = await client.get<Project[]>(`/tenants/${selectedTenantId}/projects:atlas`, {
        params,
      })
      return response.data
    },
    refetchOnWindowFocus: true, // Refetch when window regains focus
    refetchOnMount: true, // Refetch when component mounts
    refetchOnReconnect: true, // Refetch when network reconnects
    staleTime: 3 * 60 * 1000, // Data becomes stale after 3 minutes
    cacheTime: 10 * 60 * 1000, // Remove from cache after 10 minutes
    retry: 3, // Retry failed requests 3 times
    retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000), // Exponential backoff
    enabled: Boolean(selectedTenantId),
  })
}

interface ExportLayerDTO {
  export_link: string
}

interface ExportLayerParams {
  projectId: string
  layerId: string
}

export function useGetLinkForExportDownload() {
  const selectedTenantId = useTenantStore((state) => state.currentTenant?.id)

  return useMutation({
    mutationFn: async ({ projectId, layerId }: ExportLayerParams) => {
      const response = await client.get<ExportLayerDTO>(
        `/tenants/${selectedTenantId}/projects/${projectId}/layers/${layerId}:export`,
      )
      window.open(response.data.export_link, '_blank')
      return response.data
    },
  })
}

export function useProjectsQuery({
  searchTerm,
  typeIds = [],
  stageIds = [],
  location = [],
}: {
  searchTerm?: string
  typeIds?: string[]
  stageIds?: string[]
  location?: string[]
}) {
  const selectedTenantId = useTenantStore((state) => state.currentTenant?.id)

  return useInfiniteQuery({
    queryKey: [ProjectTags.List, selectedTenantId, searchTerm, typeIds, stageIds, location],
    queryFn: async ({ pageParam }) => {
      const response = await client.get<Project[]>(`/tenants/${selectedTenantId}/projects:filter`, {
        params: {
          cursorId: pageParam,
          take: 20,
          query: queryTool(searchTerm),
          typeIds,
          stageIds,
          location,
        },
      })
      return response.data
    },
    enabled: Boolean(selectedTenantId),
    getNextPageParam: (lastPage) => lastPage.at(-1)?.id,
  })
}

export function useProjectsNamesQuery() {
  const currentTenantId = useTenantStore((state) => state.currentTenant?.id)
  return useQuery({
    queryKey: [ProjectTags.ProjectNames],
    queryFn: async () => {
      const response = await client.get<ProjectNames[]>(`/tenants/${currentTenantId}/projects:all-names`)

      return response.data
    },
  })
}

export function useProjectById(projectId: string) {
  const currentTenantId = useTenantStore((state) => state.currentTenant?.id)
  return useQuery({
    queryKey: [ProjectTags.Detail, projectId],
    queryFn: async () => {
      return client.get<Project>(`/tenants/${currentTenantId}/projects/${projectId}`).then((res) => res.data)
    },
  })
}

export function useCreateProjectFromShape() {
  const selectedTenantId = useTenantStore((state) => state.currentTenant?.id)

  return useMutation({
    mutationFn: async (data: CreateProjectFromShapeDTO) => {
      if (!selectedTenantId) {
        throw new Error('Tenant not selected')
      }

      const formData = new FormData()
      formData.append('name', data.name)
      formData.append('shape', data.shape)
      data.typeIds && data.typeIds.forEach((typeId) => formData.append('typeIds', typeId))
      data.typeIds?.forEach((typeId) => formData.append('typeIds', typeId))

      data.stageId && formData.append('stageId', data.stageId)

      const response = await client.post<CreateProjectFromShapeResponse>(
        `/tenants/${selectedTenantId}/projects`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      return response.data
    },

    onError: (error) => {
      handleApiError(error)
    },
  })
}

export const useValidateProjectZone = () => {
  const selectedTenantId = useTenantStore((state) => state.currentTenant?.id)

  return useMutation({
    mutationFn: async (file: File) => {
      const formData = new FormData()
      formData.append('shape', file)
      const { data } = await client.post<{ success: boolean }>(
        `/tenants/${selectedTenantId}/projects:validate`,
        formData,
      )
      return data.success
    },
    onError: handleApiError,
  })
}

export const useUpdateTenantProject = () => {
  const queryClient = useQueryClient()
  const selectedTenantId = useTenantStore((state) => state.currentTenant?.id)

  return useMutation({
    mutationFn: async ({ projectId, payload }: { projectId: string; payload: ProjectUpdateFormData }) => {
      await client.patch(`/tenants/${selectedTenantId}/projects/${projectId}`, payload)
    },
    onError: handleApiError,
    onSuccess: () => {
      // Instead of just invalidating by tag, invalidate all query keys that start with ProjectTags.List
      queryClient.invalidateQueries({
        predicate: (query) => {
          const queryKey = query.queryKey as unknown[]
          return Array.isArray(queryKey) && queryKey[0] === ProjectTags.List
        },
      })

      // Also invalidate atlas projects as they might need refreshing too
      queryClient.invalidateQueries({
        queryKey: [ProjectTags.AtlasProject],
      })
    },
  })
}

export const useDeleteTenantProject = () => {
  const queryClient = useQueryClient()
  const selectedTenantId = useTenantStore((state) => state.currentTenant?.id)

  return useMutation({
    mutationFn: async (projectId: string) => {
      const { data } = await client.delete(`/tenants/${selectedTenantId}/projects/${projectId}`)
      return data
    },
    onError: handleApiError,
    onSuccess: () => {
      // Instead of just invalidating by tag, invalidate all query keys that start with ProjectTags.List
      queryClient.invalidateQueries({
        predicate: (query) => {
          const queryKey = query.queryKey as unknown[]
          return Array.isArray(queryKey) && queryKey[0] === ProjectTags.List
        },
      })

      // Also invalidate atlas projects as they might need refreshing too
      queryClient.invalidateQueries({
        queryKey: [ProjectTags.AtlasProject],
      })
    },
  })
}
