import React, { useState, useEffect } from 'react'
import { X } from 'lucide-react'
import { truncateFilename } from 'src/enviroment/lib/utils'

interface FileUploadStatusProps {
  file: File
  onRemove: () => void
}

const FileUploadStatus: React.FC<FileUploadStatusProps> = ({ file, onRemove }) => {
  const [preview, setPreview] = useState<string>('')

  useEffect(() => {
    if (!file) return

    const isSupportedImage = file.type.startsWith('image/') && !file.type.includes('tiff') && !file.type.includes('tif')

    if (isSupportedImage) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreview(reader.result as string)
      }
      reader.readAsDataURL(file)
    }
  }, [file])

  const sizeInMB = file.size / 1024 / 1024
  const sizeInKB = file.size / 1024
  const fileSize = sizeInMB < 1 ? `${sizeInKB.toFixed(2)} KB` : `${sizeInMB.toFixed(2)} MB`

  const getPreviewContent = () => {
    if (file.type.startsWith('image/') && !file.type.includes('tiff') && !file.type.includes('tif') && preview) {
      return <img src={preview} alt={file.name} className="h-10 w-10 rounded object-cover" />
    }

    const getFileTypeIcon = () => {
      const extension = file.name.split('.').pop()?.toLowerCase()

      const bgColor =
        {
          pdf: 'bg-red-500',
          doc: 'bg-blue-500',
          docx: 'bg-blue-500',
          xls: 'bg-green-500',
          xlsx: 'bg-green-500',
          txt: 'bg-gray-500',
          tiff: 'bg-purple-500',
          tif: 'bg-purple-500',
        }[extension || ''] || 'bg-gray-500'

      return (
        <div className={`flex h-10 w-10 items-center justify-center rounded ${bgColor}`}>
          <span className="text-[5px] font-medium uppercase text-white">{extension || 'FILE'}</span>
        </div>
      )
    }

    return getFileTypeIcon()
  }

  return (
    <div className="flex items-center rounded-lg bg-gray-800 p-3">
      <div className="flex flex-1 items-center gap-2">
        {getPreviewContent()}
        <div className="min-w-0 flex-1">
          <p className="truncate text-sm text-light-green-50" title={file.name}>
            {truncateFilename(file.name)}
          </p>
          <p className="text-xs text-gray-400">{fileSize}</p>
        </div>
        <button
          type="button"
          onClick={onRemove}
          className="rounded-full p-1 hover:bg-gray-700"
          aria-label="Remove file"
        >
          <X className="h-4 w-4 text-gray-400" />
        </button>
      </div>
    </div>
  )
}

export default FileUploadStatus
