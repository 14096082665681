import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { PrimaryButton } from '../../atoms/buttons/PrimaryButton'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '../../atoms/dialog'
import { Input } from '../../atoms/inputs/Input'
import Typography from '../../atoms/Typography'
import { zodResolver } from '@hookform/resolvers/zod'
import { ErrorMessage } from 'src/components/atoms/ErrorMessage'
import { ProjectTags, useCreateProjectFromShape, useValidateProjectZone } from 'src/enviroment/api/services/projectsApi'
import { useNavigate } from 'react-router'
import { ROUTES } from 'src/types/routes'
import { useQueryClient } from '@tanstack/react-query'
import { IconLeaf } from '@tabler/icons-react'
import SingleFileDropzone from '../SingleFileDropzone'
import RequestProjectForm from 'src/components/molecules/RequestProjectForm'
import DropDown from 'src/components/atoms/DropDown'
import { useSearchTypes } from 'src/enviroment/api/services/typesApi'
import { useSearchStages } from 'src/enviroment/api/services/stageApi'

// Form schema for validation
const projectFormSchema = z.object({
  name: z
    .string()
    .trim()
    .min(3, { message: 'Name must be at least 3 characters' })
    .max(35, { message: 'Name cannot exceed 100 characters' }),
  shape: z.instanceof(File).nullable(),
  stageId: z.string(),
  typeIds: z.array(z.string()),
})

export type ProjectFormData = z.infer<typeof projectFormSchema>

type ProjectRequestData = {
  name: string
  shape: File
}

interface Props {
  initialFile: File | null
}

const CreateProjectDrawDialog = ({ initialFile }: Props) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [opened, setOpened] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isCheckingZone, setIsCheckingZone] = useState<boolean>(false)
  const [isZoneAvailable, setIsZoneAvailable] = useState<boolean | null>(true)
  const createProject = useCreateProjectFromShape()
  const validateZone = useValidateProjectZone()

  const { data: types } = useSearchTypes()
  const { data: stages } = useSearchStages()

  const { control, handleSubmit, reset, setValue } = useForm<ProjectFormData>({
    mode: 'onChange',
    resolver: zodResolver(projectFormSchema),
    defaultValues: {
      name: '',
      shape: initialFile,
    },
  })

  // Simple mock function to check if project can be created in this zone
  const checkProjectZoneAvailability = async (shape: File): Promise<boolean> => {
    const isAvaliable = validateZone.mutateAsync(shape)
    return isAvaliable
  }

  useEffect(() => {
    if (initialFile) {
      setValue('shape', initialFile)
      checkZoneAvailability(initialFile)
    }
  }, [initialFile, setValue])

  const checkZoneAvailability = async (file: File) => {
    setIsCheckingZone(true)
    try {
      const isAvailable = await checkProjectZoneAvailability(file)
      setIsZoneAvailable(isAvailable)
    } catch (error) {
      console.error('Error checking zone availability:', error)
      setIsZoneAvailable(false)
    } finally {
      setIsCheckingZone(false)
    }
  }

  const handleFileChange = (file: File | null) => {
    setValue('shape', file)
    if (file) {
      checkZoneAvailability(file)
    } else {
      setIsZoneAvailable(true)
    }
  }

  const handleDialogChange = (open: boolean) => {
    setOpened(open)
    if (!open) {
      reset()
    }
    // Don't reset isZoneAvailable when dialog is closed
  }

  const onSubmit = async (data: ProjectFormData) => {
    if (!data.shape) {
      console.error('File is required')
      return
    }

    const requestData: ProjectRequestData = {
      ...data,
      name: data.name,
      shape: data.shape,
    }

    try {
      setIsLoading(true)
      await createProject.mutateAsync(requestData)
      setOpened(false)
      reset()
      queryClient.invalidateQueries([ProjectTags.List])
      navigate(ROUTES.PROJECTS)
    } catch (error) {
      console.error('Failed to create project:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && e.target instanceof HTMLInputElement) {
      e.preventDefault()
      if ((e.target as HTMLInputElement).name === 'name') {
        handleSubmit(onSubmit)()
      }
    }
  }

  return (
    <Dialog open={opened} onOpenChange={handleDialogChange}>
      <DialogTrigger asChild>
        <PrimaryButton size="sm">{isZoneAvailable ? 'Create project' : 'Request Project'}</PrimaryButton>
      </DialogTrigger>

      {isZoneAvailable === false ? (
        <RequestProjectForm
          initialFile={initialFile}
          onOpenChange={handleDialogChange}
          onFileChange={handleFileChange}
          isCheckingZone={isCheckingZone}
        />
      ) : (
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              <Typography variant="2xl-nexa-bold">{isZoneAvailable ? 'Create Project' : 'Request Project'}</Typography>
              <Typography variant="base" className="mt-2">
                Name your project and upload a spatial file for your project boundary.
              </Typography>
            </DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown} className="mt-4">
            <div className="mb-2 space-y-4">
              <div>
                <Controller
                  name="name"
                  control={control}
                  render={({ field: { onChange, ...field }, formState }) => (
                    <Input
                      {...field}
                      onChange={onChange}
                      label="Project Name"
                      autoFocus
                      placeholder="New project"
                      type="text"
                      errorMessage={formState.errors.name?.message}
                    />
                  )}
                />
              </div>

              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                {stages && (
                  <Controller
                    name="stageId"
                    control={control}
                    render={({ field: { onChange, value }, formState }) => (
                      <DropDown
                        label="Stage"
                        options={stages.map((stage) => ({
                          id: stage.id,
                          label: stage.name,
                          value: stage.id,
                        }))}
                        placeholder="Select stage"
                        value={value}
                        errorMessage={formState.errors.stageId?.message}
                        onChange={onChange}
                      />
                    )}
                  />
                )}
                {/* </div> */}

                {/* <div> */}
                {types && (
                  <Controller
                    name="typeIds"
                    control={control}
                    render={({ field: { onChange, value }, formState }) => (
                      <DropDown
                        label="Types"
                        options={types.map((stage) => ({
                          id: stage.id,
                          label: stage.name,
                          value: stage.id,
                        }))}
                        multiple
                        placeholder="Select types"
                        value={value}
                        errorMessage={formState.errors.typeIds?.message}
                        onChange={onChange}
                      />
                    )}
                  />
                )}
              </div>

              <Controller
                name="shape"
                control={control}
                render={({ field: { value }, formState }) => (
                  <>
                    <SingleFileDropzone file={value} onFileChange={handleFileChange} />
                    <ErrorMessage message={formState.errors.shape?.message} />

                    {isCheckingZone && (
                      <div className="mt-2 text-center">
                        <div className="animate-pulse text-gray-600">
                          <Typography variant="sm">
                            Checking if this zone is available for project creation...
                          </Typography>
                        </div>
                      </div>
                    )}
                  </>
                )}
              />
            </div>
            <DialogFooter className="mt-4 sm:justify-end sm:space-x-6">
              {isLoading && (
                <div className="mb-4 w-full text-center">
                  <div className="animate-pulse text-gray-600">
                    <Typography variant="sm">
                      Please wait while we create your project. This process may take a few moments due to complex
                      processing...
                    </Typography>
                  </div>
                </div>
              )}
              <PrimaryButton
                type="submit"
                disabled={isLoading}
                isLoading={isLoading}
                rightIcon={<IconLeaf size={20} />}
              >
                Create Project
              </PrimaryButton>
            </DialogFooter>
          </form>
        </DialogContent>
      )}
    </Dialog>
  )
}

export default CreateProjectDrawDialog
