import { AlertCircle, Clock } from 'lucide-react'
import { IconCheck, IconLoader } from '@tabler/icons-react'
import { LayerStatus } from 'src/enviroment/api/services/layersApi'

export const getStatusIcon = (status: LayerStatus) => {
  switch (status) {
    case LayerStatus.COMPLETED:
      return <IconCheck className="h-5 w-5 text-light-green-500" stroke={2.5} />
    case LayerStatus.ERROR:
      return <AlertCircle className="h-5 w-5 text-red-500" />
    case LayerStatus.UPLOADING:
      return <IconLoader className="h-5 w-5 animate-spin text-light-green-500" />
    case LayerStatus.PROCESSING:
      return <IconLoader className="h-5 w-5 animate-spin text-blue-500" />
    default:
      return <Clock className="h-5 w-5 text-gray-600" />
  }
}
