import { FC } from 'react'
import { Tabs, TabsList, TabsTrigger, TabsContent } from 'src/components/molecules/Tabs'
import DashboardHeader from 'src/components/molecules/DashboardHeader'
import { UsersTab } from 'src/templates/dashboard/super-admin/tabs/UsersTab'
import { ProjectsTab } from 'src/templates/dashboard/super-admin/tabs/ProjectsTab'
import { WorkspacesTab } from 'src/templates/dashboard/super-admin/tabs/WorkspacesTab'
import { LibraryTab } from 'src/templates/dashboard/super-admin/tabs/LibraryTab'
import { LearningTab } from 'src/templates/dashboard/super-admin/tabs/LearningTab'

interface SuperAdminTemplateProps {
  defaultTab?: string
}

const SuperAdminTemplate: FC<SuperAdminTemplateProps> = ({ defaultTab = 'pending-users' }) => {
  return (
    <div className="flex h-full flex-col">
      <DashboardHeader title="Platform Admin" />

      <div className="flex-1 p-6">
        <Tabs defaultValue={defaultTab} className="w-full">
          <TabsList className="h-15 ">
            <TabsTrigger className="h-10" value="pending-users">
              Users
            </TabsTrigger>
            <TabsTrigger className="h-10" value="projects">
              Projects
            </TabsTrigger>
            <TabsTrigger className="h-10" value="workspaces">
              Workspaces
            </TabsTrigger>
            <TabsTrigger className="h-10" value="library">
              Library
            </TabsTrigger>
            <TabsTrigger className="h-10" value="learning">
              Learning
            </TabsTrigger>
          </TabsList>

          <TabsContent value="pending-users">
            <UsersTab />
          </TabsContent>

          <TabsContent value="projects">
            <ProjectsTab />
          </TabsContent>

          <TabsContent value="workspaces">
            <WorkspacesTab />
          </TabsContent>

          <TabsContent value="library">
            <LibraryTab />
          </TabsContent>

          <TabsContent value="learning">
            <LearningTab />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  )
}

export default SuperAdminTemplate
