import { InfiniteData, useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { client } from '../axios'
import { Member } from 'src/templates/members/columns'
import { UserQueryKeys } from './userApi'
import { STORAGE_KEYS } from 'src/enviroment/constants/storageKeys'
import { useTenantStore } from 'src/enviroment/store/tenentStore'
import { UserRole } from 'src/types/user'
import { enqueueSnackbar } from 'notistack'

export enum MembersTags {
  List = 'MembersList',
}

const take = 20

export interface IPayloadCreateMember {
  email: string
  role: UserRole
  projectIds?: string[]
}

export function useMembersQuery() {
  const queryClient = useQueryClient()
  const currentTenantId = useTenantStore((state) => state.currentTenant?.id)

  return useInfiniteQuery({
    queryKey: [MembersTags.List],
    queryFn: async ({ pageParam: cursorId }) => {
      // Guard against missing user data

      const response = await client.get<Member[]>(`/tenants/${currentTenantId}/members`, {
        params: { cursorId, take },
      })
      return response.data
    },
    enabled: Boolean(queryClient.getQueryData([UserQueryKeys.User])),
    getNextPageParam: (lastPage) => lastPage.at(-1)?.id,
  })
}
export function useCreateMemberMutation() {
  const queryClient = useQueryClient()
  const currentTenantId = useTenantStore((state) => state.currentTenant?.id)

  return useMutation({
    mutationFn: (data: IPayloadCreateMember) => {
      return client.post(`/tenants/${currentTenantId}/members`, data)
    },
    onSettled: () => {
      localStorage.removeItem(STORAGE_KEYS.INVITE_TOKEN)
      queryClient.invalidateQueries({ queryKey: [MembersTags.List] })
    },
    onSuccess: () => {
      enqueueSnackbar('Member invited successfully', { variant: 'success' })
    },
  })
}

export function useEditMemberMutation() {
  const queryClient = useQueryClient()
  const currentTenantId = useTenantStore((state) => state.currentTenant?.id)

  return useMutation({
    mutationFn: (data: { id: string; firstName: string; lastName: string; role: UserRole }) => {
      return client.patch(`/tenants/${currentTenantId}/members/${data.id}`, data)
    },
    // onMutate: async (data) => {
    //   await queryClient.cancelQueries({ queryKey: [MembersTags.List] })

    //   const previousMembers = queryClient.getQueryData<Member[]>([MembersTags.List])

    //   queryClient.setQueryData<Member[]>([MembersTags.List], (oldMembers) =>
    //     oldMembers?.map((m) => (m.id === data.id ? { ...m, ...data } : m)),
    //   )

    //   return { previousMembers }
    // },
    // onError: (_, __, ctx) => {
    //   queryClient.setQueryData<Member[]>([MembersTags.List], ctx?.previousMembers)
    // },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [MembersTags.List] })
    },
  })
}

export function useDeleteAllMemberMutation() {
  const queryClient = useQueryClient()
  const currentTenantId = useTenantStore((state) => state.currentTenant?.id)

  return useMutation({
    mutationFn: () => {
      return client.delete(`/tenants/${currentTenantId}/members`)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [MembersTags.List] })
    },
  })
}

export function useDeleteMemberMutation() {
  const queryClient = useQueryClient()
  const currentTenantId = useTenantStore((state) => state.currentTenant?.id)

  return useMutation({
    mutationFn: (userId: string) => {
      return client.delete(`/tenants/${currentTenantId}/members/${userId}`)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [MembersTags.List] })
    },
  })
}

interface UpdateRoleData {
  userId: string
  role: UserRole
}

interface UpdateRoleResponse {
  status: number
  data: {
    message: string
  }
}

interface MutationContext {
  previousData: InfiniteData<Member[]> | undefined
}

export function useUpdateMemberRoleMutation() {
  const queryClient = useQueryClient()
  const currentTenantId = useTenantStore((state) => state.currentTenant?.id)

  return useMutation<UpdateRoleResponse, Error, UpdateRoleData, MutationContext>({
    mutationFn: ({ userId, role }) => {
      return client.patch(`/tenants/${currentTenantId}/members/${userId}/role`, { role })
    },
    onMutate: async ({ userId, role }) => {
      await queryClient.cancelQueries({ queryKey: [MembersTags.List] })

      const previousData = queryClient.getQueryData<InfiniteData<Member[]>>([MembersTags.List])
      // Optimistically update to the new value
      queryClient.setQueryData<InfiniteData<Member[]>>([MembersTags.List], (old) => {
        if (!old) return { pages: [], pageParams: [] }

        return {
          ...old,
          pages: old.pages.map((page: Member[]) =>
            page.map((member: Member) => (member.id === userId ? { ...member, role: role } : member)),
          ),
        }
      })

      return { previousData }
    },
    onError: (error, variables, context) => {
      // Roll back to the previous value on error
      if (context?.previousData) {
        queryClient.setQueryData<InfiniteData<Member[]>>([MembersTags.List], context.previousData)
      }
    },
    onSettled: () => {
      // Always refetch after error or success
      queryClient.invalidateQueries({ queryKey: [MembersTags.List] })
    },
  })
}
