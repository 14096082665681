interface CompanyLogoProps {
  logoUrl: string
  altText?: string
  isCollapsed?: boolean
  maxHeight?: string
  className?: string
}

const CompanyLogo: React.FC<CompanyLogoProps> = ({
  logoUrl,
  altText = 'Company logo',
  isCollapsed = false,
  maxHeight = '80px',
  className = '',
}) => {
  /** Обробник помилок завантаження зображення */
  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>): void => {
    const target = e.target as HTMLImageElement
    target.style.display = 'none'
    console.error('Failed to load logo:', logoUrl)
  }

  return (
    <div
      className={`
        relative
        mb-2
        mr-2
        flex
        h-[100px] 
        w-full
        items-center
        justify-center
        overflow-hidden
        ${className}
      `}
    >
      <div
        className="
          relative
          flex
          h-full
          w-auto
          items-center
          justify-center
        "
      >
        <img
          src={logoUrl}
          alt={altText}
          className="
              max-h-[100px]
              w-auto
              object-contain
              transition-all
              duration-200
              ease-in-out
              hover:opacity-90
            "
          style={{
            maxHeight,
          }}
          onError={handleImageError}
        />
      </div>
    </div>
  )
}

export default CompanyLogo
