import React, { useEffect } from 'react'
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/components/molecules/Table'
import { useInView } from 'react-intersection-observer'
import { Loader } from 'lucide-react'
import { JumpingDots } from 'src/components/atoms/JumpingDots'

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  isLoading?: boolean
  isFetchingNextPage?: boolean
  hasNextPage?: boolean
  onLoadMore?: () => void
}

function DataTable<TData, TValue>({
  columns,
  data,
  isLoading,
  isFetchingNextPage,
  hasNextPage,
  onLoadMore,
}: DataTableProps<TData, TValue>) {
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '100px',
  })

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage && onLoadMore) {
      onLoadMore()
    }
  }, [inView, hasNextPage, isFetchingNextPage, onLoadMore])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <div className="relative max-h-[600px] overflow-y-auto">
      <Table>
        <TableHeader className="sticky top-0 bg-gray-800">
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                {isLoading ? (
                  <Loader className=" mx-auto h-6 w-6 animate-spin text-light-green-600" size={32} />
                ) : (
                  'No results.'
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      {/* Loader trigger */}
      {hasNextPage && (
        <div ref={ref} className="h-20 w-full">
          {isFetchingNextPage && <JumpingDots className="text-light-green-50" />}
        </div>
      )}
    </div>
  )
}

export default DataTable
