import { zodResolver } from '@hookform/resolvers/zod'
import { IconPlus } from '@tabler/icons-react'
import { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { PrimaryButton } from '../../atoms/buttons/PrimaryButton'
import { SecondaryButton } from '../../atoms/buttons/SecondaryButton'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '../../atoms/dialog'
import { Input } from '../../atoms/inputs/Input'
import Typography from '../../atoms/Typography'
import FileUploadContainer from '../../organisms/FileUploadContainer'
import { useCreateLearningMaterial } from 'src/enviroment/api/services/superAdminApi'

const AddMaterialSchema = z.object({
  name: z
    .string()
    .trim()
    .min(3, { message: 'Name must be at least 3 characters' })
    .max(50, { message: 'Name must be at most 50 characters' }),
  type: z
    .string()
    .trim()
    .min(2, { message: 'Type must be at least 2 characters' })
    .max(35, { message: 'Type must be at most 35 characters' }),
})

type AddMaterialFormData = z.infer<typeof AddMaterialSchema>

const defaultValues: AddMaterialFormData = {
  name: '',
  type: '',
}

const AddMaterialDialog = () => {
  const [opened, setOpened] = useState(false)
  const [file, setFile] = useState<File | null>(null)

  const handleClose = useCallback(() => {
    setOpened(false)
    setFile(null)
  }, [])

  const form = useForm<AddMaterialFormData>({
    mode: 'all',
    defaultValues,
    resolver: zodResolver(AddMaterialSchema),
  })

  const { mutateAsync: createMaterial, isLoading } = useCreateLearningMaterial()

  const handleFileSelect = (selectedFile: File) => {
    setFile(selectedFile)
  }

  const handleFileRemove = () => {
    setFile(null)
  }

  const handleSubmit = useCallback(
    async (data: AddMaterialFormData) => {
      if (!file) return

      await createMaterial({
        name: data.name,
        type: data.type,
        file,
      })

      setOpened(false)
      setFile(null)
      form.reset()
    },
    [file, createMaterial, form],
  )

  return (
    <Dialog open={opened} onOpenChange={setOpened}>
      <DialogTrigger asChild>
        <SecondaryButton className="flex-shrink-0" leftIcon={<IconPlus />}>
          Add Material
        </SecondaryButton>
      </DialogTrigger>
      <DialogContent aria-description="add-material-dialog">
        <DialogHeader>
          <DialogTitle>
            <Typography variant="2xl-nexa-bold">Add Learning Material</Typography>
          </DialogTitle>
        </DialogHeader>
        <div className="grid gap-6">
          <div>
            <Controller
              name="name"
              control={form.control}
              render={({ field, formState }) => (
                <Input
                  label="Name"
                  placeholder="Enter material name"
                  disabled={isLoading}
                  errorMessage={formState.errors.name?.message}
                  {...field}
                />
              )}
            />
          </div>
          <div>
            <Controller
              name="type"
              control={form.control}
              render={({ field, formState }) => (
                <Input
                  label="Type"
                  placeholder="Enter material type"
                  disabled={isLoading}
                  errorMessage={formState.errors.type?.message}
                  {...field}
                />
              )}
            />
          </div>
          <div>
            <FileUploadContainer
              file={file}
              onFileSelect={handleFileSelect}
              onFileRemove={handleFileRemove}
              accept=".pdf,.mp4"
            />
          </div>
        </div>
        <DialogFooter className="sm:justify-center sm:space-x-6">
          <SecondaryButton onClick={handleClose} className="w-40" disabled={isLoading}>
            Back
          </SecondaryButton>
          <PrimaryButton
            onClick={form.handleSubmit(handleSubmit)}
            isLoading={isLoading}
            disabled={isLoading || !file}
            className="w-40"
          >
            Create
          </PrimaryButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default AddMaterialDialog
