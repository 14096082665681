import { FC, useCallback, useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { z } from 'zod'
import { IconPencil, IconSearch, IconCopy, IconCheck, IconChevronDown, IconChevronUp } from '@tabler/icons-react'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter, DialogTrigger } from '../../atoms/dialog'
import { Input } from '../../atoms/inputs/Input'
import { Checkbox } from '../../atoms/buttons/CheckBoxButton'
import { RadioGroup, RadioGroupItem } from '../../atoms/radio-group'
import { Label } from '../../atoms/label'
import { PrimaryButton } from '../../atoms/buttons/PrimaryButton'
import { SecondaryButton } from '../../atoms/buttons/SecondaryButton'
import Typography from '../../atoms/Typography'
import { UserRole } from 'src/types/user'
import { useEditUser, useGetDetailsForEditUser } from 'src/enviroment/api/services/superAdminApi'
import { zodResolver } from '@hookform/resolvers/zod'

const editUserSchema = z.object({
  firstName: z.string().trim().min(1).max(32),
  lastName: z.string().trim().min(1).max(32),
  tenants: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      selected: z.boolean(),
      role: z.nativeEnum(UserRole).nullable(),
    }),
  ),
})

type EditUserFormData = z.infer<typeof editUserSchema>

interface Props {
  userId: string
}

const EditUserDialog: FC<Props> = ({ userId }) => {
  const [opened, setOpened] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [copiedId, setCopiedId] = useState<string | null>(null)
  const [expandedTenantId, setExpandedTenantId] = useState<string | null>(null)

  const handleClose = useCallback(() => setOpened(false), [])
  const { data: userData, isLoading } = useGetDetailsForEditUser(userId, opened)
  const updateUser = useEditUser(userId)

  const form = useForm<EditUserFormData>({
    mode: 'all',
    values: userData
      ? {
          firstName: userData.firstName,
          lastName: userData.lastName,
          tenants: userData.tenants,
        }
      : undefined,
    resolver: zodResolver(editUserSchema),
  })

  const selectedTenants = form.watch('tenants') || []
  const filteredTenants = selectedTenants.filter(
    (tenant) =>
      tenant.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      tenant.id.toLowerCase().includes(searchQuery.toLowerCase()),
  )

  const handleSubmit: SubmitHandler<EditUserFormData> = useCallback(
    (data) => {
      const filteredData = {
        ...data,
        tenants: data.tenants.filter((tenant) => tenant.selected),
      }
      updateUser.mutate(filteredData)
      setOpened(false)
    },
    [updateUser],
  )

  const copyToClipboard = async (id: string, e: React.MouseEvent) => {
    e.preventDefault() // Add preventDefault
    e.stopPropagation()
    try {
      await navigator.clipboard.writeText(id)
      setCopiedId(id)
      setTimeout(() => setCopiedId(null), 2000)
    } catch (err) {
      console.error('Failed to copy:', err)
    }
  }

  const handleTenantClick = (tenantId: string, e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setExpandedTenantId(expandedTenantId === tenantId ? null : tenantId)
  }

  const handleTenantSelection = (tenant: EditUserFormData['tenants'][0], e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    const newTenants = selectedTenants.map((t) =>
      t.id === tenant.id ? { ...t, selected: !t.selected, role: !t.selected ? UserRole.VIEWER : t.role } : t,
    )
    form.setValue('tenants', newTenants)
  }

  return (
    <Dialog open={opened} onOpenChange={setOpened}>
      <DialogTrigger className="flex h-11 w-11 items-center justify-center rounded-[8px] text-light-green-500 transition-all hover:cursor-pointer hover:bg-light-green-500 hover:text-white active:scale-95">
        <IconPencil size={24} />
      </DialogTrigger>

      <DialogContent className="sm:max-w-[700px]">
        <DialogHeader>
          <DialogTitle>
            <Typography variant="2xl-nexa-bold">Edit Member</Typography>
          </DialogTitle>
        </DialogHeader>

        {isLoading ? (
          <div className="flex justify-center p-6">
            <Typography variant="base" className="text-gray-400">
              Loading...
            </Typography>
          </div>
        ) : userData ? (
          <form onSubmit={form.handleSubmit(handleSubmit)} className="flex flex-col gap-4">
            <div className="space-y-4">
              <div className="space-y-2">
                <Label className="text-white">Name</Label>
                <div className="flex gap-4">
                  <Input
                    disabled={form.formState.isSubmitting}
                    autoComplete="off"
                    className="h-12 rounded-lg border-0 bg-gray-800 text-white"
                    placeholder="First Name"
                    errorMessage={form.formState.errors.firstName?.message}
                    {...form.register('firstName')}
                  />
                  <Input
                    disabled={form.formState.isSubmitting}
                    autoComplete="off"
                    className="h-12 rounded-lg border-0 bg-gray-800 text-white"
                    placeholder="Last Name"
                    errorMessage={form.formState.errors.lastName?.message}
                    {...form.register('lastName')}
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <Typography variant="sm" className="text-gray-400">
                    Total Workspaces: {selectedTenants.length}
                  </Typography>
                  <Typography variant="sm" className="text-gray-400">
                    |
                  </Typography>
                  <Typography variant="sm" className="text-gray-400">
                    Selected: {selectedTenants.filter((t) => t.selected).length}
                  </Typography>
                </div>
              </div>
            </div>

            <div className="w-full rounded-lg bg-gray-900 p-4">
              <div className="mb-4">
                <Input
                  placeholder="Search workspaces by name..."
                  value={searchQuery}
                  icon={<IconSearch size={20} />}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="bg-gray-800"
                />
              </div>

              <div className="rounded-lg border border-gray-700 bg-gray-800">
                <div className="max-h-[350px] overflow-y-auto">
                  {filteredTenants.map((tenant) => (
                    <div key={tenant.id}>
                      <div className="flex items-center justify-between border-b border-gray-700 p-3">
                        <div className="flex flex-1 items-center gap-3">
                          <Checkbox
                            id={`checkbox-${tenant.id}`}
                            checked={tenant.selected}
                            className="border-light-green-500"
                            onClick={(e) => handleTenantSelection(tenant, e)}
                          />
                          <div className="flex-1">
                            <Label htmlFor={`checkbox-${tenant.id}`} className="block font-medium text-gray-50">
                              {tenant.name}
                            </Label>
                            <div className="flex items-center gap-2">
                              <span className="text-sm text-gray-400">{tenant.id}</span>
                              <button
                                type="button"
                                className="text-gray-400 hover:text-gray-300"
                                onClick={(e) => copyToClipboard(tenant.id, e)}
                              >
                                {copiedId === tenant.id ? (
                                  <IconCheck size={14} className="text-green-500" />
                                ) : (
                                  <IconCopy size={14} />
                                )}
                              </button>
                            </div>
                          </div>
                          {tenant.selected && (
                            <button
                              type="button"
                              onClick={(e) => handleTenantClick(tenant.id, e)}
                              className="ml-2 rounded-md p-2 transition-colors hover:bg-gray-700"
                            >
                              {expandedTenantId === tenant.id ? (
                                <IconChevronUp size={20} className="text-gray-400" />
                              ) : (
                                <IconChevronDown size={20} className="text-gray-400" />
                              )}
                            </button>
                          )}
                        </div>
                      </div>
                      {expandedTenantId === tenant.id && tenant.selected && (
                        <div className="border-b border-gray-700 bg-gray-800 px-5 py-5">
                          <div className="flex w-full justify-start">
                            <RadioGroup
                              value={tenant.role || ''}
                              onValueChange={(value) => {
                                const newTenants = selectedTenants.map((t) =>
                                  t.id === tenant.id ? { ...t, role: value as UserRole } : t,
                                )
                                form.setValue('tenants', newTenants)
                              }}
                              className="flex flex-row items-center gap-4"
                            >
                              {Object.values(UserRole).map((role) => (
                                <div key={role} className="flex items-center">
                                  <RadioGroupItem
                                    value={role}
                                    id={`${tenant.id}-${role}`}
                                    className="border-light-green-500"
                                  />
                                  <Label htmlFor={`${tenant.id}-${role}`} className="ml-2 capitalize text-gray-400">
                                    {role.toLowerCase()}
                                  </Label>
                                </div>
                              ))}
                            </RadioGroup>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <DialogFooter className="sm:justify-end sm:space-x-4">
              <SecondaryButton type="button" onClick={handleClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton type="submit" isLoading={form.formState.isSubmitting}>
                Save Changes
              </PrimaryButton>
            </DialogFooter>
          </form>
        ) : null}
      </DialogContent>
    </Dialog>
  )
}

export default EditUserDialog
