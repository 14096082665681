import { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { Project } from 'src/types/projects'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { createPopupContent } from './createPopupContent'
import { useNavigate } from 'react-router'
import { DrawnAreasState } from 'src/types/mapbox'
import * as turf from '@turf/turf'
import { stylesDrawPolygon } from 'src/enviroment/constants/constants'
import { VerticalToolbar } from 'src/components/molecules/VerticalToolBar'
import { MapControlBar } from 'src/components/molecules/MapControlBar'
import { zoomToPercent } from 'src/enviroment/lib/utils'
import DrawingMapTip from 'src/components/molecules/DrawingMapTip'
import { AreasInfo } from './draw/AreasInfo'
import MapDiagnostics from 'src/components/molecules/MapDiagnostic'
import Joyride, { STATUS, CallBackProps as JoyrideCallbackProps } from 'react-joyride'
import { steps, stylesJoyride } from 'src/enviroment/constants/guides'

interface MapboxMapProps {
  initialOptions?: Omit<mapboxgl.MapOptions, 'container'>
  onCreated?(map: mapboxgl.Map): void
  onLoaded?(map: mapboxgl.Map): void
  onRemoved?(): void
  projects: Project[] | undefined
  onAreaCreated?: (features: GeoJSON.Feature[]) => void
  setComponent: (component: JSX.Element | null) => void
  refetch: () => void
}

function ReworkMapBox({
  initialOptions = {},
  onCreated,
  onLoaded,
  projects,
  onRemoved,
  refetch,
  setComponent,
  onAreaCreated,
}: MapboxMapProps) {
  const navigate = useNavigate()
  const [map, setMap] = useState<mapboxgl.Map>()
  const [draw, setDraw] = useState<MapboxDraw>()
  const [runTour, setRunTour] = useState<boolean>(false)

  const mapNode = useRef(null)
  const popupRef = useRef<mapboxgl.Popup | null>(null)

  const [isDrawing, setIsDrawing] = useState(false)
  const [hasSelectedFeatures, setHasSelectedFeatures] = useState(false)
  const [drawnAreas, setDrawnAreas] = useState<DrawnAreasState | null>(null)
  const [isProjectsSourceLoaded, setIsProjectsSourceLoaded] = useState(false)
  const [isStyleLoaded, setIsStyleLoaded] = useState(false)
  const [isPanMode, setIsPanMode] = useState(false)
  const [zoomLevel, setZoomLevel] = useState(2)

  useEffect(() => {
    if (isStyleLoaded && isProjectsSourceLoaded && map && onLoaded) {
      onLoaded(map)
    }
  }, [isStyleLoaded, isProjectsSourceLoaded, map, onLoaded])

  useEffect(() => {
    // Check if user has seen the guide before
    const hasSeenGuide = localStorage.getItem('hasSeenMapToolsGuide')

    if (!hasSeenGuide && isStyleLoaded) {
      setRunTour(true)
      localStorage.setItem('hasSeenMapToolsGuide', 'true')
    }
  }, [isStyleLoaded])

  const handleJoyrideCallback = (data: JoyrideCallbackProps) => {
    const { status } = data
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      setRunTour(false)
    }
  }

  const calculateTotalArea = useCallback((features: GeoJSON.Feature[]) => {
    return features.reduce((total, feature) => total + turf.area(feature), 0) / 10000
  }, [])

  const updateAreas = useCallback(() => {
    if (!draw) return

    const allFeatures = draw.getAll().features
    if (allFeatures.length === 0) {
      setDrawnAreas(null)
      setHasSelectedFeatures(false)
      return
    }

    setDrawnAreas({
      features: allFeatures,
      totalHectares: calculateTotalArea(allFeatures),
    })
    setIsDrawing(false)
  }, [draw, calculateTotalArea])

  const actions = useMemo(
    () => ({
      startDrawing: () => {
        if (!draw) return
        draw.changeMode('draw_polygon')
        setIsDrawing(true)
      },

      deleteSelected: () => {
        if (!draw) return
        const selectedIds = draw.getSelectedIds()
        if (selectedIds.length > 0) {
          draw.delete(selectedIds)
          updateAreas()
        }
      },

      deleteAll: () => {
        if (!draw) return
        draw.deleteAll()
        updateAreas()
      },

      handleCreate: () => {
        if (drawnAreas?.features.length) {
          onAreaCreated?.(drawnAreas.features)
          setDrawnAreas(null)
          draw?.deleteAll()
        }
      },

      getDrawnAreasFile: () => {
        if (!draw) return null
        const features = draw.getAll()
        if (features.features.length === 0) return null

        return new File([JSON.stringify(features)], 'new-project.geojson', {
          type: 'application/json',
        })
      },

      downloadAreas: () => {
        if (!draw) return
        const features = draw.getAll()
        if (features.features.length === 0) return

        const blob = new Blob([JSON.stringify(features)], { type: 'application/json' })
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = 'new-project.geojson'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(url)
      },
    }),
    [draw, drawnAreas, onAreaCreated, updateAreas],
  )

  const getProjectFeatures = useCallback((projectsData: Project[] = []) => {
    return projectsData
      .filter((project) => project && typeof project.longitude === 'number' && typeof project.latitude === 'number')
      .map((project) => ({
        type: 'Feature' as const,
        geometry: {
          type: 'Point' as const,
          coordinates: [project.longitude, project.latitude],
        },
        properties: project,
      }))
  }, [])

  const setupEventListeners = useCallback(
    (map: mapboxgl.Map) => {
      map.on('mouseenter', 'clusters', () => {
        map.getCanvas().style.cursor = 'pointer'
      })

      map.on('mouseleave', 'clusters', () => {
        map.getCanvas().style.cursor = ''
      })

      map.on('mouseenter', 'unclustered-point', (e) => {
        if (!e.features?.[0]) return

        const feature = e.features[0]
        const coordinates = (
          feature.geometry as { type: 'Point'; coordinates: [number, number] }
        ).coordinates.slice() as [number, number]
        const project = feature.properties as unknown as Project

        map.getCanvas().style.cursor = 'pointer'

        if (popupRef.current) {
          popupRef.current.remove()
        }

        popupRef.current = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
          maxWidth: '300px',
          className: 'project-popup',
        })
          .setLngLat(coordinates)
          .setDOMContent(createPopupContent(project))
          .addTo(map)
      })

      map.on('mouseleave', 'unclustered-point', (e: any) => {
        const toElement = e.toElement || e.relatedTarget
        if (popupRef.current?.getElement()?.contains(toElement as Node)) {
          return
        }

        map.getCanvas().style.cursor = ''
        if (popupRef.current) {
          popupRef.current.remove()
          popupRef.current = null
        }
      })

      map.on('click', 'unclustered-point', (e) => {
        if (!e.features?.[0]) return
        const project = e.features[0].properties as unknown as Project
        navigate(`/dashboard/project/${project.id}`)
      })

      map.on('zoom', () => {
        if (map) {
          const newZoom = map.getZoom()
          setZoomLevel(zoomToPercent(newZoom))
        }
      })

      map.on('click', 'clusters', (e) => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ['clusters'],
        })

        const clickedFeature = features[0]
        const clusterId = clickedFeature.properties?.cluster_id

        const source = map.getSource('projects') as mapboxgl.GeoJSONSource
        source.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return

          const coordinates = (clickedFeature.geometry as any).coordinates.slice() as [number, number]

          map.easeTo({
            center: coordinates,
            zoom: zoom || map.getZoom() + 1,
            duration: 1500,
            easing: (t) => t * (2 - t),
          })
        })
      })
    },
    [navigate],
  )

  const setupDrawingEvents = useCallback(
    (mapboxMap: mapboxgl.Map) => {
      if (!draw) return

      mapboxMap.on('draw.create', () => {
        updateAreas()
      })

      mapboxMap.on('draw.update', () => {
        updateAreas()
      })

      mapboxMap.on('draw.delete', () => {
        updateAreas()
      })

      mapboxMap.on('draw.selectionchange', (e: { features: any[] }) => {
        setHasSelectedFeatures(e.features.length > 0)
      })

      mapboxMap.on('draw.modechange', () => {
        const mode = draw.getMode()
        setIsDrawing(mode === 'draw_polygon')
        if (mode !== 'draw_polygon') {
          mapboxMap.getCanvas().style.cursor = ''
        }
      })
    },
    [draw, updateAreas],
  )

  const setupLayers = useCallback(
    (map: mapboxgl.Map) => {
      map.addSource('projects', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: getProjectFeatures(projects),
        },
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50,
      })

      map.on('sourcedata', (e) => {
        if (e.sourceId === 'projects' && e.isSourceLoaded) {
          setIsProjectsSourceLoaded(true)
        }
      })

      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'projects',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': '#2e846a',
          'circle-radius': ['step', ['get', 'point_count'], 20, 5, 30, 10, 40],
        },
      })

      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'projects',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
        paint: {
          'text-color': '#F5F6F6',
        },
      })

      map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'projects',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#2e846a',
          'circle-radius': 10,
          'circle-stroke-width': 2,
          'circle-stroke-color': '#fff',
        },
      })
    },
    [getProjectFeatures, projects],
  )

  const updateProjectsSource = useCallback(() => {
    if (!map) return

    const source = map.getSource('projects') as mapboxgl.GeoJSONSource
    if (source) {
      source.setData({
        type: 'FeatureCollection',
        features: getProjectFeatures(projects || []),
      })
    }
  }, [map, getProjectFeatures, projects])

  useEffect(() => {
    if (!map) return

    const interval = setInterval(() => {
      updateProjectsSource()
    }, 2 * 60 * 1000)

    return () => clearInterval(interval)
  }, [map, updateProjectsSource])

  useEffect(() => {
    const node = mapNode.current

    if (typeof window === 'undefined' || node === null || map) return

    const mapboxMap = new mapboxgl.Map({
      container: node,
      accessToken: import.meta.env.VITE_APP_MAPBOX_ACCESS_TOKEN,
      style: 'mapbox://styles/mapbox/dark-v11',
      center: [0, 0],
      zoom: 2,
      projection: 'globe',
      attributionControl: false,
      logoPosition: 'bottom',
      ...initialOptions,
    })

    const mapboxDraw = new MapboxDraw({
      displayControlsDefault: false,
      styles: stylesDrawPolygon,
      controls: {
        polygon: false,
        trash: false,
        point: false,
        line_string: false,
      },
    })
    mapboxMap.addControl(mapboxDraw)

    setMap(mapboxMap)
    setDraw(mapboxDraw)

    if (onCreated) onCreated(mapboxMap)

    mapboxMap.on('style.load', () => {
      setIsStyleLoaded(true)
      setupLayers(mapboxMap)
      setupEventListeners(mapboxMap)
      if (draw) {
        setupDrawingEvents(mapboxMap)
      }

      if (projects?.length) {
        const bounds = new mapboxgl.LngLatBounds()
        projects.forEach((project) => {
          bounds.extend([project.longitude, project.latitude])
        })

        mapboxMap.fitBounds(bounds, {
          padding: 50,
          maxZoom: 10,
        })
      }
    })

    return () => {
      if (popupRef.current) {
        popupRef.current.remove()
        popupRef.current = null
      }
      mapboxMap.remove()
      setMap(undefined)
      setDraw(undefined)

      if (onRemoved) onRemoved()
    }
  }, [])

  useEffect(() => {
    updateProjectsSource()
  }, [projects, updateProjectsSource])

  useEffect(() => {
    if (map && draw) {
      setupDrawingEvents(map)
    }
  }, [map, draw, setupDrawingEvents])

  useEffect(() => {
    if (drawnAreas && drawnAreas.features.length > 0) {
      const file = actions.getDrawnAreasFile()
      setComponent(<AreasInfo drawnAreas={drawnAreas} file={file} />)
    } else {
      setComponent(null)
    }
  }, [actions, actions.getDrawnAreasFile, drawnAreas, setComponent])

  const handlePanMode = () => {
    setIsPanMode((prev) => !prev)
    if (map) {
      map.getCanvas().style.cursor = !isPanMode ? 'grab' : ''

      if (!isPanMode) {
        map.scrollZoom.disable()
      } else {
        map.scrollZoom.enable()
      }
    }
  }
  return (
    <>
      <style>
        {`
          .project-popup .mapboxgl-popup-content {
            background-color: rgba(0, 0, 0, 0.8);
            color: white;
            border-radius: 8px;
            padding: 12px;
            }
            .project-popup .mapboxgl-popup-tip {
              border-top-color: rgba(0, 0, 0, 0.8);
              }
              `}
      </style>

      <Joyride
        run={runTour}
        steps={steps}
        continuous={true}
        showSkipButton={true}
        showProgress={true}
        locale={{
          last: 'Done',
          skip: 'Skip',
          next: 'Next',
          back: 'Back',
        }}
        styles={stylesJoyride}
        callback={handleJoyrideCallback}
      />

      <div className="relative h-full">
        {import.meta.env.VITE_APP_MODE === 'development' && (
          <div className="absolute bottom-16 left-5 z-10">
            <MapDiagnostics
              map={map}
              projects={projects}
              onForceRefresh={() => refetch()}
              updateProjectsSource={() => updateProjectsSource()}
            />
          </div>
        )}
        <div className="absolute right-5 top-[150px] z-10">
          <VerticalToolbar
            isDrawing={isDrawing}
            onPencil={() => (isDrawing ? draw?.trash() : actions.startDrawing())}
            onDownload={actions.downloadAreas}
            hasSelectedFeatures={hasSelectedFeatures}
            onEraser={actions.deleteSelected}
            onDelete={actions.deleteAll}
            isGuideTourActive={false}
          />
        </div>
        <div className=" absolute bottom-10 right-[44%] z-10">
          <MapControlBar
            zoomLevel={zoomLevel}
            onZoomIn={() => map?.zoomIn()}
            onZoomOut={() => map?.zoomOut()}
            onPanMode={handlePanMode}
            isPanMode={isPanMode}
          />
        </div>
        <div className="absolute left-1/2 top-20 z-20 -translate-x-1/2 transform"></div>
        <div className="absolute bottom-5 left-4 z-10 flex">
          <DrawingMapTip isDrawing={isDrawing} />
        </div>
        <div ref={mapNode} className="h-full w-full" />
      </div>
    </>
  )
}

export default ReworkMapBox
