import { useCallback, useRef, useEffect, useState } from 'react'
import { Editor, Monaco } from '@monaco-editor/react'
import { editor } from 'monaco-editor'

interface JSONEditorProps {
  /**
   * Initial value for the editor
   */
  value?: string
  /**
   * Height of the editor
   */
  height?: string
  /**
   * Whether the editor is disabled
   */
  isDisabled?: boolean
  /**
   * Callback when content changes
   */
  onChange?: (value: string) => void
  /**
   * Additional editor options
   */
  editorOptions?: editor.IStandaloneEditorConstructionOptions
}

/**
 * Universal JSON Editor component that can be reused across the application
 */
const JSONEditor = ({
  value = '',
  height = '300px',
  isDisabled = false,
  onChange,
  editorOptions = {},
}: JSONEditorProps): JSX.Element => {
  const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null)
  const monacoRef = useRef<Monaco | null>(null)
  const [isFormatted, setIsFormatted] = useState(false)

  /**
   * Updates editor value if external value changes
   */
  useEffect(() => {
    if (editorRef.current && value !== editorRef.current.getValue()) {
      editorRef.current.setValue(value)
    }
  }, [value])

  /**
   * Format the JSON in the editor
   */
  const formatJSON = useCallback(() => {
    if (editorRef.current && monacoRef.current) {
      editorRef.current.getAction('editor.action.formatDocument')?.run()
      setIsFormatted(true)
    }
  }, [])

  /**
   * Sets up editor when it mounts
   */
  const handleEditorDidMount = useCallback(
    (editor: editor.IStandaloneCodeEditor, monaco: Monaco): void => {
      editorRef.current = editor
      monacoRef.current = monaco

      // Ensure dark theme is applied immediately
      monaco.editor.setTheme('vs-dark')

      // Set read-only state based on isDisabled prop
      editor.updateOptions({ readOnly: isDisabled })

      // Format the document initially
      setTimeout(() => {
        formatJSON()
      }, 100)
    },
    [isDisabled, formatJSON],
  )

  /**
   * Handles content changes
   */
  const handleEditorChange = useCallback(
    (value: string | undefined): void => {
      onChange?.(value || '')
      setIsFormatted(false)
    },
    [onChange],
  )

  /**
   * Configures editor before it mounts
   */
  const handleEditorWillMount = useCallback((monaco: Monaco): void => {
    // Define theme explicitly
    monaco.editor.defineTheme('vs-dark', {
      base: 'vs-dark',
      inherit: true,
      rules: [],
      colors: {},
    })

    // Configure JSON language settings
    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      allowComments: true,
      schemas: [],
    })
  }, [])

  // Default editor options
  const defaultOptions: editor.IStandaloneEditorConstructionOptions = {
    minimap: { enabled: false },
    fontSize: 13,
    theme: 'vs-dark',
    scrollBeyondLastLine: false,
    automaticLayout: true,
    formatOnPaste: true,
    formatOnType: true,
    tabSize: 2,
    insertSpaces: true,
    detectIndentation: false,
    // Remove or set to 'on' to enable validation decorations
    renderValidationDecorations: 'on',
    readOnly: isDisabled,
  }

  return (
    <div className="flex w-full flex-col">
      <div style={{ height, width: '100%' }} className="overflow-hidden">
        <Editor
          height="100%"
          language="json"
          defaultValue={value}
          onChange={handleEditorChange}
          onMount={handleEditorDidMount}
          beforeMount={handleEditorWillMount}
          options={{ ...defaultOptions, ...editorOptions }}
        />
      </div>
      {!isDisabled && (
        <div className="mt-2 flex justify-end">
          <button
            className="rounded bg-light-green-500 px-3 py-1 text-sm text-white transition-colors hover:bg-light-green-700"
            onClick={formatJSON}
            disabled={isFormatted}
          >
            {isFormatted ? 'Formatted' : 'Format JSON'}
          </button>
        </div>
      )}
    </div>
  )
}

export default JSONEditor
