import { FC, useEffect, useState } from 'react'
import { IconEdit, IconLeaf } from '@tabler/icons-react'
import { Input } from '../../atoms/inputs/Input'
import Typography from '../../atoms/Typography'
import { useUpdateTenantProject } from 'src/enviroment/api/services/projectsApi'
import { Project } from 'src/types/projects'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/atoms/dialog'
import { PrimaryButton } from 'src/components/atoms/buttons/PrimaryButton'
import { Controller, useForm } from 'react-hook-form'
import DropDown from 'src/components/atoms/DropDown'
import { useSearchTypes } from 'src/enviroment/api/services/typesApi'
import { useSearchStages } from 'src/enviroment/api/services/stageApi'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

interface EditMyProjectDialogProps {
  project: Project
}

const projectUpdateFormSchema = z.object({
  name: z
    .string()
    .trim()
    .min(3, { message: 'Name must be at least 3 characters' })
    .max(35, { message: 'Name cannot exceed 100 characters' }),
  stageId: z.string().optional(),
  typeIds: z.array(z.string()),
})

export type ProjectUpdateFormData = z.infer<typeof projectUpdateFormSchema>

const EditMyProjectDialog: FC<EditMyProjectDialogProps> = ({ project }) => {
  const [opened, setOpened] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { mutateAsync } = useUpdateTenantProject()

  const { data: types } = useSearchTypes()
  const { data: stages } = useSearchStages()

  const { control, handleSubmit, reset, setValue } = useForm<ProjectUpdateFormData>({
    mode: 'onChange',
    resolver: zodResolver(projectUpdateFormSchema),
  })

  useEffect(() => {
    reset({
      name: project.name,
      stageId: (project.stageId && project.stageId) || undefined,
      typeIds: (project && project.types.map((type) => type.id)) || undefined,
    })
  }, [project])

  const onSubmit = async (data: ProjectUpdateFormData) => {
    try {
      setIsLoading(true)
      await mutateAsync({ projectId: project.id, payload: data })
      setOpened(false)
    } catch (error) {
      console.error('Failed to update project:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && e.target instanceof HTMLInputElement) {
      e.preventDefault()
      if ((e.target as HTMLInputElement).name === 'name') {
        handleSubmit(onSubmit)()
      }
    }
  }

  return (
    <Dialog open={opened} onOpenChange={setOpened}>
      <DialogTrigger className="flex h-9 w-9 cursor-pointer items-center justify-center rounded-full border border-gray-200 bg-transparent text-gray-200 shadow-sm backdrop-blur-sm transition-all duration-200 hover:bg-gray-300/20 hover:text-light-green-50">
        <IconEdit className="h-6 w-6" stroke={1.5} />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            <Typography variant="2xl-nexa-bold">Update Project</Typography>
            <Typography variant="base" className="mt-2">
              Name your project and upload a spatial file for your project boundary.
            </Typography>
          </DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown} className="mt-4">
          <div className="mb-2 space-y-4">
            <div>
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, ...field }, formState }) => (
                  <Input
                    {...field}
                    onChange={onChange}
                    label="Project Name"
                    autoFocus
                    placeholder="New project"
                    type="text"
                    errorMessage={formState.errors.name?.message}
                  />
                )}
              />
            </div>

            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              {stages && (
                <Controller
                  name="stageId"
                  control={control}
                  render={({ field: { onChange, value }, formState }) => (
                    <DropDown
                      label="Stage"
                      options={stages.map((stage) => ({
                        id: stage.id,
                        label: stage.name,
                        value: stage.id,
                      }))}
                      placeholder="Select stage"
                      value={value}
                      errorMessage={formState.errors.stageId?.message}
                      onChange={onChange}
                    />
                  )}
                />
              )}
              {types && (
                <Controller
                  name="typeIds"
                  control={control}
                  render={({ field: { onChange, value }, formState }) => (
                    <DropDown
                      label="Types"
                      options={types.map((stage) => ({
                        id: stage.id,
                        label: stage.name,
                        value: stage.id,
                      }))}
                      multiple
                      placeholder="Select types"
                      value={value}
                      errorMessage={formState.errors.typeIds?.message}
                      onChange={onChange}
                    />
                  )}
                />
              )}
            </div>
          </div>
          <DialogFooter className="mt-4 sm:justify-end sm:space-x-6">
            <PrimaryButton type="submit" disabled={isLoading} isLoading={isLoading} rightIcon={<IconLeaf size={20} />}>
              Update Project
            </PrimaryButton>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default EditMyProjectDialog
