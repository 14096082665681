import { IconTrash } from '@tabler/icons-react'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogTrigger,
} from '../../atoms/alert-dialog'
import { FC, useCallback, useState } from 'react'
import { useDeleteMemberMutation } from 'src/enviroment/api/services/membersApi'
import { Member } from 'src/templates/members/columns'
import { IUser } from 'src/types/user'

interface Props {
  member: Member | IUser
}

const DeleteMemberDialog: FC<Props> = ({ member }) => {
  const [opened, setOpened] = useState(false)
  const handleClose = useCallback(() => setOpened(false), [])

  const { mutateAsync: deleteMemberAsync, isLoading } = useDeleteMemberMutation()

  const handleDelete = useCallback(async () => {
    try {
      await deleteMemberAsync(member.id)
      handleClose()
    } catch (error) {
      console.log(error)
    }
  }, [deleteMemberAsync, handleClose, member.id])

  return (
    <AlertDialog open={opened} onOpenChange={setOpened}>
      <AlertDialogTrigger className="flex h-10 w-16 items-center justify-center rounded-full border border-red-ramo text-red-600 transition-all hover:cursor-pointer hover:bg-red-600 hover:text-white active:scale-95">
        <IconTrash size={24} />
      </AlertDialogTrigger>
      <AlertDialogContent>
        <div className="flex flex-col items-center gap-4">
          <div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#FFADAD]">
            <IconTrash className="h-6 w-6 text-red-600" />
          </div>
          <div className="flex flex-col justify-center gap-6">
            <span className="text-center text-xl text-green-50">Delete RAMO Member</span>
            <span className="text-center text-gray-50">
              You&apos;re about to delete the {member.firstName} {member.lastName} member. Are you sure you want to
              proceed?
            </span>
          </div>
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={isLoading} onClick={handleClose} className="w-40">
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction disabled={isLoading} onClick={handleDelete} className="w-40">
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default DeleteMemberDialog
